import { useEffect, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { authServices } from 'Utils/auth/services';
import snackbarUtil from 'Utils/snackBarUtil';

interface PartnershipModalProps {
    show: any;
    clickHandler: any;
    userIds: string;
    userName: string
}

const PartnershipModal = ({ show, clickHandler, userIds, userName }: PartnershipModalProps) => {
    const [sportData, setSportData] = useState<any>();
    const [password, setPassword] = useState<string>("");
    const [sportShare, setSportShare] = useState<any>([{
        sport: "",
        sport_id: "",
        name: "",
        percentage: [
            {
                _id: "",
                parent_id: "",
                parent_partnership_share: 0,
                parent_share: 0,
                share: 0,
                user_id: "",
                user_name: "",
                user_share: 0,
                user_type_id: 0,
            },
            {
                parent_id: "",
                parent_partnership_share: 0,
                parent_share: 0,
                share: 0,
                user_share: 0,
                user_type_id: 0,
                _id: ""
            },
        ],
    }]);

    const getPartnerShip = async () => {
        const { response } = await authServices.getPartnerShip({
            user_id: userIds
        });
        setSportData(response?.data);
    }

    useEffect(() => {
        const transformedDataClient = sportData?.sports_share.map((sport: any) => {
            const percentages = sport?.percentage?.map((percentage: any) => ({
                parent_id: percentage.parent_id,
                parent_partnership_share: percentage.parent_partnership_share,
                parent_share: percentage.parent_share,
                share: percentage.share,
                user_id: percentage.user_id,
                user_name: percentage.user_name,
                user_share: percentage.user_share,
                user_type_id: percentage.user_type_id,
                _id: percentage?._id
            }));

            return {
                sport: sport.sport,
                sport_id: Number(sport.sport_id),
                name: sport.name,
                percentage: [...percentages],
            };
        });
        setSportShare(transformedDataClient);
    }, [sportData]);

    useEffect(() => {
        getPartnerShip();
    }, [userIds]);

    const handleUkShareChange = (index: number, value: number) => {
        const updatedShares = [...sportShare];
        updatedShares[index].percentage[0].user_share = value;
        updatedShares[index].percentage[1].parent_share = value;
        setSportShare(updatedShares);
    };

    const getUpdatePartbership = async () => {
        const { response } = await authServices.getUpdatePartbership({
            sports_share:sportShare,
            password: password,
            user_id: userIds,

        })
        if (response?.status) {
            snackbarUtil.success(response?.msg);
        }
    }

    const handleUpdate = () => {
        if (password?.length !== 0) {
            getUpdatePartbership();
        }
    }
    return (
        <Modal show={show} onHide={clickHandler} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <span>Update Sharing of ({userName})</span>
                <Button variant="light btn-sm" onClick={clickHandler}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='fs-md'>
                <div className="table-responsive">
                    {
                        sportShare?.map((items: any, index: number) => {
                            return (
                                <Table key={index} className="table align-middle table-nowrap table-striped-columns">
                                    <tbody>
                                        <tr>
                                            <th scope="col" className='table-light'>{items?.name}</th>
                                            <td>{sportData?.name} Current Share: <strong>{items?.percentage[0]?.user_share}</strong></td>
                                        </tr>
                                        <tr>
                                            <th scope="col" className='table-light'>Partnership</th>
                                            <td>Parent Updated Partnership: <strong>{items?.percentage[0]?.share}</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className='border-0 px-0'>
                                                <Form.Control
                                                    onChange={(e) => handleUkShareChange(index, Number(e.target.value))}
                                                    value={items?.percentage[0]?.user_share}
                                                    name='number'
                                                    type='number'
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            );
                        })
                    }
                </div>
                <Form.Control
                    onChange={(e) => setPassword(e.target.value)}
                    isInvalid={!password && password.trim() === ""}
                    value={password}
                    name='password'
                    type='password'
                />
                {!password && (
                    <Form.Control.Feedback type="invalid" className="d-block">
                        Password is requird
                    </Form.Control.Feedback>
                )}
            </Modal.Body>
            <Modal.Footer className='border-top'>
                <Button variant='subtle-primary' onClick={handleUpdate}>Submit</Button>
                <Button className='btn-ghost-primary' onClick={clickHandler}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PartnershipModal;
