import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
//import images
import avatar1 from "assets/images/users/32/avatar-1.jpg";
import { createSelector } from 'reselect';

const ProfileDropdown = () => {

    const [userName, setUserName] = useState<any>("Admin");

    const selectUser = createSelector(
        (state: any) => state.Profile,
        (user) => user.user
    );

    const user = useSelector(selectUser);

    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            const authUser: any = localStorage.getItem("authUser")
            const obj = JSON.parse(authUser);
            // setUserName(process.env.REACT_APP_DEFAULTAUTH === "fake" ? obj.username === undefined ? user?.username ? user?.username : "Admin" : "Admin" || "Admin" :
            setUserName(process.env.REACT_APP_DEFAULTAUTH === "fake" ? obj.username === "Admin":
                process.env.REACT_APP_DEFAULTAUTH === "firebase" ? obj.email && obj.email : "Admin"
            );
        }
    }, [userName, user]);

    const userDetails = localStorage.getItem("adminDetails");

    const userId = userDetails && JSON.parse(userDetails)?.user_name


    return (
        <React.Fragment>
            <Dropdown className="header-item topbar-user">
                <Dropdown.Toggle type="button" className="btn bg-transparent border-0 arrow-none" id="page-header-user-dropdown">
                    <span className="d-flex align-items-center">
                        <img className="rounded-circle header-profile-user" src={avatar1} alt="Header Avatar" />
                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{userId}</span>
                        </span>
                        <i className='fs-lg ri-arrow-down-s-line text-muted'></i>
                    </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-end">
                    <h6 className="dropdown-header">Welcome Admin! </h6>
                    <Dropdown.Item href="#"><i className="ri-bar-chart-2-line text-muted fs-lg align-middle me-1"></i> <span className="align-middle">My Market</span></Dropdown.Item>
                    <Dropdown.Item href="#"><i className="ri-file-list-line text-muted fs-lg align-middle me-1"></i> <span className="align-middle">Statement</span></Dropdown.Item>
                    <Dropdown.Item href="#"><i className="ri-arrow-up-down-line text-muted fs-lg align-middle me-1"></i> <span className="align-middle">Profit Loss</span></Dropdown.Item>
                    <Dropdown.Item href="#"><i className="ri-currency-line text-muted fs-lg align-middle me-1"></i> <span className="align-middle">Deposit</span></Dropdown.Item>
                    <Dropdown.Item href="#"><i className="ri-currency-line text-muted fs-lg align-middle me-1"></i> <span className="align-middle">Withdraw</span></Dropdown.Item>
                    <Dropdown.Item href="#"><i className="ri-shuffle-line text-muted fs-lg align-middle me-1"></i> <span className="align-middle">Socket Status</span></Dropdown.Item>
                    <Dropdown.Item href="/secure-auth"><i className="ri-shield-user-line text-muted fs-lg align-middle me-1"></i> <span className="align-middle">Secure Auth Verification</span></Dropdown.Item>
                    <div className="dropdown-divider"></div>
                    <Dropdown.Item href="/change-password"><i className="ri-lock-password-line text-muted fs-lg align-middle me-1"></i> <span className="align-middle">Change Password</span></Dropdown.Item>
                    <Dropdown.Item href="#"><i className="ri-edit-box-line text-muted fs-lg align-middle me-1"></i> <span className="align-middle">Edit Stack</span></Dropdown.Item>
                    <Dropdown.Item href="#"><i className="mdi mdi-cog-outline text-muted fs-lg align-middle me-1"></i> <span className="align-middle">Self Settings</span></Dropdown.Item>
                    <Dropdown.Item href="#"><i className="ri-delete-bin-3-line text-muted fs-lg align-middle me-1"></i> <span className="align-middle">Redis Flush</span></Dropdown.Item>
                    {/* <Dropdown.Item href="#"><i className="mdi mdi-lock text-muted fs-lg align-middle me-1"></i> <span className="align-middle">Lock screen</span></Dropdown.Item> */}
                    <Dropdown.Item href={process.env.PUBLIC_URL + "/logout"}><i className="mdi mdi-logout text-muted fs-lg align-middle me-1"></i> <span className="align-middle" data-key="t-logout">Logout</span></Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </React.Fragment>
    );
}

export default ProfileDropdown;