import TableContainer from "Common/TableContainer";
import { useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import moment from "moment";
import { authServices } from "Utils/auth/services";

interface StatementTableProps {
  statementData: StatementListData[];
}

const StatementTable = ({ statementData }: StatementTableProps) => {
  const [showList, setList] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [agentList, setAgentList] = useState<CasinoUserAgent[]>([]);

  const toggleList = (name?: string) => {
    setAgentList([]);
    setList(!showList);
    setSelectedUser(name || "");
  };

  const getUserAgent = async (userId: string) => {
    const { response } = await authServices.getShowAgent({
      user_id: userId,
    });
    if (response?.data) {
      setAgentList(response?.data?.agents);
    }
  };

  const defaultTable = useMemo(() => {
    if (!statementData || !Array.isArray(statementData)) {
      return [];
    }
    return statementData.map((items, index: number) => ({
      no: index + 1,
      date: items?.date,
      DomainName: items?.domain_name || "",
      description: items?.description,
      credit: items?.credit_debit > 0 ? items?.credit_debit : 0 || 0,
      debit: items?.credit_debit < 0 ? items?.credit_debit : 0 || 0,
      username: items?.user_name,
      userId: items?.user_id,
    }));
  }, [statementData]);

  const columns = useMemo(
    () => [
      {
        header: "No.",
        accessorKey: "no",
        enableColumnFilter: false,
      },

      {
        header: "Date",
        accessorKey: "date",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <p className="mb-0">
                {moment(cell?.row?.original?.date).format("DD-MM-YY")}
              </p>
              <p className="mb-0">
                {moment(cell?.row?.original?.date).format(" hh:mm A")}
              </p>
            </div>
          );
        },
      },
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span
              className="cursor-pointer text-info"
              onClick={() => {
                toggleList(cell.row.original?.username);
                getUserAgent(cell.row.original?.userId);
              }}
            >
              {cell.row.original.username}
            </span>
          );
        },
      },
      {
        header: "Description",
        accessorKey: "description",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div className="cursor-pointer text-primary">
              <p className="mb-0">{cell.row.original.description}</p>
              {/* <p className="mb-0">SPB-ASIA-134279105458) - Loss [ User : Loss ]</p> */}
            </div>
          );
        },
      },
      {
        header: "Credit",
        accessorKey: "credit",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span className="cursor-pointer text-success">
              {cell.row.original.credit}
            </span>
          );
        },
      },
      {
        header: "Debit",
        accessorKey: "debit",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span className="cursor-pointer text-danger">
              {cell.row.original.debit}
            </span>
          );
        },
      },
    ],
    []
  );


  return (
    <>
      <TableContainer
        columns={columns || []}
        data={defaultTable || []}
        isBordered={false}
        customPageSize={10}
        isPagination
        tableClass="table-centered align-middle table-nowrap table-striped mb-3"
        theadClass="text-muted table-light"
        SearchPlaceholder="Search Products..."
      />
      <Modal show={showList} onHide={toggleList} className="zoomIn" scrollable>
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <div className="d-flex align-items-center">
            Parent List of{" "}
            <span className="text-muted fw-normal mx-2">({selectedUser})</span>
          </div>
          <Button variant="light btn-sm" onClick={()=>toggleList()}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        {agentList?.map((item) => {
          return (

            <Modal.Body>
              {item.name}({item.user_name})
            </Modal.Body>
          );
        })}
      </Modal>
    </>
  );
};

export default StatementTable;
