import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';

interface Props {
    userData: DataRes | undefined;
    setUserPayload: Dispatch<SetStateAction<{
        partnership: number;
        domain: string;
        domain_name: string;
        name: string;
        user_name: string;
        password: string;
        user_type_id: string;
        parent_id: string;
        point: number;
        exposure_limit: number;
        belongs_to_credit_reference: number;
        credit_reference: number;
        belongs_to: string;
        title: string;
        session_commission: number;
        match_commission: number;
    }>>;
    userPayload: {
        partnership: number;
        domain: string;
        domain_name: string;
        name: string;
        user_name: string;
        password: string;
        user_type_id: string;
        parent_id: string;
        point: number;
        exposure_limit: number;
        belongs_to_credit_reference: number;
        credit_reference: number;
        belongs_to: string;
        title: string;
        session_commission: number;
        match_commission: number;
    };
    errors: {
        [key: string]: string;
    }
    setErrors: Dispatch<SetStateAction<{
        [key: string]: string;
    }>>
    type: string,
    userType: string | undefined
    setDemoUser: Dispatch<SetStateAction<boolean>>
    demoUser: boolean,
    demo: boolean | undefined
}

const PersonalDetails: FC<Props> = ({ setDemoUser, demoUser, demo, setErrors, errors, userData, setUserPayload, userPayload, type, userType }) => {
    const [passwordShow, setPasswordShow] = useState<boolean>(false);
    const [userDetails, setUserDetails] = useState<any>(null);
    const user_id = localStorage.getItem("userId")

    useEffect(() => {
        if (userData?.user_name) {
            setUserPayload((prevState) => ({
                ...prevState,
                parent_id: user_id || "",
            }));
        }
    }, [user_id, setUserPayload]);

    const handleInputChange = (e: any) => {
        const { name, value, type, checked } = e.target;
        if (name === "domain_name") {
            const selectedDomain = userData?.domain?.find((domain:any) => domain.domain_name === value);
            if (selectedDomain) {
                setUserPayload((prevState) => ({
                    ...prevState,
                    domain: selectedDomain._id,
                    domain_name: value,
                }));
            }
        } else if (name === "user_type_id") {
            const numValue = parseInt(value);
            if (numValue < 0) {
                setErrors((prevErrors) => ({ ...prevErrors, [name]: "User type cannot be negative." }));
            } else {
                setUserPayload((prevState) => ({
                    ...prevState,
                    [name]: numValue,
                }));
                setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
            }
        } else if (name === "belongs_to_credit_reference" && e.target.id === "BelongToUkraine") {
            setUserPayload((prevState) => ({
                ...prevState,
                belongs_to_credit_reference: checked ? 1 : 0,
                user_type_id: checked ? "8" : prevState.user_type_id,

            }));
        } else if (name === "belongs_to_credit_reference" && e.target.id === "BelongToB2C") {
            setUserPayload((prevState) => ({
                ...prevState,
                belongs_to_credit_reference: checked ? 1 : 0,
                user_type_id: checked ? "0" : prevState.user_type_id,
            }));
        } else {
            setUserPayload((prevState) => ({
                ...prevState,
                [name]: type === "checkbox" ? (checked ? 1 : 0) : value,
            }));
            if (name === "belongs_to" && value.toLowerCase() === "diamond") {
                setUserPayload((prevState) => ({
                    ...prevState,
                    belongs_to_credit_reference: 1,
                }));
            }
        }
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };



    const handleSelectClient = (e: any) => {
        const selectedValue = e.target.value;
        const selectedName = e.target.options[e.target.selectedIndex].text;
        setUserPayload((prevState) => ({
            ...prevState,
            user_type_id: selectedValue,
            title: selectedName,
        }));
        setErrors((prevErrors) => ({ ...prevErrors, user_type_id: "" }));
    }

    useEffect(() => {
        const user = localStorage.getItem("adminDetails");
        if (user) {
            try {
                const parsedUser = JSON.parse(user);
                setUserDetails(parsedUser);
            } catch (error) {
                console.error("Failed to parse user details", error);
            }
        }
    }, []);


    useEffect(() => {
        if (userType) {
            setUserPayload((prevState) => ({
                ...prevState,
                domain_name: userDetails?.domain_name,
            }))
        }
    }, [userType])

    const labels = [
        {
            name: "CHIP_SUMMARY",
            label: "CHIP_SUMMARY"
        },
        {
            label: "DIAMOND",
            name: "DIAMOND/SKY/LOTUS/RADHE"
        },
    ]

    return (
        <Card>
            <Card.Header>
                <h5 className="text-primary mb-0">Personal Details</h5>
            </Card.Header>
            <Card.Body>
                <Form action="#">
                    <Row className="g-3">
                        {
                            (type !== "uk" && type !== "user" && type !== "b2c" && userDetails?.user_type_id === 0) && <Col sm={12}>
                                <div className="bg-light py-2 px-3">
                                    <p className="mb-2">Type Of Website</p>
                                    <div className="mt-0 d-flex align-items-center flex-wrap">

                                        {labels?.map((label, index) => (
                                            <div className="form-check me-3" key={index}>
                                                <Form.Check
                                                    type="checkbox"
                                                    id={label?.label}
                                                    name="belongs_to"
                                                    checked={userPayload.belongs_to === label?.label}
                                                    onChange={() => setUserPayload((prev) => (
                                                        {
                                                            ...prev,
                                                            belongs_to: label?.label
                                                        }
                                                    ))}
                                                />
                                                <Form.Label className="form-check-label" htmlFor={label?.label}>
                                                    {label?.name}
                                                </Form.Label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                        }


                        <Col lg={6}>
                            <div className="form-floating">
                                <Form.Control
                                    type="text"
                                    name="parent"
                                    placeholder="Parent Name"
                                    disabled
                                    value={userData?.user_name || ''}
                                />
                                <Form.Label>Parent Name</Form.Label>
                            </div>
                        </Col>

                        <Col lg={6}>
                            <div className="form-floating">
                                <Form.Control
                                    name="user_name"
                                    type="text"
                                    placeholder="Agent Name"
                                    onChange={handleInputChange}
                                    value={userPayload.user_name || ''}
                                    isInvalid={!!errors.user_name}
                                />
                                <Form.Label>{type === "user" ? "Client Name" : "Agent Name"}</Form.Label>
                                <Form.Control.Feedback type="invalid">
                                    {errors.user_name}
                                </Form.Control.Feedback>
                            </div>
                        </Col>

                        <Col lg={6}>
                            <div className="form-floating">
                                <Form.Control
                                    name="name"
                                    type="text"
                                    placeholder="Username"
                                    onChange={handleInputChange}
                                    value={userPayload.name || ''}
                                    isInvalid={!!errors.name}
                                />
                                <Form.Label>Username</Form.Label>
                                <Form.Control.Feedback type="invalid">
                                    {errors.name}
                                </Form.Control.Feedback>
                            </div>
                        </Col>

                        <Col lg={6}>
                            <div className="form-floating">
                                <Form.Control
                                    name="password"
                                    type={passwordShow ? "text" : "password"}
                                    placeholder="Password"
                                    onChange={handleInputChange}
                                    value={userPayload.password || ''}
                                    isInvalid={!!errors.password}
                                />
                                <Form.Label>Password</Form.Label>
                                <Button
                                    variant="link"
                                    className="position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
                                    type="button"
                                    id="password-addon"
                                    onClick={() => setPasswordShow(!passwordShow)}
                                >
                                    <i className="ri-eye-fill align-middle"></i>
                                </Button>
                                <Form.Control.Feedback type="invalid">
                                    {errors.password}
                                </Form.Control.Feedback>
                            </div>
                        </Col>
                        {
                            !userType && <Col lg={6}>
                                <div className="form-floating">
                                    <Form.Select
                                        name="domain_name"
                                        onChange={handleInputChange}
                                        value={userPayload.domain_name || ''}
                                        isInvalid={!!errors.domain_name}
                                    >
                                        <option value="">Choose...</option>
                                        {userData?.domain?.map((domain:any, index:number) => (
                                            <option key={index} value={domain.domain_name}>
                                                {domain.host_name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Label>Domain List</Form.Label>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.domain_name}
                                    </Form.Control.Feedback>
                                </div>
                            </Col>
                        }

                        {type !== "user" && <>
                            {
                                (type !== "uk" && (userType === "2" || userPayload?.belongs_to === "" || userPayload?.belongs_to === "CHIP_SUMMARY" || userPayload?.belongs_to === "B2C_MANAGER" || userPayload?.belongs_to === "LOTUS")) ? <Col lg={6}>
                                    <div className="form-floating">
                                        <Form.Control
                                            name="user_type_id"
                                            type="number"
                                            placeholder="Child Level"
                                            onChange={handleInputChange}
                                            value={userPayload.user_type_id || ''}
                                            isInvalid={!!errors.user_type_id}
                                            min={0}
                                            onKeyDown={(e) => {
                                                if (e.key === '-' || e.key === 'e' || e.key === '+' || e.key === 'E') {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                        <Form.Label>Child Level</Form.Label>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.user_type_id}
                                        </Form.Control.Feedback>
                                    </div>
                                </Col> : <Col lg={6}>
                                    <div className="form-floating">
                                        <Form.Select
                                            name="Client Level"
                                            onChange={handleSelectClient}
                                            isInvalid={!!errors.user_type_id}

                                        >
                                            <option value="">Choose...</option>
                                            <option value={8} >White Label</option>
                                            <option value={7} >Sub Admin</option>
                                            <option value={6} >Hyper</option>
                                            <option value={5} >Senior Super</option>
                                            <option value={4} >Super</option>
                                            <option value={3} >Master</option>
                                            <option value={2} >Dealer</option>
                                        </Form.Select>
                                        <Form.Label>Client Level</Form.Label>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.user_type_id}
                                        </Form.Control.Feedback>
                                    </div>
                                </Col>
                            }
                        </>
                        }
                        <Col lg={6}>
                            <div className="form-floating">
                                <Form.Select
                                    name="point"
                                    onChange={handleInputChange}
                                    value={1}

                                    isInvalid={!!errors.point}
                                >
                                    {/* {userType !== "2" && <option value="">Choose...</option>} */}
                                    <option value="1">1</option>
                                    {/* {
                                         userType !== "2" &&  <option value="100">100</option>
                                    } */}

                                </Form.Select>
                                <Form.Label>Point</Form.Label>
                                <Form.Control.Feedback type="invalid">
                                    {errors.point}
                                </Form.Control.Feedback>
                            </div>
                        </Col>

                        <Col lg={6}>
                            <div className="form-floating">
                                <Form.Control
                                    name="exposure_limit"
                                    type="number"
                                    placeholder="Exposure Limit"
                                    onChange={handleInputChange}
                                    value={-1}
                                    readOnly
                                    isInvalid={!!errors.exposure_limit}
                                />
                                <Form.Label>Exposure Limit</Form.Label>
                                <Form.Control.Feedback type="invalid">
                                    {errors.exposure_limit}
                                </Form.Control.Feedback>
                            </div>
                        </Col>

                        {
                            userPayload?.belongs_to !== "CHIP_SUMMARY" && type !== "user" && userType !== "2" ? <>
                                {type !== "b2c" && <Col lg={6}>
                                    <div className="form-check me-3">
                                        <Form.Check
                                            type="checkbox"
                                            id="BelongToUkraine"
                                            name="belongs_to_credit_reference"
                                            checked={userPayload.belongs_to_credit_reference === 1 && userPayload.user_type_id === "8"}
                                            onChange={handleInputChange}
                                        />
                                        <Form.Label className="form-check-label" htmlFor="BelongToUkraine">
                                            Belong To Ukraine
                                        </Form.Label>
                                    </div>
                                </Col>}


                                <Col lg={6}>
                                    <div className="form-check me-3">
                                        <Form.Check
                                            type="checkbox"
                                            id="BelongToB2C"
                                            name="belongs_to_credit_reference"
                                            checked={userPayload.belongs_to_credit_reference === 1 && userPayload.user_type_id === "0"}
                                            onChange={handleInputChange}
                                        />
                                        <Form.Label className="form-check-label" htmlFor="BelongToB2C">
                                            Belong To {type === "b2c" ? "Credit Reference" : "B2C"}
                                        </Form.Label>
                                    </div>
                                </Col>
                                {
                                    (type === "uk" || type === "b2c" && userPayload?.belongs_to_credit_reference === 1) && <Col lg={6}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="credit_reference"
                                                type="number"
                                                placeholder="credit reference"
                                                onChange={handleInputChange}
                                                value={userPayload.credit_reference || ''}
                                                isInvalid={!!errors.credit_reference}
                                            />
                                            <Form.Label>Credit Reference</Form.Label>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.credit_reference}
                                            </Form.Control.Feedback>
                                        </div>
                                    </Col>
                                }

                            </> : ""
                        }

                        {
                            demo && <Col lg={6}>
                                <div className="form-floating">
                                    <Form.Check
                                        type="checkbox"
                                        id="BelongToB2C"
                                        name="belongs_to_credit_reference"
                                        checked={demoUser}
                                        onChange={(e)=>setDemoUser(e.target.checked)}
                                    />
                                    <Form.Label className="form-check-label" htmlFor="BelongToB2C">
                                       Demo
                                    </Form.Label>
                                </div>
                            </Col>
                        }
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    );
};

export default PersonalDetails;
