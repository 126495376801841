import ToggleSwitch from 'Common/ToggleSwitch';
import { useState } from 'react';
import { Button, Form, Modal, Table } from 'react-bootstrap';

interface SocialMediaModalProps {
	show: any;
	clickHandler: any;
}

const SocialMediaModal = ({show, clickHandler} : SocialMediaModalProps) => {
    const[showToggle, setToggle] = useState(false)

    const toggleSwitch = () => {
        setToggle(!showToggle)
    }
    return (
        <Modal size='lg' show={show} onHide={clickHandler} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <div>Add Social Media</div>
                <Button variant="light btn-sm" onClick={clickHandler}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='fs-md'>
            <div className="table-responsive">
                <Table className="table align-middle table-nowrap table-striped-columns mb-0">
                    <thead className="table-light">
                        <tr>
                            <th scope="col">Icon</th>
                            <th scope="col">Domain</th>
                            <th scope="col">Url</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><i className='fs-5xl ri-facebook-circle-fill' style={{color: '#1877f2'}}></i></td>
                            <td>radheexch99.com</td>
                            <td>
                                <Form.Control type='text' placeholder='https://www.facebook.com' />
                            </td>
                            <td>
                                <ToggleSwitch On='On' Off='Off' onChange={toggleSwitch} checked={showToggle ? true : false} />
                            </td>
                        </tr>
                        <tr>
                            <td><i className='fs-5xl ri-instagram-fill' style={{color: '#de44bd'}}></i></td>
                            <td>radheexch99.com</td>
                            <td>
                                <Form.Control type='text' placeholder='https://www.instagram.com' />
                            </td>
                            <td>
                                <ToggleSwitch On='On' Off='Off' onChange={toggleSwitch} checked={showToggle ? true : false} />
                            </td>
                        </tr>
                        <tr>
                            <td><i className='fs-5xl ri-telegram-fill' style={{color: '#0088cc'}}></i></td>
                            <td>radheexch99.com</td>
                            <td>
                                <Form.Control type='text' placeholder='https://www.telegram.com' />
                            </td>
                            <td>
                                <ToggleSwitch On='On' Off='Off' onChange={toggleSwitch} checked={showToggle ? true : false} />
                            </td>
                        </tr>
                        <tr>
                            <td><i className='fs-5xl ri-whatsapp-fill' style={{color: '#25d366'}}></i></td>
                            <td>radheexch99.com</td>
                            <td>
                                <Form.Control type='text' placeholder='https://www.whatsapp.com' />
                            </td>
                            <td>
                                <ToggleSwitch On='On' Off='Off' onChange={toggleSwitch} checked={showToggle ? true : false} />
                            </td>
                        </tr>
                        <tr>
                            <td><i className='fs-5xl mdi mdi-gmail' style={{color: '#ea4335'}}></i></td>
                            <td>radheexch99.com</td>
                            <td>
                                <Form.Control type='text' placeholder='https://www.gmail.com' />
                            </td>
                            <td>
                                <ToggleSwitch On='On' Off='Off' onChange={toggleSwitch} checked={showToggle ? true : false} />
                            </td>
                        </tr>
                        <tr>
                            <td><i className='fs-5xl ri-twitter-fill' style={{color: '#1da1f2'}}></i></td>
                            <td>radheexch99.com</td>
                            <td>
                                <Form.Control type='text' placeholder='https://www.twitter.com' />
                            </td>
                            <td>
                                <ToggleSwitch On='On' Off='Off' onChange={toggleSwitch} checked={showToggle ? true : false} />
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
            </Modal.Body>
            <Modal.Footer className='border-top'>
                <Button>Update</Button>
                <Button variant='light' onClick={clickHandler}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SocialMediaModal;