import BreadCrumb from "Common/BreadCrumb";
import { Col, Container, Row, Card, Form, Button } from "react-bootstrap";
import PersonalDetails from "./PersonalDetails";
import Permissions from "Common/Permissions";
import SharingTable from "Common/Tables/SharingTable";
import CommissionSetting from "Common/CommissionSetting";
import { authServices } from "Utils/auth/services";
import { FC, useEffect, useState } from "react";
import snackbarUtil from "Utils/snackBarUtil";
import { useNavigate, useParams } from "react-router-dom";

interface Props {
    type: string,
    demo?: boolean,
    name: string
}

const AddAgent: FC<Props> = ({ type, demo, name }) => {
    const [userData, setUserData] = useState<DataRes>();
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [demoUser, setDemoUser] = useState(true)
    const [userPayload, setUserPayload] = useState({
        partnership: 0,
        domain: "",
        domain_name: "",
        name: "",
        user_name: "",
        password: "",
        user_type_id: "",
        parent_id: "",
        point: 1,
        exposure_limit: -1,
        belongs_to_credit_reference: 0,
        credit_reference: 0,
        belongs_to: "",
        title: "White Label",
        session_commission: 0,
        match_commission: 0
    })

    const [sportShare, setSportShare] = useState<SportsSharePayload[]>(
        [{
            sport: "",
            sport_id: "",
            name: "",
            percentage: [
                {
                    parent_id: "",
                    parent_partnership_share: 0,
                    parent_share: 0,
                    share: 0,
                    user_id: "",
                    user_name: "",
                    user_share: 0,
                    user_type_id: 0,
                },
                {
                    parent_id: "",
                    parent_partnership_share: 0,
                    parent_share: 0,
                    share: 0,
                    user_share: 0,
                },
            ],
        }]);


    const [sportShareClient, setSportShareClient] = useState<any>()
    const [sportsPermission, setSportsPermission] = useState<SportsPermission[]>([]);
    const user_id = localStorage.getItem("userId")
    const { id, type: userTypes } = useParams();
    const nav = useNavigate();
    const getUserDetails = async () => {
        const { response } = await authServices.getAddUserDetails({
            is_sub_superadmin: false,
            user_id: id ? id : user_id || "",
            user_type_id: Number(userTypes) || 0
        })
        setUserData(response?.data)
        const transformedData = response?.data?.sports_share.map((sport: any) => {
            const percentages = sport?.percentage?.map((percentage: any) => ({
                parent_id: percentage.parent_id,
                parent_partnership_share: percentage.parent_partnership_share,
                parent_share: percentage.parent_share,
                share: percentage.share,
                user_id: percentage.user_id,
                user_name: percentage.user_name,
                user_share: percentage.user_share,
                user_type_id: percentage.user_type_id,
            }));


            const newPercentageObject = {
                parent_id: user_id,
                parent_partnership_share: 0,
                parent_share: 0,
                share: 0,
                user_share: 0,
            };
            return {
                sport: sport.sport,
                sport_id: sport.sport_id,
                name: sport.name,
                percentage: [...percentages, newPercentageObject],
            };
        });

        const transformedDataClient = response?.data?.sports_share.map((sport: any) => {
            const percentages = sport?.percentage?.map((percentage: any) => ({
                parent_id: percentage.parent_id,
                parent_partnership_share: percentage.parent_partnership_share,
                parent_share: percentage.parent_share,
                share: percentage.share,
                user_id: percentage.user_id,
                user_name: percentage.user_name,
                user_share: percentage.user_share,
                user_type_id: percentage.user_type_id,
            }));


            return {
                sport: sport.sport,
                sport_id: sport.sport_id,
                name: sport.name,
                percentage: [...percentages],
            };
        });

        setSportShare(transformedData);
        setSportShareClient(transformedDataClient);


    }

    useEffect(() => {
        getUserDetails();
    }, [user_id, userTypes, id]);

    useEffect(() => {
        if (userData) {
            setUserPayload(prev => ({
                ...prev,
                session_commission: userData.session_commission ?? prev.session_commission,
                match_commission: userData.match_commission ?? prev.match_commission,
                
            }));
        }
    }, [userData, setUserPayload, demoUser, userData]);

    useEffect(() => {
        if (userData && demo) {
            setUserPayload(prev => ({
                ...prev,
                is_demo:demoUser,
                domain:userData?.domain?._id,
                domain_name:userData?.domain?.domain_name,
            }));
        }
    }, [userData, setUserPayload, demoUser, userData]);

    console.log(userData, "userDatauserDatauserDatauserData")

    const getRegister = async () => {
        const { belongs_to, ...restPayload } = userPayload;

        const payload = type === "user" ? { ...restPayload } : { ...userPayload };
        const { response, error } = await authServices.getUserRegister({
            ...payload,
            sports_share: type === "user" ? sportShareClient : sportShare,
            sports_permission: sportsPermission,
        })
        if (response?.status) {
            snackbarUtil.success(response?.msg);
            nav('/user-list')
        }


    }


    const validateForm = () => {
        let formErrors: { [key: string]: string } = {};

        if (!userPayload.name) formErrors.name = "Username is required";
        if (!userPayload.user_name) formErrors.user_name = "Agent Name is required";
        if (!userPayload.password) formErrors.password = "Password is required";
        // if (!userPayload.domain_name) formErrors.domain_name = "Domain is required";
        if (!userPayload.user_type_id) formErrors.user_type_id = "Client Level is required";

        if (userPayload.point < 0) formErrors.user_type_id = "Client Level cannot be negative";
        if (userPayload.belongs_to === "CHIP_SUMMARY" && parseInt(userPayload.user_type_id) < 2) {
            formErrors.user_type_id = "Child level cannot be less than 2";
        }

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleCreateUser = () => {
        if (validateForm()) {
            getRegister();
        }else(
            console.log(errors,"huihuihui")
        )
    }

    useEffect(() => {
        if (id) {
            setUserPayload((prevState) => ({
                ...prevState,
                parent_id: id || "",
            }))
        } else {
            setUserPayload((prevState) => ({
                ...prevState,
                parent_id: user_id || "",
            }))
        }
    }, [user_id, id]);

    useEffect(() => {
        if (type === "uk") {
            setUserPayload((prevState) => ({
                ...prevState,
                belongs_to: "UKRAINE",
            }));
        } else if (type === "user") {
            setUserPayload((prevState) => ({
                ...prevState,
                user_type_id: "1",
                title: "user",
                belongs_to: "",
            }));
        } else if (type === "b2c") {
            setUserPayload((prevState) => ({
                ...prevState,
                belongs_to: "B2C_MANAGER",
                title: "Super Manager"
            }));
        }

    }, [type]);


    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title={`Add ${name} Details`} pageTitle="" />
                <Row>
                    <Col lg={6}>
                        <PersonalDetails demoUser={demoUser} setDemoUser={setDemoUser} demo={demo}  userType={userTypes} type={type} errors={errors} setErrors={setErrors} userPayload={userPayload} userData={userData} setUserPayload={setUserPayload} />

                        <CommissionSetting userPayload={userPayload} setUserPayload={setUserPayload} userData={userData} />
                    </Col>
                    <Col lg={6}>
                        {
                            (type !== "user" && userTypes !== "2") && <Card>
                                <Card.Header><h5 className='text-primary mb-0'>Sharing</h5></Card.Header>
                                <Card.Body>
                                    <SharingTable type={type} sportShare={sportShare} setSportShare={setSportShare} userData={userData?.sports_share} />
                                </Card.Body>
                            </Card>
                        }


                        <Card>
                            <Card.Body>
                                <Permissions sportsPermission={sportsPermission} setSportsPermission={setSportsPermission} userData={userData?.sports_permission} />
                            </Card.Body>
                            <Card.Footer className="text-end">
                                <Button onClick={handleCreateUser}>{type === "user" ? "Save" : "Add Agent"}</Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AddAgent;