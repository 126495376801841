import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';

interface UpdateDomainModalProps {
	show: any;
	clickHandler: any;
}

const UpdateDomainModal = ({show, clickHandler} : UpdateDomainModalProps) => {

    return (
        <Modal show={show} onHide={clickHandler} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <div>Update Domain: <span className='text-muted fw-normal'>(username)</span></div>
                <Button variant="light btn-sm" onClick={clickHandler}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='fs-md'>
                <Row className="align-items-center g-3">
                    <Col lg={12}>
                        <Form.Group>
                            <Form.Label>Old Domain</Form.Label>
                            <Form.Control type="text" placeholder='Enter Old Domain' />
                        </Form.Group>
                    </Col>
                    <Col lg={12}>
                        <Form.Group>
                            <Form.Label>New Domain</Form.Label>
                            <Form.Control type="text" placeholder='Enter New Domain' />
                        </Form.Group>
                    </Col>
                    <Col lg={12}>
                        <Table className="table align-middle table-nowrap table-striped-columns mb-0">
                            <thead className="table-light">
                                <tr>
                                    <th scope="col">Collection</th>
                                    <th scope="col">Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Banking Method</td>
                                    <td>0</td>
                                </tr>
                                <tr>
                                    <td>BankingType</td>
                                    <td>0</td>
                                </tr>
                                <tr>
                                    <td>Partnerships</td>
                                    <td>73</td>
                                </tr>
                                <tr>
                                    <td>User Setting Wise Sport</td>
                                    <td>73</td>
                                </tr>
                                <tr>
                                    <td>User</td>
                                    <td>73</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className='border-top'>
                <Button>Update</Button>
                <Button variant='light' onClick={clickHandler}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UpdateDomainModal;