import BreadCrumb from "Common/BreadCrumb";
import DomainSettingTable from "Common/Tables/DomainSettingTable";
import { useState } from "react";
import { Container, Button, Card, Form, Row, Col } from "react-bootstrap";

const DomainSetting = () => {
    const[showFilter, setFilter] = useState(false)

    return (
        <div className="page-content">
            <Container fluid>
                <div className="position-relative mb-2 d-flex justify-content-between align-items-center">
                    <BreadCrumb title="Domain Setting" pageTitle="Dashboard" back />
                    <Button variant="subtle-dark" onClick={()=>setFilter(!showFilter)}><i className="fs-lg align-middle ri-filter-3-line"></i> Filter</Button>
                </div>

                {showFilter ? (
                    <Card>
                        <Card.Body>
                            <Form>
                            <Row className="gy-3 align-items-end">
                                <Col lg={9}>
                                <Row className="gap-3 gap-sm-0">
                                    <Col lg={4}>
                                    <Form.Label>Website Name</Form.Label>
                                    <Form.Control type="text" placeholder="Website" />
                                    </Col>
                                    <Col lg={4}>
                                    <Form.Label>Site Title Data</Form.Label>
                                    <Form.Control type="text" placeholder="Title" />
                                    </Col>
                                    <Col lg={4}>
                                    <Form.Label>Domain Name</Form.Label>
                                    <Form.Control type="text" placeholder="Domain" />
                                    </Col>
                                </Row>
                                </Col>
                                <Col lg={3}>
                                <Button
                                    className="me-2 btn-sm"
                                    style={{ height: "38px" }}
                                >
                                    <i className="fs-lg align-middle ri-add-fill"></i> Add
                                </Button>
                                </Col>
                            </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                ):null}

                <Card>
                    <Card.Body>
                        <DomainSettingTable />
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
};

export default DomainSetting;