import MatchDetailTable from 'Common/Tables/MatchDetailTable';
import ShowFancyTable from 'Common/Tables/ShowFancyTable';
import { useEffect, useState } from 'react';
import { Card, Tab, Nav } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { authServices } from 'Utils/auth/services';
import { Data1Item } from './DetailsLeft';
import snackbarUtil from 'Utils/snackBarUtil';

interface BetsResponse {
    data: BetsData;
    status: boolean;
}

export interface BetsData {
    metadata: Metadaum[];
    data: Dataaa[];
}

interface Metadaum {
    total: number;
    page: number;
}

interface Dataaa {
    user_id: string;
    domain_name: string;
    match_date: string;
    market_id: string;
    winner_name: any;
    odds: number;
    size: number;
    stack: number;
    is_back: number;
    liability: number;
    ip_address: string;
    delete_status: number;
    is_fancy: number;
    createdAt: string;
    updatedAt: string;
    bet_id: string;
    user_name: string;
    market_name: string;
    selection_name: string;
    sport_name: string;
    series_name: string;
    match_name: string;
    p_l: number;
    deleted_reason: string;
}

const DetailsRight = () => {
    const [betsDetails, setBetDetails] = useState<BetsData>();
    const [betsDetailsFancy, setBetDetailsFancy] = useState<BetsData>();
    const [betsDetailsOdss, setBetDetailsOdds] = useState<BetsData>();
    const [currentPage, setCurrentPage] = useState(1);
    const [fancyLiveData, setFancyLiveData] = useState<Data1Item[] | undefined>(undefined);
    const [active, setActive] = useState(false);
    const [paylodData, setPayloadData] = useState({
        bet_id: "",
        user_id: "",
        is_void: true,
        is_fancy: 0,
        password: ""
      })
    const [pageSize] = useState(50);
    const { id } = useParams();

    const getBetsData = async (pageSize: number, currentPage: number, is_fancy?: number) => {
        const { response } = await authServices.getBets(
            {
                match_id: id || "",
                limit: pageSize,
                page: currentPage,
                search: {
                    bet_result_id:
                    {
                        $eq: null
                    },
                    is_fancy
                }
            });
        if (is_fancy === 0) {
            setBetDetailsOdds(response?.data)
        } else if (is_fancy === 1) {
            setBetDetailsFancy(response?.data)

        } else {
            setBetDetails(response?.data);
        }

    }
    // const getFancyOnline = async () => {
    //     const { response } = await authServices.getOnlineFancy(
    //         { match_id: id || "" });

    //     setFancyOnlineData(response?.data)

    // }

    const getFancyLiveData = async () => {
        const { response } = await authServices.getFancyLiveData({
            match_id: id || ""
        })
        setFancyLiveData(response?.data)
    }

    useEffect(() => {
        getBetsData(pageSize, currentPage);
    }, [id, pageSize, currentPage]);

    const handleAll = () => {
        getBetsData(pageSize, currentPage);
    };

    const handleMatchedBet = (is_fancy: number) => {
        getBetsData(pageSize, currentPage, is_fancy);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        if (betsDetails && currentPage < betsDetails?.metadata[0]?.total) setCurrentPage(currentPage + 1);
    };


    const showFancy = () => {
        getFancyLiveData();
    }

    const getDeleteBet = async()=>{
        const {response} = await authServices.getDeleteBet(paylodData);
        if(response?.status){
          snackbarUtil.success(response?.msg);
          getBetsData(pageSize, currentPage);
        }
      }

    // useEffect(() => {
    //     const mergedData = {
    //         data: fancyLiveData?.map(item => {
    //             const correspondingItem = fancyOnlineData?.find(d => d.fancy_id === item.fancy_id);
    //             return {
    //                 ...item,
    //                 ...(correspondingItem || {})
    //             };
    //         }),
    //     };

    //     setCombineFancyData(mergedData)

    // }, [fancyOnlineData, fancyLiveData])

    return (
        <Card className="p-3">
            <Tab.Container defaultActiveKey="all">
                <Nav as="ul" variant="pills" className="arrow-navtabs nav-primary bg-light mb-3">
                    <Nav.Item as="li" className="mx-2">
                        <Nav.Link eventKey="all" onClick={handleAll}>All Bet <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary shadow-lg">{betsDetails?.data?.length || 0}</span></Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li" className="mx-2">
                        <Nav.Link eventKey="match" onClick={() => handleMatchedBet(0)}>Match Bet <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary shadow-lg">{betsDetailsOdss?.data?.length || 0}</span></Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li" className="mx-2">
                        <Nav.Link eventKey="fancy" onClick={() => handleMatchedBet(1)}>Fancy Bet <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary shadow-lg">{betsDetailsFancy?.data?.length || 0}</span></Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li" className="mx-2">
                        <Nav.Link eventKey="show" onClick={showFancy}>Show Fancy <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary shadow-lg">{fancyLiveData?.length}</span></Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content className="text-muted">
                    <Tab.Pane eventKey="all">
                        <MatchDetailTable
                            currentPage={currentPage}
                            betsDetails={betsDetails}
                            handlePreviousPage={handlePreviousPage}
                            handleNextPage={handleNextPage}
                            getDeleteBet={getDeleteBet}
                            setPayloadData={setPayloadData}
                            paylodData={paylodData}
                            setActive={setActive}
                            active={active}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey="match">

                        <MatchDetailTable
                            currentPage={currentPage}
                            betsDetails={betsDetailsOdss}
                            handlePreviousPage={handlePreviousPage}
                            handleNextPage={handleNextPage}
                            getDeleteBet={getDeleteBet}
                            setPayloadData={setPayloadData}
                            paylodData={paylodData}
                            setActive={setActive}
                            active={active}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey="fancy">

                        <MatchDetailTable
                            currentPage={currentPage}
                            betsDetails={betsDetailsFancy}
                            handlePreviousPage={handlePreviousPage}
                            handleNextPage={handleNextPage}
                            getDeleteBet={getDeleteBet}
                            setPayloadData={setPayloadData}
                            paylodData={paylodData}
                            setActive={setActive}
                            active={active}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey="show">
                        <ShowFancyTable
                            data={fancyLiveData}
                            getFancyLiveData={getFancyLiveData}
                        />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </Card>
    );
};

export default DetailsRight;
