import CasinoBetsTable from "Common/Tables/CasinoBetsTable";
import Flatpickr from 'react-flatpickr';
import { Button, Card, Col, Form, Row } from "react-bootstrap";

interface StatementProps {
    filter? : boolean
}

const NewsUpdate = ({filter}: StatementProps) => {
    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col lg={12}>
                        <Form.Label>Heading</Form.Label>
                        <Form.Control type="text" placeholder="Enter News Heading" />
                    </Col>
                    <Col lg={12} className="mt-3">
                        <Form.Label>Description</Form.Label>
                        <Form.Control as={'textarea'} rows={5} placeholder="Enter News Description" style={{height: 'auto'}} />
                    </Col>
                </Row>
            </Card.Body>
            <Card.Footer className='border-0 text-end'>
                <Button className="me-2">Update</Button>
                <Button variant='light'>Cancel</Button>
            </Card.Footer>
        </Card>
    );
};

export default NewsUpdate;