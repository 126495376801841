import ToggleSwitch from 'Common/ToggleSwitch';
import { useEffect, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import { authServices } from 'Utils/auth/services';

interface RootObject {
    user_name: string;
    parent_id: null;
    data: Data;
    status: boolean;
}

interface Data {
    users: User[];
    metadata: Metadata;
}

interface Metadata {
    total: Total[];
    own: Total[];
    parent: any[];
    teams: string[];
    columns: string[];
    total_exposure: Totalexposure[];
}

interface Totalexposure {
    market_id: string;
    selection_id: number;
    selection_name: string;
    sort_priority: number;
    win_loss: number;
    win_loss_total_exposure: number;
}

interface Total {
    selection_id: number;
    selection_name: string;
    win_loss: number;
}

interface User {
    user_id: string;
    user_type_id: number;
    user_name: string;
    domain_name: string;
    england: number;
    england_total_exposure: number;
    australia: number;
    australia_total_exposure: number;
}

interface BookModalProps {
    show: any;
    clickHandler: any;
    marketIds: string
}

const BookModal = ({ show, clickHandler, marketIds }: BookModalProps) => {
    const [showOn, setShowOn] = useState(false);
    const [bookData, setBookData] = useState<RootObject>()
    const [BookFancyData, setBookFancyData] = useState<any>()

    const userId = localStorage.getItem("userId")

    const getMarketPosition = async () => {
        const { response } = await authServices.getMarketPosition({
            user_id: userId || "",
            market_id: marketIds
        })
        setBookData(response)
    }

    useEffect(() => {
        if(marketIds){
            getMarketPosition();
        }
    }, [userId, marketIds])


    const normalizeTeamName = (team: string): string => {
        return team.toLowerCase().replace(/ /g, "_");
    };

    useEffect(() => {
        if (!bookData?.data) return;

        const mergedData = bookData.data.users.map((user: any) => {
            const mergedUser = { ...user };

            bookData.data.metadata.teams.forEach((team: string) => {
                const normalizedTeam = normalizeTeamName(team);
                const teamScore = user[normalizedTeam];
                const teamExposure = user[`${normalizedTeam}_total_exposure`];

                mergedUser[team] = {
                    win_loss: teamScore || 0,
                    total_exposure: teamExposure || 0
                };

                delete mergedUser[normalizedTeam];
                delete mergedUser[`${normalizedTeam}_total_exposure`];
            });

            return mergedUser;
        });

        setBookFancyData(mergedData)
    }, [bookData]);



    console.log(bookData?.data, "mergedDa12")

    return (
        <Modal size="lg" show={show} onHide={clickHandler} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <div className='d-flex align-items-center'>Market Position <span className='text-muted fw-normal mx-2'>Match Odds</span> <ToggleSwitch On='On' Off='Off' id='mainMatch' onChange={() => setShowOn(!showOn)} checked={showOn ? true : false} /></div>
                <Button variant="light btn-sm" onClick={clickHandler}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='pt-1 fs-md'>
                <div className="table-responsive">
                    <Table className="align-middle table-striped table-nowrap mb-0">
                        <thead>
                            <tr>
                                <th scope="col">Domain Name</th>
                                <th scope="col">Account</th>
                                {
                                    bookData?.data?.metadata?.teams?.map((items) => {
                                        return (
                                            <th scope="col">{items}</th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                BookFancyData?.map((user: any, id: number) => {
                                    return (
                                        <tr key={id}>
                                            <td>{user?.domain_name}</td>
                                            <td>
                                                <Button variant='subtle-primary' className='rounded-pill p-1' style={{ width: '30px' }}>
                                                    {user?.user_type_id === "1" ? "U" : "A"}
                                                </Button>
                                                <span className='text-primary ms-2'>{user?.user_name}</span>
                                            </td>

                                           
                                            {bookData?.data?.metadata?.teams?.map((team: string, index: number) => {
                                                const teamData = user[team];
                                                return (
                                                    <td key={index} className={teamData?.win_loss > 0 ? "text-success" : "text-danger"}>
                                                        {teamData?.win_loss}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })
                            }

                            <tr>
                                <td></td>
                                <td className='fw-bold'>Total</td>
                                {
                                    bookData?.data?.metadata?.total?.map((total) => {
                                        return (
                                            <td className={total?.win_loss > 0 ? 'text-success' : "text-danger"}>{total?.win_loss}</td>
                                        )
                                    })
                                }

                            </tr>
                            <tr>
                                <td></td>
                                <td className='fw-bold'>Own</td>
                                {
                                    bookData?.data?.metadata?.own?.map((total) => {
                                        return (
                                            <td className={total?.win_loss > 0 ? 'text-success' : "text-danger"}>{total?.win_loss}</td>
                                        )
                                    })
                                }
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default BookModal;