import { useEffect, useState } from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { authServices } from 'Utils/auth/services';
import snackbarUtil from 'Utils/snackBarUtil';

interface DepositModalProps {
    show: boolean;
    clickHandler: any;
    userIds: string;
    getUserList: () => Promise<void>;
    userDetails: any
    userName: string
}

interface CommissionData {
    match_commission: number;
    session_commission: number;
}

const CommissionSetting = ({ show, clickHandler, userIds, getUserList, userDetails, userName }: DepositModalProps) => {
    const [commissionData, setCommissionData] = useState<CommissionData | null>(null);
    const [formData, setFormData] = useState({
        match_commission: '',
        session_commission: '',
        password: ''
    });
    const [formErrors, setFormErrors] = useState({
        match_commission: '',
        session_commission: '',
        password: 'Please enter password'
    });

    const getCommissionDetails = async (id: string) => {
        const { response } = await authServices.getCommittion({ user_id: id });
        setCommissionData(response?.data);
    };

    useEffect(() => {
        if (userIds) getCommissionDetails(userIds);
    }, [userIds]);

    useEffect(() => {
        if (commissionData) {
            setFormData({
                match_commission: commissionData?.match_commission?.toString(),
                session_commission: commissionData?.session_commission?.toString(),
                password: ''
            });
        }
    }, [commissionData]);

    useEffect(() => {
        if (!show) {
            setFormData({
                match_commission: '',
                session_commission: '',
                password: ''
            });
            setFormErrors({
                match_commission: '',
                session_commission: '',
                password: ''
            });
        }
    }, [show]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const validateForm = () => {
        let isValid = true;
        const errors: any = {};

        if (!formData.match_commission || isNaN(Number(formData.match_commission)) || Number(formData.match_commission) <= 0) {
            errors.match_commission = 'Please enter a valid amount';
            isValid = false;
        }

        if (!formData.session_commission || isNaN(Number(formData.session_commission)) || Number(formData.session_commission) <= 0) {
            errors.session_commission = 'Please enter a valid amount';
            isValid = false;
        }
        if (!formData.password || isNaN(Number(formData.password)) || Number(formData.password) <= 0) {
            errors.session_commission = 'Please enter a valid amount';
            isValid = false;
        }

        setFormErrors(errors);
        return isValid;
    };

    const updateCommission = async () => {
        const { response } = await authServices.getUpdateCommittion({
            match_commission: Number(formData.match_commission),
            session_commission: Number(formData.session_commission),
            user_id: userIds,
        });

        if (response?.status) {
            snackbarUtil.success(response.msg);
            clickHandler();
            getUserList();
        }
    };

    const handleSubmit = () => {

        if (validateForm()) {
            if(formData?.password === userDetails?.password){
                updateCommission();
            }else{
                snackbarUtil.error("Please Enter Valid Password");
            }
        }
    };

    const adjustCommission = (field: string, value: number) => {
        setFormData((prevState: any) => ({
            ...prevState,
            [field]: (Number(prevState[field]) + value).toString(),
        }));
    };

    return (
        <Modal show={show} onHide={clickHandler} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <span>Update Commission Setting of {userName}</span>
                <Button variant="light btn-sm" onClick={clickHandler}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className="fs-md">
                <Form action="#">
                    <Row className="g-3">
                        <Col>
                            <div className="fs-md mb-2">Market Commission</div>
                            <div className="input-step step-primary">
                                <Button
                                    className="minus"
                                    onClick={() => adjustCommission('match_commission', -1)}
                                >
                                    –
                                </Button>
                                <Form.Control
                                    type="number"
                                    name="match_commission"
                                    className="product-quantity"
                                    value={formData.match_commission}
                                    min="0"
                                    max="100"
                                    onChange={handleInputChange}
                                />
                                <Button
                                    className="plus"
                                    onClick={() => adjustCommission('match_commission', 1)}
                                >
                                    +
                                </Button>
                            </div>
                            {formErrors.match_commission && (
                                <Form.Control.Feedback type="invalid" className="d-block">
                                    {formErrors.match_commission}
                                </Form.Control.Feedback>
                            )}
                        </Col>
                        <Col>
                            <div className="fs-md mb-2">Session Commission</div>
                            <div className="input-step step-primary">
                                <Button
                                    className="minus"
                                    onClick={() => adjustCommission('session_commission', -1)}
                                >
                                    –
                                </Button>
                                <Form.Control
                                    type="number"
                                    className="product-quantity"
                                    name="session_commission"
                                    value={formData.session_commission}
                                    min="0"
                                    max="100"
                                    onChange={handleInputChange}
                                />
                                <Button
                                    className="plus"
                                    onClick={() => adjustCommission('session_commission', 1)}
                                >
                                    +
                                </Button>
                            </div>
                            {formErrors.session_commission && (
                                <Form.Control.Feedback type="invalid" className="d-block">
                                    {formErrors.session_commission}
                                </Form.Control.Feedback>
                            )}
                        </Col>
                        <Col md={12}>
                        <Form.Label>Password</Form.Label>
                            <Form.Control
                                name="password"
                                type="number"
                                placeholder="Enter Login Password"
                                onChange={handleInputChange}
                                value={formData?.password}
                                isInvalid={!formData?.password && formData?.password.trim() === ""}
                                required

                            />
                            {formErrors.password && (
                                <Form.Control.Feedback type="invalid" className="d-block">
                                    {formErrors.password}
                                </Form.Control.Feedback>
                            )}
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className="text-end border-top">
                <Button variant="subtle-primary" onClick={handleSubmit}>Update</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CommissionSetting;
