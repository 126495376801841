import { Container } from "react-bootstrap";
import * as React from 'react';
import { closest, isNullOrUndefined } from '@syncfusion/ej2-base';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Filter, IFilter, VirtualScroll, Sort } from '@syncfusion/ej2-react-grids';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { RatingComponent } from '@syncfusion/ej2-react-inputs';
import { DataManager, Query, UrlAdaptor } from '@syncfusion/ej2-data';
import './grid-overview.css';


interface TrustTemplateProps {
    Trustworthiness: string;
}
function trustdetails(props: TrustTemplateProps): JSX.Element {
    if (props.Trustworthiness === "Select All") {
      return (<span></span>);
    }
    let loc = { width: '31px', height: '24px' };
    let Trustworthiness = props.Trustworthiness == "Sufficient" ? 'src/grid/images/Sufficient.png' : props.Trustworthiness == "Insufficient" ? 'src/grid/images/Insufficient.png' : 'src/grid/images/Perfect.png';
    return (<div><img style={loc} src={Trustworthiness} alt="" /> <span id="Trusttext">{props.Trustworthiness}</span></div>);
}

const Dashboard = () => {
    const ddlData: { [key: string]: Object }[] = [
        { text: '1,000 Rows and 11 Columns', value: '1000' },
        { text: '10,000 Rows and 11 Columns', value: '10000' },
        { text: '1,00,000 Rows and 11 Columns', value: '100000' }
    ];
    const query = new Query().addParams('dataCount', '1000');
    const fields: object = { text: 'text', value: 'value' };
    const hostUrl: string = 'https://services.syncfusion.com/react/production/';
    const data: DataManager = new DataManager({ url: hostUrl + 'api/UrlDataSource', adaptor: new UrlAdaptor  });
    const gridFilter: any = {
        type: 'Menu'
    }
    const select: any = {
        persistSelection: true,
        type: "Multiple",
        checkboxOnly: true
    }

    return (
        <div className="page-content">
            <Container fluid>
            <div className='control-pane'>
                <div className='control-section'>
                    <div style={{paddingBottom: '18px'}}>
                    <DropDownListComponent id="games" width='220' dataSource={ddlData} index={0}  fields={fields} placeholder="Select a Data Range" popupHeight="240px" />
                    <span id='msg'></span>
                    <br />
                    </div>
                    <GridComponent id="overviewgrid" dataSource={data} loadingIndicator= {{ indicatorType: 'Shimmer' }} query={query} enableHover={false} enableVirtualization={true} rowHeight={38} height='400'  filterSettings={gridFilter} allowFiltering={true} allowSorting={true} allowSelection={true} selectionSettings={select} enableHeaderFocus={true}>
                    <ColumnsDirective>
                        <ColumnDirective type='checkbox' allowSorting={false} allowFiltering={false} width='60'></ColumnDirective>
                        <ColumnDirective field='EmployeeID' visible={false} headerText='Employee ID' isPrimaryKey={true} width='130'></ColumnDirective>
                        <ColumnDirective field='Employees' headerText='Employee Name' width='230' clipMode='EllipsisWithTooltip' />
                        <ColumnDirective field='Designation' headerText='Designation' width='170' clipMode='EllipsisWithTooltip' />
                        <ColumnDirective field='Mail' headerText='Mail' width='230'></ColumnDirective>
                        <ColumnDirective field='Location' headerText='Location' width='140'></ColumnDirective>
                        <ColumnDirective field='Status' headerText='Status' width='130'></ColumnDirective>
                        <ColumnDirective field='Trustworthiness' headerText='Trustworthiness' template={trustdetails} width='160'></ColumnDirective>
                        <ColumnDirective field='Rating' headerText='Rating' width='220' />
                        <ColumnDirective field='Software' allowFiltering={false} allowSorting={false} headerText='Software Proficiency' width='180' format='C2' />
                        <ColumnDirective field='CurrentSalary' headerText='Current Salary' width='160' format='C2'></ColumnDirective>
                        <ColumnDirective field='Address' headerText='Address' width='240' clipMode="EllipsisWithTooltip" ></ColumnDirective>
                    </ColumnsDirective>
                    <Inject services={[Filter, VirtualScroll, Sort]} />
                    </GridComponent>
                </div>
                <style>
                    @import 'src/grid/Grid/style.css';
                </style>
                </div>
            </Container>
        </div>
    );
};

export default Dashboard;