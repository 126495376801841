import DepositModal from "Common/Modals/DepositModal";
import EditCreditReference from "Common/Modals/EditCreditReference";
import ExposureModal from "Common/Modals/ExposureModal";
import PermissionSetting from "Common/Modals/PermissionSetting";
import ResetPasswordModal from "Common/Modals/ResetPasswordModal";
import ShowPasswordModal from "Common/Modals/ShowPasswordModal";
import WithdrawModal from "Common/Modals/WithdrawModal";
import TableContainer from "Common/TableContainer";
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState, } from "react";
import { Badge, Button, Dropdown, Modal, OverlayTrigger, Tooltip, Form, Popover } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CommissionSetting from "./CommissionSetting";
import PartnershipModal from "Common/Modals/PartnershipModal";
import UserMarketLimit from "Common/Modals/UserMarketLimit";
import EditStakeModal from "Common/Modals/EditStakeModal";
import ExposureLimitModal from "Common/Modals/ExposureLimitModal";
import PermissionModal from "Common/Modals/PermissionModal";
import ParentList from "Common/Modals/ParentList";

interface Props{
    userData: useListData | undefined
    getUserList: () => Promise<void>
    setUserId: Dispatch<SetStateAction<string>>
}

const UserListTable:FC<Props> = ({userData, getUserList, setUserId}) => {
    const [showCreditReference, setCreditReference] = useState(false);
    const [showExposure, setExposure] = useState(false);
    const [showDeposit, setDeposit] = useState(false);
    const [showCommSet, setShowCommSet] = useState(false);
    const [showWithdraw, setWithdraw] = useState(false);
    const [showPasswordModal, setPasswordModal] = useState(false);
    const [showPermissionSetting, setPermissionSetting] = useState(false);
    const [showResetPasswordModal, setshowResetPasswordModal] = useState(false);
    const [userIds, setUserIds] = useState<string>("");
    const [showPartnership, setPartnership] = useState(false);
    const [showMarketLimit, setMarketLimit] = useState(false);
    const [showEditStake, setEditStake] = useState(false);
    const [showExposureLimit, setExposureLimit] = useState(false);
    const [showPermission, setPermission] = useState(false);
    const [creditRef, setCreditRef] = useState(0);
    const [userName, setUserName] = useState("");
    const [dataUser, setDataUser] = useState()
    const [settingData, setSettingData] = useState("");
    const [showParentList, setShowParentList] = useState(false)

    const handleChildUserList = (ids:string)=>{
        setUserId(ids)
    }
    
    const toggleCreditReference = (cr:string, id:string, name:string) => {
        setCreditRef(Number(cr));
        setUserIds(id);
        setUserName(name)
        setCreditReference(!showCreditReference);
    };
    const togglePermissionSetting = (id:string) => {
        setUserIds(id);
        setPermissionSetting(!showPermissionSetting);
    };
    const toggleExposure = (id:string) => {
        setUserIds(id);
        setExposure(!showExposure);
    };
    const toggleDeposit = (id:string) => {
        setUserIds(id);
        setDeposit(!showDeposit);
    };
    const toggleCopmmitionSetting = (id:string, name:string) => {
        setUserIds(id);
        setUserName(name)
        setShowCommSet(!showCommSet);
    };
    const toggleWithdraw = (id:string) => {
        setUserIds(id);
        setWithdraw(!showWithdraw);
    };
    const toggleShowPassword = (id:string) => {
        setUserIds(id);
        setPasswordModal(!showPasswordModal);
    };
    const toggleResetPassword = (id:string) => {
        setUserIds(id);
        setshowResetPasswordModal(!showResetPasswordModal);
    };
    const togglePartnershipSharing = (id:string, name:string) => {
        setUserIds(id);
        setUserName(name)
        setPartnership(!showPartnership);
    };
    const toggleMarketLimit = (id:string, settingId:string, name:string) => {
        setUserIds(id);
        setSettingData(settingId);
        setUserName(name)
        setMarketLimit(!showMarketLimit);
    };
    const toggleEditStake = (id:string) => {
        setUserIds(id);
        setEditStake(!showEditStake);
    };
    const handleParentList = (id:string, name:string) => {
        setUserIds(id);
        setUserName(name)
        setShowParentList(!showParentList);
    };
    const toggleExposureLimit = () => {
        setExposureLimit(!showExposureLimit);
    };
    const togglePermission = (id:string, name:string, item:any) => {
        setUserIds(id);
        setUserName(name);
        setDataUser(item)
        setPermission(!showPermission);
    };

    const nav = useNavigate();
    const defaultData = useMemo(() => {
        if (!userData?.childLevelDetails || !Array.isArray(userData.childLevelDetails)) {
            return [];
        }
        return userData.childLevelDetails.map((items, index: number) => ({
            no: index + 1,
            UserName: items?.user_name || '',
            name: items?.name || '',
            DomainName: items?.domain?.site_title || '',
            exp: items?.liability || 0,
            profitLoss: items?.profit_loss || 0,
            balance: items?.balance || 0,
            point: items?.point || 0,
            childLevel: items?.user_type_id || '',
            mobile: items?.mobile || '',
            creditReference:items?.credit_reference,
            pName:items?.parent_user_name,
            type:items?.parent_level_ids[0]?.name,
            lockUser:items?.self_lock_user,
            lockBetting:items?.self_lock_betting,
            userId:items?._id,
            items:items,
            userSetting:items?.userSettingSportsWise
        }));
    }, [userData]);

    const columns = useMemo(
        () => [
            { header: "No.", accessorKey: "no", enableColumnFilter: false, enableSorting: true },
            {
                header: "User Name", 
                accessorKey: "UserName", 
                enableColumnFilter: false, 
                enableSorting: true ,
                cell: (cell: any) => (
                    <span className="cursor-pointer" onClick={()=>handleChildUserList(cell.row.original.userId)}>
                        {cell.row.original.UserName} 
                        
                    </span>
                ),
            },
            { header: "Domain Name", accessorKey: "DomainName", enableColumnFilter: false, enableSorting: true },
            
            {
                header: "C R",
                accessorKey: "creditReference",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => (
                    <span>
                        {cell.row.original.creditReference}{" "}
                        <Button variant="sm" onClick={()=>toggleCreditReference(cell.row.original.creditReference, cell.row.original.userId, cell.row.original.UserName)}>
                            <i className="bx bx-edit-alt fs-lg align-middle"></i>
                        </Button>
                    </span>
                ),
            },
            {
                header: "Exposure",
                accessorKey: "exposure",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => (
                    <span 
                    className={`badge cursor-pointer bg-body-secondary border border-light ${cell.row.original.exp > 0?"text-success":"text-danger"}`} onClick={()=>toggleExposure(cell.row.original.userId)}>
                      {cell.row.original.exp?.toFixed(2)}
                    </span>
                ),
            },
            {
                header: "P|L",
                accessorKey: "profitLoss",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => <span className={cell.row.original.profitLoss<0?"text-danger":"text-success"}> {cell.row.original.profitLoss?.toFixed(2)}</span>,

            },
            // {
            //     header: "P|L %",
            //     accessorKey: "profitLoss2",
            //     enableColumnFilter: false,
            //     enableSorting: true,
            //     cell: (cell: any) => <span className="text-success">0%</span>,
            // },

            // { header: "Point", accessorKey: "point", enableColumnFilter: false, enableSorting: true },

            {
                header: "Balance",
                accessorKey: "balance",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => <span className={cell.row.original.balance<0?"text-danger":"text-success"}>{(cell.row.original.balance)?.toFixed(2)}</span>,
            },
            {
                header: "Credit",
                accessorKey: "credit",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => (
                    <div className="d-flex btn-group">
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top"> Chips Deposit </Tooltip>}>
                            <Button variant="success bg-gradient" className="btn-sm" style={{ minWidth: '29px' }} onClick={()=>toggleDeposit(cell.row.original.userId)}>
                                D
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top"> Chips Withdraw </Tooltip>}>
                            <Button variant="danger bg-gradient" className="btn-sm" onClick={()=>toggleWithdraw(cell.row.original.userId)}>
                                W
                            </Button>
                        </OverlayTrigger>
                    </div>
                ),
            },
            {
                header: "User S",
                accessorKey: "userStatus",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => (
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top"> User Status {cell.row?.original?.lockUser?"Lock":"Unlock"} </Tooltip>}>
                        <div className="text-center">
                            <i className={`fs-lg ${cell.row?.original?.lockUser?"ri-lock-line":"ri-lock-unlock-line"}`}></i>
                        </div>
                    </OverlayTrigger>
                ),
            },
            {
                header: "Bet S",
                accessorKey: "betStatus",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => (
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top"> User Status {cell.row?.original?.lockBetting?"Lock":"Unlock"} </Tooltip>}>
                        <div className="text-center">
                            <i className={`fs-lg ${cell.row?.original?.lockBetting?"ri-lock-line":"ri-lock-unlock-line"}`}></i>
                        </div>
                    </OverlayTrigger>
                ),
            },
            // { header: "P Name", accessorKey: "pName", enableColumnFilter: false, enableSorting: true, cell:(cell:any)=><span>{cell.row.original.pName}</span> },
            // {
            //     header: "A/C Type",
            //     accessorKey: "acType",
            //     enableColumnFilter: false,
            //     enableSorting: true,
            //     cell: (cell: any) => <Badge className="badge-gradient-primary">{cell.row?.original?.type}</Badge>,
            // },
            {
                header: "C | L",
                accessorKey: "childLevel",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => <span className="text-info">{cell.row.original.childLevel}</span>,
            },
            {
                header: "Action",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cell: any) => (
                    <div>
                        <Dropdown drop="start">
                            <Dropdown.Toggle variant="subtle-dark" className="e-caret-hide btn-sm">
                                <i className="fs-lg ri-more-2-fill"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item className="border-bottom text-primary py-2" onClick={()=>toggleShowPassword(cell.row.original.userId)}>
                                    <i className="fs-lg me-2 ri-eye-fill align-middle"></i> Show Password
                                </Dropdown.Item>
                                <Dropdown.Item className="border-bottom text-secondary py-2" onClick={()=>toggleResetPassword(cell.row.original.userId)}>
                                    <i className="fs-lg me-2 ri-key-2-fill align-middle"></i> Change Password
                                </Dropdown.Item>
                                <Dropdown.Item className="border-bottom text-success py-2" onClick={()=>togglePartnershipSharing(cell.row.original.userId, cell.row.original.UserName)}>
                                    <i className="fs-lg me-2 ri-hand-coin-fill align-middle"></i> Partnership Sharing
                                </Dropdown.Item>
                                <Dropdown.Item className="border-bottom text-danger py-2" onClick={()=>togglePermission(cell.row.original.userId, cell.row.original.UserName, cell.row?.original?.items)}>
                                    <i className="fs-lg me-2 ri-shield-user-fill align-middle"></i> Permission
                                </Dropdown.Item>
                                <Dropdown.Item className="border-bottom text-dark py-2">
                                    <i className="fs-lg me-2 mdi mdi-human-child align-middle"></i> Child Block Market
                                </Dropdown.Item>
                                <Dropdown.Item className="border-bottom text-success py-2">
                                    <i className="fs-lg me-2 ri-file-list-3-fill align-middle"></i> Report
                                </Dropdown.Item>
                                <Dropdown.Item className="border-bottom text-info py-2" onClick={()=>toggleMarketLimit(cell.row.original.userId, cell.row.original.userSetting, cell.row.original.UserName)}>
                                    <i className="fs-lg me-2 ri-settings-4-fill align-middle"></i> Market Limit
                                </Dropdown.Item>
                                {/* <Dropdown.Item className="border-bottom text-dark py-2" onClick={()=>togglePermissionSetting(cell.row.original.userId)}>
                                    <i className="fs-lg me-2 mdi mdi-account-key align-middle"></i> Permission Setting
                                </Dropdown.Item> */}
                                <Dropdown.Item className="border-bottom text-warning py-2" onClick={()=>toggleCopmmitionSetting(cell.row.original.userId, cell.row.original.UserName)}>
                                    <i className="fs-lg me-2 ri-percent-fill align-middle"></i> Commission Setting
                                </Dropdown.Item>
                                <Dropdown.Item className="border-bottom text-success py-2" onClick={()=>handleParentList(cell.row.original.userId, cell.row.original.UserName)}>
                                    <i className="fs-lg me-2 ri-parent-fill align-middle"></i> Parent List
                                </Dropdown.Item>
                                <Dropdown.Item className="border-bottom text-primary py-2" onClick={()=>nav(`/addChild-user/${cell.row.original.userId}/${cell.row.original.childLevel}`)}>
                                    <i className="fs-lg me-2 ri-user-add-fill align-middle"></i> Add Child
                                </Dropdown.Item>
                                <Dropdown.Item className="border-bottom text-danger py-2" onClick={()=>nav(`/addDemo-user/${cell.row.original.userId}/${cell.row.original.childLevel}`)}>
                                    <i className="fs-lg me-2 ri-login-circle-fill align-middle"></i> Demo Login
                                </Dropdown.Item>
                                <Dropdown.Item className="border-bottom text-secondary py-2" onClick={toggleExposureLimit}>
                                    <i className="fs-lg me-2 ri-file-chart-fill align-middle"></i> Exposure Limit
                                </Dropdown.Item>
                                <Dropdown.Item className="text-info py-2" onClick={()=>toggleEditStake(cell.row.original.userId)}>
                                    <i className="fs-lg me-2 ri-edit-fill align-middle"></i> Edit Stake
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                ),
            },
        ],
        []
    );

    const [userDetails, setUserDetails] = useState(null);

    useEffect(() => {
        const user = localStorage.getItem("adminDetails");

        if (user) {
            try {
                const parsedUser = JSON.parse(user);
                setUserDetails(parsedUser);
            } catch (error) {
                console.error("Failed to parse user details", error);
            }
        }
    }, []); 

  

    return (
        <>
            <TableContainer
                isPagination={true}
                columns={columns}
                data={defaultData}
                sorting={true}
                customPageSize={Math.min(50, defaultData.length)}
                divClassName="table-responsive"
                tableClass="table table-centered align-middle table-custom-effect table-nowrap userListTable mb-0 table-sticky-second table-sticky-head table-sticky-last"
                theadClass="table-light"
                PaginationClassName="align-items-center mt-1 pt-2"
                isBordered={false}
            />

            {/* Modals */}
            <EditCreditReference getUserList={getUserList} userIds={userIds} userDetails={userDetails} userName={userName} creditRef={creditRef} setCreditRef={setCreditRef} show={showCreditReference} clickHandler={toggleCreditReference} />
            <PartnershipModal userName={userName} userIds={userIds} show={showPartnership} clickHandler={togglePartnershipSharing} />
            {/* <PermissionSetting userIds={userIds} show={showPermissionSetting} clickHandler={togglePermissionSetting} /> */}
            <ExposureModal userIds={userIds} show={showExposure} clickHandler={toggleExposure} />
            <CommissionSetting userName={userName} userDetails={userDetails} getUserList={getUserList} userIds={userIds} show={showCommSet} clickHandler={toggleCopmmitionSetting} />
            <DepositModal getUserList={getUserList} userIds={userIds} show={showDeposit} clickHandler={toggleDeposit} />
            <WithdrawModal getUserList={getUserList} userIds={userIds} show={showWithdraw} clickHandler={toggleWithdraw} />
            <ShowPasswordModal userIds={userIds} show={showPasswordModal} clickHandler={toggleShowPassword} />
            <ResetPasswordModal setshowResetPasswordModal={setshowResetPasswordModal} userIds={userIds} show={showResetPasswordModal} clickHandler={toggleResetPassword} />
            <UserMarketLimit userName={userName} settingData={settingData} userIds={userIds} show={showMarketLimit} clickHandler={toggleMarketLimit} />
            <EditStakeModal userIds={userIds} show={showEditStake} clickHandler={toggleEditStake} />
            
            <ExposureLimitModal show={showExposureLimit} clickHandler={toggleExposureLimit} />
            <ParentList  userName={userName} userIds={userIds} show={showParentList} clickHandler={handleParentList} />
            <PermissionModal getUserList={getUserList} userIds={userIds} userName={userName} userData={dataUser} show={showPermission} clickHandler={togglePermission} />
        </>
    );
};

export default UserListTable;