import { Button, Modal, ListGroup } from 'react-bootstrap';

interface MatchListProps {
	show: any;
	clickHandler: any;
}

const MatchList = ({show, clickHandler} : MatchListProps) => {
    return (
        <Modal size="lg" show={show} onHide={clickHandler} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-0">
                <span>Market List</span>
                <Button variant="light btn-sm" onClick={clickHandler}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='pt-0'>
                <div className='d-flex align-items-center mt-3 mb-2'>
                    <Button variant='subtle-primary btn-sm me-2'><i className="bx bx-cricket-ball fs-xl align-middle"></i></Button>
                    <h4 className='text-primary mb-0'>Cricket</h4>
                </div>
                <ListGroup>
                    <ListGroup.Item as='a' className="list-group-item-action d-flex align-items-center">
                        <div>
                            <div className="text-dark fs-md">Sydney FC v Eastern Football Team</div>
                            <div className="d-flex align-items-center fs-2xs">
                                <span className="text-success">INPLAY</span>
                                <span className="mx-2 text-muted">|</span>
                                <span className="text-muted">19/09, 03:30 pm</span><span className="mx-2 text-muted">|</span><span className="text-primary">Cricket</span>
                            </div>
                        </div>
                        <Button variant='subtle-dark btn-sm' className='ms-auto'><i className='fs-md ri-arrow-right-up-line'></i></Button>
                    </ListGroup.Item>
                    <ListGroup.Item as='a' className="list-group-item-action d-flex align-items-center">
                        <div>
                            <div className="text-dark fs-md">Sydney FC v Eastern Football Team</div>
                            <div className="d-flex align-items-center fs-2xs">
                                <span className="text-success">INPLAY</span>
                                <span className="mx-2 text-muted">|</span>
                                <span className="text-muted">19/09, 03:30 pm</span><span className="mx-2 text-muted">|</span><span className="text-primary">Cricket</span>
                            </div>
                        </div>
                        <Button variant='subtle-dark btn-sm' className='ms-auto'><i className='fs-md ri-arrow-right-up-line'></i></Button>
                    </ListGroup.Item>
                    <ListGroup.Item as='a' className="list-group-item-action d-flex align-items-center">
                        <div>
                            <div className="text-dark fs-md">Sydney FC v Eastern Football Team</div>
                            <div className="d-flex align-items-center fs-2xs">
                                <span className="text-success">INPLAY</span>
                                <span className="mx-2 text-muted">|</span>
                                <span className="text-muted">19/09, 03:30 pm</span><span className="mx-2 text-muted">|</span><span className="text-primary">Cricket</span>
                            </div>
                        </div>
                        <Button variant='subtle-dark btn-sm' className='ms-auto'><i className='fs-md ri-arrow-right-up-line'></i></Button>
                    </ListGroup.Item>
                </ListGroup>
                <div className='d-flex align-items-center mt-3 mb-2'>
                    <Button variant='subtle-primary btn-sm me-2'><i className="bx bx-football fs-xl align-middle"></i></Button>
                    <h4 className='text-primary mb-0'>Soccer</h4>
                </div>
                <ListGroup>
                    <ListGroup.Item as='a' className="list-group-item-action d-flex align-items-center">
                        <div>
                            <div className="text-dark fs-md">Sydney FC v Eastern Football Team</div>
                            <div className="d-flex align-items-center fs-2xs">
                                <span className="text-success">INPLAY</span>
                                <span className="mx-2 text-muted">|</span>
                                <span className="text-muted">19/09, 03:30 pm</span><span className="mx-2 text-muted">|</span><span className="text-primary">Soccer</span>
                            </div>
                        </div>
                        <Button variant='subtle-dark btn-sm' className='ms-auto'><i className='fs-md ri-arrow-right-up-line'></i></Button>
                    </ListGroup.Item>
                    <ListGroup.Item as='a' className="list-group-item-action d-flex align-items-center">
                        <div>
                            <div className="text-dark fs-md">Sydney FC v Eastern Football Team</div>
                            <div className="d-flex align-items-center fs-2xs">
                                <span className="text-success">INPLAY</span>
                                <span className="mx-2 text-muted">|</span>
                                <span className="text-muted">19/09, 03:30 pm</span><span className="mx-2 text-muted">|</span><span className="text-primary">Soccer</span>
                            </div>
                        </div>
                        <Button variant='subtle-dark btn-sm' className='ms-auto'><i className='fs-md ri-arrow-right-up-line'></i></Button>
                    </ListGroup.Item>
                    <ListGroup.Item as='a' className="list-group-item-action d-flex align-items-center">
                        <div>
                            <div className="text-dark fs-md">Sydney FC v Eastern Football Team</div>
                            <div className="d-flex align-items-center fs-2xs">
                                <span className="text-success">INPLAY</span>
                                <span className="mx-2 text-muted">|</span>
                                <span className="text-muted">19/09, 03:30 pm</span><span className="mx-2 text-muted">|</span><span className="text-primary">Soccer</span>
                            </div>
                        </div>
                        <Button variant='subtle-dark btn-sm' className='ms-auto'><i className='fs-md ri-arrow-right-up-line'></i></Button>
                    </ListGroup.Item>
                </ListGroup>
                <div className='d-flex align-items-center mt-3 mb-2'>
                    <Button variant='subtle-primary btn-sm me-2'><i className="bx bx-tennis-ball fs-xl align-middle"></i></Button>
                    <h4 className='text-primary mb-0'>Tennis</h4>
                </div>
                <ListGroup>
                    <ListGroup.Item as='a' className="list-group-item-action d-flex align-items-center">
                        <div>
                            <div className="text-dark fs-md">Sydney FC v Eastern Football Team</div>
                            <div className="d-flex align-items-center fs-2xs">
                                <span className="text-success">INPLAY</span>
                                <span className="mx-2 text-muted">|</span>
                                <span className="text-muted">19/09, 03:30 pm</span><span className="mx-2 text-muted">|</span><span className="text-primary">Soccer</span>
                            </div>
                        </div>
                        <Button variant='subtle-dark btn-sm' className='ms-auto'><i className='fs-md ri-arrow-right-up-line'></i></Button>
                    </ListGroup.Item>
                    <ListGroup.Item as='a' className="list-group-item-action d-flex align-items-center">
                        <div>
                            <div className="text-dark fs-md">Sydney FC v Eastern Football Team</div>
                            <div className="d-flex align-items-center fs-2xs">
                                <span className="text-success">INPLAY</span>
                                <span className="mx-2 text-muted">|</span>
                                <span className="text-muted">19/09, 03:30 pm</span><span className="mx-2 text-muted">|</span><span className="text-primary">Soccer</span>
                            </div>
                        </div>
                        <Button variant='subtle-dark btn-sm' className='ms-auto'><i className='fs-md ri-arrow-right-up-line'></i></Button>
                    </ListGroup.Item>
                    <ListGroup.Item as='a' className="list-group-item-action d-flex align-items-center">
                        <div>
                            <div className="text-dark fs-md">Sydney FC v Eastern Football Team</div>
                            <div className="d-flex align-items-center fs-2xs">
                                <span className="text-success">INPLAY</span>
                                <span className="mx-2 text-muted">|</span>
                                <span className="text-muted">19/09, 03:30 pm</span><span className="mx-2 text-muted">|</span><span className="text-primary">Soccer</span>
                            </div>
                        </div>
                        <Button variant='subtle-dark btn-sm' className='ms-auto'><i className='fs-md ri-arrow-right-up-line'></i></Button>
                    </ListGroup.Item>
                </ListGroup>
                <div className='d-flex align-items-center mt-3 mb-2'>
                    <Button variant='subtle-primary btn-sm me-2'><i className="mdi mdi-poker-chip fs-xl align-middle"></i></Button>
                    <h4 className='text-primary mb-0'>Casino</h4>
                </div>
                <ListGroup>
                    <ListGroup.Item as='a' className="list-group-item-action d-flex align-items-center">
                        <div>
                            <div className="text-dark fs-md">Sydney FC v Eastern Football Team</div>
                            <div className="d-flex align-items-center fs-2xs">
                                <span className="text-success">INPLAY</span>
                                <span className="mx-2 text-muted">|</span>
                                <span className="text-muted">19/09, 03:30 pm</span><span className="mx-2 text-muted">|</span><span className="text-primary">Soccer</span>
                            </div>
                        </div>
                        <Button variant='subtle-dark btn-sm' className='ms-auto'><i className='fs-md ri-arrow-right-up-line'></i></Button>
                    </ListGroup.Item>
                    <ListGroup.Item as='a' className="list-group-item-action d-flex align-items-center">
                        <div>
                            <div className="text-dark fs-md">Sydney FC v Eastern Football Team</div>
                            <div className="d-flex align-items-center fs-2xs">
                                <span className="text-success">INPLAY</span>
                                <span className="mx-2 text-muted">|</span>
                                <span className="text-muted">19/09, 03:30 pm</span><span className="mx-2 text-muted">|</span><span className="text-primary">Soccer</span>
                            </div>
                        </div>
                        <Button variant='subtle-dark btn-sm' className='ms-auto'><i className='fs-md ri-arrow-right-up-line'></i></Button>
                    </ListGroup.Item>
                    <ListGroup.Item as='a' className="list-group-item-action d-flex align-items-center">
                        <div>
                            <div className="text-dark fs-md">Sydney FC v Eastern Football Team</div>
                            <div className="d-flex align-items-center fs-2xs">
                                <span className="text-success">INPLAY</span>
                                <span className="mx-2 text-muted">|</span>
                                <span className="text-muted">19/09, 03:30 pm</span><span className="mx-2 text-muted">|</span><span className="text-primary">Soccer</span>
                            </div>
                        </div>
                        <Button variant='subtle-dark btn-sm' className='ms-auto'><i className='fs-md ri-arrow-right-up-line'></i></Button>
                    </ListGroup.Item>
                </ListGroup>
            </Modal.Body>
        </Modal>
    );
};

export default MatchList;