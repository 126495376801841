import BreadCrumb from "Common/BreadCrumb";
import ToggleSwitch from "Common/ToggleSwitch";
import { useCallback, useEffect, useState } from "react";
import { Container, Card, Form, Button, Table, Pagination, Modal, Dropdown, Accordion, useAccordionButton, OverlayTrigger, Tooltip, Popover, ListGroup, Collapse, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { authServices } from "Utils/auth/services";
import snackbarUtil from "Utils/snackBarUtil";
import LimitSetting from "./LimitSetting";
import moment from "moment";

interface CustomToggleProps {
    eventKey?: any;
    children?: any;
}



const ImportMarket = () => {
    const [showOn, setShowOn] = useState(false);
    const [matchVisible, setMatchVisible] = useState(false);
    const [marketSetting, setMarketSetting] = useState(false);
    const [matchLock, setMatchLock] = useState(false);
    const [showTvScore, setTvScore] = useState(false);
    const [deleteMatch, setDeleteMatch] = useState(false);
    const [showPassword, setPassword] = useState(false);
    const [sportSeries, setSportSeries] = useState<{ [key: string]: seriesRes[] }>({});
    const [sportMatches, setSportMatches] = useState<{ [key: string]: MatchesRes[] }>({});
    const [sportName, setSportName] = useState<sportList[]>([]);
    const [sportIds, setSportids] = useState<string>("");

    const [sportNameMarket, setSportiNameMarket] = useState<string>("");
    const [limits, setLimits] = useState<LimitResponse>();
    const [marketName, setMarkrtName] = useState<string>("");
    const [unmatchedBetVisible, setUnmatchedBetVisible] = useState<{ [key: string]: boolean }>({});
    const [unMatedBet, setUnMatchedBet] = useState<boolean>();
    const [noOfBets, setNoofBets] = useState<string>("");
    const [marketData, setMarketData] = useState<MatchesRes[]>([]);
    const [seriesIdss, setSeriesids] = useState<string>();
    const [matchid, setMatchid] = useState<string>("");
    const [showNews, setShowNews] = useState<boolean>(false);
    const [newsValues, setNewsValues] = useState<string>("");
    const [tvData, setTvData] = useState<SocreTVRes>({
        match_scoreboard_url: "",
        non_premimum_match_tv_url: "",
        premimum_match_tv_url: ""
    });



    const getUpdateEvent = async (is_visible: boolean, marketName: any, event: string, sportId: string, series_id: string) => {
        const { response } = await authServices?.updateEvent({
            event,
            filter: {
                [marketName]: sportId
            },
            update: {
                is_visible,
            }
        })
        if (response?.status) {
            snackbarUtil.success(response?.data);
            if (event === "sport") {
                getSport();
            } else if (event === "series") {
                getSeriesData(sportIds)
            } else if (event === "match") {
                getMatchesData(sportId, series_id);
            }else if(event === "market"){
                getMarketData(series_id);
            }
        }
    }
    const getUpdateEventNews = async (data:any) => {
        const { response } = await authServices?.updateEvent({
            event:data?.eventName,
            filter: {
                [data?.sportName]: data?.sportId
            },
            update: {
                news:newsValues,
            }
        })
        if (response?.status) {
           snackbarUtil.success(response?.data);
           getMarketData(data?.series_id);
        }
    }



    const toggleVisible = (is_visible: boolean, sportName: string, eventName: string, sportId: string, series_id: string) => {
        setMatchVisible(!matchVisible);
        getUpdateEvent(is_visible, sportName, eventName, sportId, series_id);
    }

    const [newsPaylod, setNewsPayload] = useState({
        sportName:"",
        eventName:"",
        sportId:"",
        series_id:""
    })

    const toggleNews = ( sportName: string, eventName: string, sportId: string, series_id: string) => {
        setShowNews(true);
        setNewsPayload((prev)=>({
            ...prev,
            sportName,
            eventName,
            sportId,
            series_id
        }))
    }

    const handleUpdateNews = ()=>{
        console.log(newsPaylod, "newsPaylod")
        getUpdateEventNews(newsPaylod)
    }

    const getLimit = async (id: string, mrktName: string,) => {
        if (sportIds) {
            const { response } = await authServices?.getLimits({
                [mrktName]: id
            })
            setLimits(response?.data);
            setUnMatchedBet(response?.data?.limites?.unmatch_bet_allowed);
            setNoofBets(response?.data?.limites?.no_of_unmatch_bet_allowed)
        }

    }
    const toggleMarketSetting = (name: string, mrktName: string, Id: string) => {
        setMarketSetting(true);
        setMarkrtName(mrktName)
        setSportiNameMarket(name);
        setSeriesids(Id);
        getLimit(Id, mrktName);
    }
    const closeMarketSetting = () => {
        setMarketSetting(false)
    }
    const toggleUnmatchedBet = (sportId: string, mektName: string, name: string) => {
        getLimit(sportId, mektName);
        setMarkrtName(name);
        setUnmatchedBetVisible((prev) => ({
            ...prev,
            [sportId]: !prev[sportId],
        }))
    };
    const toggleUnmatchedBetMatched = (sportId: string, mektName: string, name: string) => {
        getLimit(sportId, mektName);
        setMarkrtName(name);
        setUnmatchedBetVisible((prev) => ({
            ...prev,
            [sportId]: !prev[sportId],
        }))
    };
    
    const toggleUnmatchedBetMarket = (sportId: string, mektName: string, name: string) => {
        getLimit(sportId, mektName);
        setMarkrtName(name);
        setUnmatchedBetVisible((prev) => ({
            ...prev,
            [sportId]: !prev[sportId],
        }))
    };
    const toggleMatchLock = (is_lock: boolean, mrktName: string, id: string, sport_id: string, series_id: string, isName: string) => {
        getUpdateLimits(!is_lock, mrktName, id, sport_id, series_id, isName);

    }


    const getSport = async () => {
        const { response } = await authServices.getSportListData({});
        setSportName(response?.data);
    };

    const getSeriesData = async (sportId: string) => {
        try {
            const { response } = await authServices.getSeriesData({ sport_id: sportId });
            setSportSeries(prev => ({ ...prev, [sportId]: response?.data || [] }));
            setSportids(sportId)
        } catch (error) {
            console.error("Error fetching series data:", error);
        }



    }

    const handleSportSeriesData = (sportId: string, is_active: number) => {
        getSeriesData(sportId);
    };

    const getMatchesData = async (sport_id: string, series_id: string) => {
        try {
            const { response } = await authServices.getMatchesData({ sport_id, series_id });
            setSportMatches(prev => ({ ...prev, [series_id]: response?.data || [] }));
        } catch (error) {
            console.error("Error fetching matches:", error);
        }
    };


    // Update Sport Status

    const userDetails = localStorage.getItem("adminDetails");
    const userId = userDetails && JSON.parse(userDetails)

    const getUpdateStatus = async (is_active: number) => {
        const { response } = await authServices.getUpdateSportServices({
            is_active,
            sport_id: sportIds,
            user_typeId: userId?.user_type_id,
            userid: userId?._id
        })

        if (response?.status) {
            snackbarUtil.success(response.msg);
            getSport();
        }
    }
    const handleMatches = (seriesId: string, sportId: string) => {
        getMatchesData(sportId, seriesId);
    };

    useEffect(() => {
        getSport();
    }, []);
    const handleActiveSport = (sport: { sport_id: string; is_active: number }) => {
        const newStatus = sport?.is_active === 1 ? 0 : 1;
        console.log("Toggling status for sport_id:", sport, sport.sport_id, "from", sport.is_active, "to", newStatus);
        getUpdateStatus(newStatus);
    };

    const getUpdateLimits = async (is_lock: boolean, marketName: string, id: string, sport_id: string, series_id: string, isName: string) => {
        const { response } = await authServices.updateLimits({
            [marketName]: id,
            values: {
                [isName]: is_lock,
            }
        })

        if (response.status) {
            snackbarUtil.success(response.msg);
            getMatchesData(sport_id, series_id)

        } else {
            snackbarUtil.error(response.msg)
        }
    }

    // for No of Bet Aloow
    const getUpdateLimitsBets = async (marketName: string, id: string, sportId?: string | undefined) => {
        let payLoad: any;
        if (sportId) {
            payLoad = {
                [marketName]: id,
                values: {
                    unmatch_bet_allowed: unMatedBet,
                    no_of_unmatch_bet_allowed: Number(noOfBets),
                },
                sport_id: sportId || ""
            }
        } else {
            payLoad = {
                [marketName]: id,
                values: {
                    unmatch_bet_allowed: unMatedBet,
                    no_of_unmatch_bet_allowed: Number(noOfBets),
                }
            }
        }
        const { response } = await authServices.updateLimits(payLoad)

        if (response.status) {
            snackbarUtil.success(response.msg);
            getLimit(id, marketName);
            toggleUnmatchedBet(id, marketName, "");

        } else {
            snackbarUtil.error(response.msg)
        }
    }


    const getMarketData = async (match_id: string) => {
        const { response } = await authServices.getMarketData({
            match_id,
            sport_id: sportIds
        });

        setMarketData(response?.data)
    }


    const handleMarketData = (matchid: string) => {
        getMarketData(matchid)
    }


    // Series Api and Functionality


    const getSeriesUpdateStatus = async (is_active: number, series_id: string) => {
        const { response } = await authServices.getSeriesUpdate({
            is_active,
            series_id,
            user_typeId: userId.user_type_id,
            userid: userId._id
        })
        if (response?.status) {
            snackbarUtil.success(response?.msg);
            getSeriesData(sportIds);
        }
    }
    const handleUpdateSeries = (active: number, series_id: string) => {
        getSeriesUpdateStatus(active, series_id);
    };

    const createMatched = async (is_manual: number, match_date: string, match_id: string, name: string, series_id: string, sport_id: string) => {
        const { response } = await authServices.createMatched({
            is_manual,
            match_date,
            match_id,
            name,
            series_id,
            sport_id
        })
        if (response?.status) {
            snackbarUtil.success(response?.msg);
        }
    }

    const handelCreate = (is_manual: number, match_date: string, match_id: string, name: string, series_id: string, sport_id: string) => {
        createMatched(is_manual, match_date, match_id, name, series_id, sport_id)
    }

    let macthName;

    const getTvScore = async (match_id: string) => {
        const { response } = await authServices.getTvScore({
            match_id,
        })
        setTvData((prev) => ({
            ...prev,
            ...response?.data
        }));
    }
    const getUpdateScore = async () => {
        const { response } = await authServices.getUpdateUrl({
            match_id: matchid,
            update: tvData,
        })
        if (response?.status) {
            snackbarUtil.success(response?.data);
            setTvScore(!showTvScore);
        }
    }



    const handleTvScore = (id: string, name: string) => {
        macthName = name;
        setMatchid(id)
        getTvScore(id);
        setTvScore(!showTvScore);
    }

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setTvData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleUpdateTv = () => {
        getUpdateScore();
    }

    const getEnabaledFancy = async (enable_fancy: number, match_id: string, sport_id: string, series_id: string) => {
        const { response } = await authServices.getEnabaledFancy({
            enable_fancy,
            match_id
        })
        if (response?.status) {
            snackbarUtil.success(response?.msg);
            getMatchesData(sport_id, series_id);
        }
    }

    const handleFancy = (enable_fancy: number, id: string, sport_id: string, series_id: string) => {
        const newData = enable_fancy === 0 ? 1 : 0
        getEnabaledFancy(newData, id, sport_id, series_id)
    }

    const navigator = useNavigate();

    const handleUnMatchedBets = (matkName: string, id: string, sportid?: string) => {
        getUpdateLimitsBets(matkName, id, sportid);
        // setSportName(sport_name)
    }

    const updateMarketStatus = async (is_active: number, market_id: string, match_id: string) => {
        const { response } = await authServices?.updateMarketStatus({
            is_active,
            market_id
        })
        if (response?.status) {
            getMarketData(match_id);
            snackbarUtil.success(response?.msg)
        }
    }

    const handleMarketLock = (is_active: number, market_id: string, match_id: string) => {
        const newData = is_active === 0 ? 1 : 0;
        updateMarketStatus(newData, market_id, match_id);
    }

    return (
        <div className="page-content">
            <Container fluid>
                <div className="position-relative mb-2">
                    <BreadCrumb title="Import Market" pageTitle="Dashboard" back />
                </div>
                <Accordion defaultActiveKey='0' className="custom-accordion-border accordion-border-box accordion-dark">
                    {
                        sportName?.map((sport) => {
                            return (
                                <Accordion.Item key={sport?.name} eventKey={sport?.name} className="position-relative">
                                    <Accordion.Header onClick={() => handleSportSeriesData(sport?.sport_id, sport?.is_active)}>{sport?.name}</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="mb-2 d-flex align-items-center">
                                            <ToggleSwitch On='On' Off='Off' className="me-2" id={`match-${sport?.sport_id}`} onChange={() => handleActiveSport(sport)} checked={sport?.is_active === 1} />

                                            <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> Market Setting </Tooltip>}>
                                                <Button variant='btn-ghost py-1 px-2 me-1' id={`match-${sport?.sport_id}`} onClick={() => toggleMarketSetting(sport?.name, "sport_id", sport?.sport_id)}><i className='ri-settings-3-fill fs-xl'></i></Button>
                                            </OverlayTrigger>

                                            <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> {!sport?.is_visible ? 'Invisible' : 'Visible'} </Tooltip>}>
                                                <Button variant='btn-ghost py-1 px-2 me-2' id={`match-${sport?.sport_id}`} onClick={() => toggleVisible(!sport?.is_visible, "sport_id", "sport", sport?.sport_id, "")}><i className={`fs-xl ${!sport?.is_visible ? 'ri-eye-off-fill text-danger' : 'ri-eye-fill text-success'}`}></i></Button>
                                            </OverlayTrigger>

                                            <OverlayTrigger trigger="click" placement="right" show={!!unmatchedBetVisible[sport.sport_id]}
                                                overlay={
                                                    <Popover id="popover-positioned-top">
                                                        <Popover.Header as="h3">{marketName} Settings
                                                            : <span>({userId?.user_name})</span>
                                                        </Popover.Header>
                                                        <Popover.Body>
                                                            <div className="d-flex align-items-center">
                                                                <strong className="fs-lg me-2">UnMatched Bet Allowed </strong>
                                                                <ToggleSwitch On="Yes" Off="No" checked={unMatedBet} onChange={(e: any) => setUnMatchedBet(e.target.checked)} />
                                                            </div>
                                                            {
                                                                unMatedBet && <Col lg={12} className="pt-2">
                                                                    <div className="form-floating">
                                                                        <Form.Control
                                                                            type="Number"
                                                                            name="parent"
                                                                            placeholder="no of unmatch bet allowed"

                                                                            value={noOfBets}
                                                                            onChange={(e) => setNoofBets(e.target.value)}
                                                                        />
                                                                        <Form.Label>no of unmatch bet allowed</Form.Label>
                                                                    </div>
                                                                </Col>
                                                            }
                                                            <div className="border-top mt-3 pt-3 text-end">
                                                                <Button variant="subtle-success" onClick={() => handleUnMatchedBets("sport_id", sport?.sport_id)}>Save</Button>
                                                                <Button variant="subtle-dark" className="ms-2" onClick={() => toggleUnmatchedBet(sport.sport_id, "sport_id", sport?.name)}>Cancel</Button>
                                                            </div>
                                                        </Popover.Body>
                                                    </Popover>
                                                }>
                                                <Button variant="subtle-secondary" className="p-1" onClick={() => toggleUnmatchedBet(sport.sport_id, "sport_id", sport?.name)}>UA</Button>
                                            </OverlayTrigger>

                                            <div className="ms-3 w-25">
                                                <div className="form-icon">
                                                    <Form.Control type="text" className="form-control form-control-icon rounded-pill" placeholder="Search Series.." />
                                                    <i className="ri-search-line"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <Accordion className="custom-accordion-border accordion-border-box accordion-dark">
                                            {
                                                sportSeries[sport.sport_id]?.map((series) => {
                                                    return (
                                                        <Accordion.Item key={series?.series_id} eventKey={series?.name} className="position-relative">
                                                            <Accordion.Header onClick={() => handleMatches(series?.series_id, series?.sport_id)}>{series?.name} <span className="mx-2 text-muted">|</span> <span className="">{series?.match_count}</span> <span className="mx-2 text-muted">|</span> <span className="">{series?.series_id}</span>
                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                                <div className="mb-2 d-flex align-items-center">
                                                                    <ToggleSwitch On='On' Off='Off' className="me-2" id={`series-${series?.series_id}`} onChange={() => handleUpdateSeries(series?.is_active === 1 ? 0 : 1, series?.series_id)} checked={series?.is_active === 1 ? true : false} />

                                                                    <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> Market Setting </Tooltip>}>
                                                                        <Button variant='btn-ghost py-1 px-2 me-1' id={`series-${series?.series_id}`}
                                                                            onClick={() => toggleMarketSetting(series?.name, "series_id", series?.series_id)}
                                                                        ><i className='ri-settings-3-fill fs-xl'></i></Button>
                                                                    </OverlayTrigger>

                                                                    <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> {matchVisible ? 'Invisible' : 'Visible'} </Tooltip>}>
                                                                        <Button variant='btn-ghost py-1 px-2 me-2' onClick={() => toggleVisible(!series?.is_visible, "series_id", "series", series?.series_id, "")}><i className={`fs-xl ${series?.is_visible ? 'ri-eye-off-fill text-danger' : 'ri-eye-fill text-success'}`}></i></Button>
                                                                    </OverlayTrigger>

                                                                    <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> Active </Tooltip>}>
                                                                        <Button variant='subtle-success py-1 px-2 me-2'>A</Button>
                                                                    </OverlayTrigger>

                                                                    <div className="ms-2 w-25">
                                                                        <div className="form-icon">
                                                                            <Form.Control type="text" className="form-control form-control-icon rounded-pill" placeholder="Search Match.." />
                                                                            <i className="ri-search-line"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <Accordion className="custom-accordion-border accordion-border-box accordion-dark">
                                                                    {
                                                                        sportMatches[series?.series_id]?.map((matches) => {
                                                                            return (
                                                                                <Accordion.Item eventKey={matches?.match_id} className="position-relative">
                                                                                    <Accordion.Header onClick={() => handleMarketData(matches?.match_id)}>{matches?.match_name} ({matches?.country_code}) <span className="mx-2 text-muted">|</span> <span className="">{matches?.match_id}</span> <span className="mx-2 text-muted">|</span> <span className="">{moment(matches.match_date)?.format("DD-MM-YYYY, HH:MM:SS, A")}</span>
                                                                                    </Accordion.Header>
                                                                                    <Accordion.Body>
                                                                                        <div className="d-flex align-items-center mb-2">
                                                                                            <ToggleSwitch On="On" Off="Off" id={matches?.match_name} onChange={() => handelCreate(matches?.is_manual, matches?.match_date, matches?.match_id, matches?.match_name, matches?.series_id, matches?.sport_id)} checked={matches?.is_manual === 1} />

                                                                                            <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> Delete Match </Tooltip>}>
                                                                                                <Button variant='btn-ghost py-1 px-2 ms-2' onClick={() => setDeleteMatch(!deleteMatch)}><i className='ri-delete-bin-3-fill fs-xl'></i></Button>
                                                                                            </OverlayTrigger>

                                                                                            <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> {matches?.is_lock ? 'Lock' : 'UnLock'} </Tooltip>}>
                                                                                                <Button variant='btn-ghost py-1 px-2 me-1' id={matches?.match_name}
                                                                                                    onClick={() => toggleMatchLock(matches?.is_lock, "match_id", matches?.match_id, matches?.sport_id, matches?.series_id, "is_lock")}
                                                                                                >
                                                                                                    <i className={`fs-xl ${matches?.is_lock ? 'ri-lock-fill' : 'ri-lock-unlock-fill'}`}></i></Button>
                                                                                            </OverlayTrigger>

                                                                                            <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> Active </Tooltip>}>
                                                                                                <Button variant='subtle-success py-1 px-2 me-2'>A</Button>
                                                                                            </OverlayTrigger>

                                                                                            <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> Create </Tooltip>}>
                                                                                                <Button variant='subtle-danger py-1 px-2 me-2'>C</Button>
                                                                                            </OverlayTrigger>

                                                                                            <OverlayTrigger  trigger="click" placement="right" show={!!unmatchedBetVisible[matches.match_id]}
                                                                                                overlay={
                                                                                                    <Popover id="popover-positioned-top">
                                                                                                        <Popover.Header as="h3">{marketName} Settings
                                                                                                            : <span>({userId?.user_name})</span>
                                                                                                        </Popover.Header>
                                                                                                        <Popover.Body>
                                                                                                            <div className="d-flex align-items-center">
                                                                                                                <strong className="fs-lg me-2">UnMatched Bet Allowed </strong>
                                                                                                                <ToggleSwitch On="Yes" Off="No" checked={unMatedBet} onChange={(e: any) => setUnMatchedBet(e.target.checked)} />
                                                                                                            </div>
                                                                                                            {
                                                                                                                unMatedBet && <Col lg={12} className="pt-2">
                                                                                                                    <div className="form-floating">
                                                                                                                        <Form.Control
                                                                                                                            type="Number"
                                                                                                                            name="parent"
                                                                                                                            placeholder="no of unmatch bet allowed"

                                                                                                                            value={noOfBets}
                                                                                                                            onChange={(e) => setNoofBets(e.target.value)}
                                                                                                                        />
                                                                                                                        <Form.Label>no of unmatch bet allowed</Form.Label>
                                                                                                                    </div>
                                                                                                                </Col>
                                                                                                            }

                                                                                                            <Button className="mt-2" variant="subtle-success" onClick={() => handleUnMatchedBets("match_id", matches.match_id, matches?.sport_id)}>Save</Button>
                                                                                                            <Button className="mt-2" variant="subtle-dark" onClick={() => toggleUnmatchedBetMatched(matches.match_id, "match_id", matches?.name)}>Cancel</Button>

                                                                                                        </Popover.Body>
                                                                                                    </Popover>
                                                                                                }>
                                                                                                <Button variant="subtle-secondary" className="p-1" onClick={() => toggleUnmatchedBetMatched(matches.match_id, "match_id", matches?.name)}>UA</Button>
                                                                                            </OverlayTrigger>

                                                                                            <Dropdown drop="start" className="ms-3">
                                                                                                <Dropdown.Toggle variant="primary" className="e-caret-hide btn-sm">
                                                                                                    <i className="fs-lg ri-more-2-fill"></i>
                                                                                                </Dropdown.Toggle>
                                                                                                <Dropdown.Menu className="relative">
                                                                                                    <Dropdown.Item className="border-bottom py-2" onClick={() => toggleMarketSetting(matches?.match_name, "match_id", matches?.match_id)}>Market Setting</Dropdown.Item>

                                                                                                    <Dropdown.Item className={`border-bottom py-2 ${matches?.enable_fancy === 1 ? "text-success" : "text-danger"}`} onClick={() => handleFancy(matches?.enable_fancy, matches?.match_id, matches?.sport_id, matches?.series_id)}>Fancy</Dropdown.Item>
                                                                                                    <Dropdown.Item className={`border-bottom py-2 ${matches?.is_visible ? "text-success" : "text-danger"}`} onClick={() => toggleVisible(!matches?.is_visible, "match_id", "match", matches?.match_id, matches?.series_id)}>Visible</Dropdown.Item>
                                                                                                    <Dropdown.Item className={`border-bottom py-2 ${matches?.inplay ? "text-success" : "text-danger"}`} onClick={() => toggleMatchLock(matches?.inplay, "match_id", matches?.match_id, matches?.sport_id, matches?.series_id, "inplay")}>Manual Inplay</Dropdown.Item>
                                                                                                    <Dropdown.Item className="border-bottom py-2" onClick={() => handleTvScore(matches?.match_id, matches?.match_name)}>Update TV & Score</Dropdown.Item>
                                                                                                    <Dropdown.Item className="border-bottom py-2" onClick={() => navigator(`/match-detail/${matches?.match_id}/${matches?.sport_id}`)}>Route</Dropdown.Item>
                                                                                                </Dropdown.Menu>

                                                                                            </Dropdown>

                                                                                        </div>
                                                                                        {
                                                                                            marketData?.map((market) => {
                                                                                                return (
                                                                                                    <ListGroup className="list-group-fill-success">
                                                                                                        <ListGroup.Item as='a' className="list-group-item-action">
                                                                                                            <div className="d-flex align-items-center">
                                                                                                                <strong className="me-3">{market?.name} <span className="mx-2">|</span> <span className="text-muted">({market?.market_id})</span> <span className="mx-2">|</span> <span className="text-muted">({market?.centralId})</span></strong>

                                                                                                                <ToggleSwitch id={market?.name} On="On" Off="Off" checked={market?.is_active === 1} onChange={() => handleMarketLock(market?.is_active, market?.market_id, market?.match_id)} />

                                                                                                                <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> Delete Match </Tooltip>}>
                                                                                                                    <Button variant='btn-ghost py-1 px-2 ms-2'
                                                                                                                    //  onClick={toggleMarketSetting}
                                                                                                                    ><i className='ri-delete-bin-3-fill fs-xl'></i></Button>
                                                                                                                </OverlayTrigger>

                                                                                                                <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> {matchLock ? 'Lock' : 'UnLock'} </Tooltip>}>
                                                                                                                    <Button variant='btn-ghost py-1 px-2 me-1'
                                                                                                                    //  onClick={toggleMatchLock}
                                                                                                                    ><i className={`fs-xl ${matchLock ? 'ri-lock-fill' : 'ri-lock-unlock-fill'}`}></i></Button>
                                                                                                                </OverlayTrigger>

                                                                                                                <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> Active </Tooltip>}>
                                                                                                                    <Button variant='subtle-success py-1 px-2 me-2'>A</Button>
                                                                                                                </OverlayTrigger>

                                                                                                                <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> Create </Tooltip>}>
                                                                                                                    <Button variant='subtle-danger py-1 px-2 me-2'>C</Button>
                                                                                                                </OverlayTrigger>

                                                                                                                <OverlayTrigger trigger="click" placement="left" show={!!unmatchedBetVisible[market?.market_id]}
                                                                                                                        overlay={
                                                                                                                            <Popover id="popover-positioned-top">
                                                                                                                                <Popover.Header as="h3">{marketName} Settings
                                                                                                                                    : <span>({userId?.user_name})</span>
                                                                                                                                </Popover.Header>
                                                                                                                                <Popover.Body>
                                                                                                                                    <div className="d-flex align-items-center">
                                                                                                                                        <strong className="fs-lg me-2">UnMatched Bet Allowed </strong>
                                                                                                                                        <ToggleSwitch On="Yes" Off="No" checked={unMatedBet} onChange={(e: any) => setUnMatchedBet(e.target.checked)} />
                                                                                                                                    </div>
                                                                                                                                    {
                                                                                                                                        unMatedBet && <Col lg={12} className="pt-2">
                                                                                                                                            <div className="form-floating">
                                                                                                                                                <Form.Control
                                                                                                                                                    type="Number"
                                                                                                                                                    name="parent"
                                                                                                                                                    placeholder="no of unmatch bet allowed"

                                                                                                                                                    value={noOfBets}
                                                                                                                                                    onChange={(e) => setNoofBets(e.target.value)}
                                                                                                                                                />
                                                                                                                                                <Form.Label>no of unmatch bet allowed</Form.Label>
                                                                                                                                            </div>
                                                                                                                                        </Col>
                                                                                                                                    }

                                                                                                                                    <Button className="mt-2" variant="subtle-success" onClick={() => handleUnMatchedBets("market_id", market.market_id, market?.sport_id)}>Save</Button>
                                                                                                                                    <Button className="mt-2" variant="subtle-dark" onClick={() => toggleUnmatchedBetMarket(market.market_id, "market_id", market?.name)}>Cancel</Button>

                                                                                                                                </Popover.Body>
                                                                                                                            </Popover>
                                                                                                                        }>

                                                                                                                        <Button id={market?.market_id} variant="subtle-secondary" className="p-1" onClick={() => toggleUnmatchedBetMarket(market.market_id, "market_id", market?.name)}>UA</Button>
                                                                                                                    </OverlayTrigger>

                                                                                                                <Dropdown drop="start" className="ms-auto">
                                                                                                                    <Dropdown.Toggle variant="primary" className="e-caret-hide btn-sm">
                                                                                                                        <i className="fs-lg ri-more-2-fill"></i>
                                                                                                                    </Dropdown.Toggle>
                                                                                                                    
                                                                                                                    <Dropdown.Menu>
                                                                                                                        <Dropdown.Item className="border-bottom py-2" onClick={() => toggleMarketSetting(market?.name, "market_id", market?.market_id)}>Market Setting</Dropdown.Item>
                                                                                                                        {/* <Dropdown.Item className="border-bottom py-2 text-success">Fancy</Dropdown.Item> */}
                                                                                                                        <Dropdown.Item className={`border-bottom py-2 ${market?.is_visible ? "text-success" : "text-danger"}`} onClick={() => toggleVisible(!market?.is_visible, "market_id", "market", market?.market_id, market?.match_id)}>Visible</Dropdown.Item>
                                                                                                                        <Dropdown.Item className="py-2" onClick={() => toggleNews("market_id", "market", market?.market_id, market?.match_id)}>Update News</Dropdown.Item>
                                                                                                                    </Dropdown.Menu>
                                                                                                                </Dropdown>
                                                                                                            </div>
                                                                                                        </ListGroup.Item>
                                                                                                    </ListGroup>
                                                                                                )
                                                                                            })
                                                                                        }


                                                                                    </Accordion.Body>
                                                                                </Accordion.Item>
                                                                            )
                                                                        })
                                                                    }


                                                                </Accordion>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    )
                                                })
                                            }


                                        </Accordion>
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })
                    }
                </Accordion>
            </Container>

            <LimitSetting seriesIdss={seriesIdss} marketName={marketName} limits={limits} name={sportNameMarket} sportIds={sportIds} getLimit={getLimit} show={marketSetting} clickHandler={closeMarketSetting} />

            <Modal show={showTvScore}>
                <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                    <div>Update TV & Score <span className='text-muted fw-normal'>({macthName})</span></div>
                    <Button variant="light btn-sm" onClick={() => setTvScore(!showTvScore)}>
                        <i className="ri-close-line fs-xl align-middle"></i>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-floating">
                        <Form.Control
                            name="premimum_match_tv_url"
                            as="textarea"
                            rows={2}
                            style={{ height: 'auto' }}
                            id="PremiumTV"
                            value={tvData?.premimum_match_tv_url}
                            placeholder="Premium TV"
                            onChange={handleInputChange}
                        />
                        <Form.Label htmlFor="Content">Premium TV</Form.Label>
                    </div>
                    <div className="form-floating mt-3">
                        <Form.Control
                            name="non_premimum_match_tv_url"
                            as="textarea"
                            rows={2}
                            style={{ height: 'auto' }}
                            id="NormalTV"
                            placeholder="Normal TV"
                            value={tvData?.non_premimum_match_tv_url}
                            onChange={handleInputChange}
                        />
                        <Form.Label htmlFor="Content">Normal TV</Form.Label>
                    </div>
                    <div className="form-floating mt-3">
                        <Form.Control
                            name="match_scoreboard_url"
                            as="textarea"
                            rows={2}
                            style={{ height: 'auto' }}
                            id="Score"
                            placeholder="Score"
                            value={tvData?.match_scoreboard_url}
                            onChange={handleInputChange}
                        />
                        <Form.Label htmlFor="Content">Score</Form.Label>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="subtle-primary" onClick={handleUpdateTv}>Update</Button>
                    <Button variant="subtle-dark" onClick={() => setTvScore(!showTvScore)}>Cancel</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={deleteMatch} onHide={() => setDeleteMatch(!deleteMatch)}>
                <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                    Delete Match
                    <Button variant="light btn-sm" onClick={() => setDeleteMatch(!deleteMatch)}>
                        <i className="ri-close-line fs-xl align-middle"></i>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-floating">
                        <Form.Control
                            name="password"
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                        />
                        <Form.Label>Enter Password</Form.Label>
                        <Button
                            variant="link"
                            className="position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
                            type="button"
                            id="password-addon"
                            onClick={() => setPassword(!showPassword)}
                        >
                            <i className="ri-eye-fill align-middle"></i>
                        </Button>
                    </div>

                    <div className="form-check mt-3">
                        <Form.Check type="checkbox" id="formCheck" defaultChecked />
                        <Form.Label className="form-check-label" htmlFor="formCheck">
                            Do you want to delete all market
                        </Form.Label>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="subtle-danger">Delete</Button>
                    <Button variant="subtle-dark" onClick={() => setDeleteMatch(!deleteMatch)}>Cancel</Button>
                </Modal.Footer>
            </Modal>

           

            <Modal show={showNews} onHide={()=>setShowNews(false)} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <span>Update News</span>
                <Button variant="light btn-sm" onClick={()=>setShowNews(false)}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='fs-md'>
                <div className="form-floating">
                    <Form.Control onChange={(e)=>setNewsValues(e.target.value)} value={newsValues} as="textarea" rows={5} id="Content" style={{height: 'auto'}} placeholder='Content' />
                    <Form.Label htmlFor="Content">Content</Form.Label>
                </div>
            </Modal.Body>
            <Modal.Footer className='border-top'>
                <Button variant='light' onClick={()=>setShowNews(false)}>Cancel</Button>
                <Button className='ms-2' onClick={handleUpdateNews}>Update</Button>
            </Modal.Footer>
        </Modal>
        </div>
    );
};

export default ImportMarket;