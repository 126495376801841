import { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

interface ExposureLimitModalProps {
	show: any;
	clickHandler: any;
}

const ExposureLimitModal = ({show, clickHandler} : ExposureLimitModalProps) => {
    const [passwordShow, setPasswordShow] = useState(false);

    return (
        <Modal show={show} onHide={clickHandler} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <span>Edit Exposure Limit (username)</span>
                <Button variant="light btn-sm" onClick={clickHandler}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='fs-md'>
                <div className="bg-light p-1 rounded text-center">Current: <strong>0</strong></div>
                <div className="form-floating mt-3">
                    <Form.Control type='number' id="Credit" placeholder='Limit' />
                    <Form.Label htmlFor="Credit">New Exposure Limit</Form.Label>
                </div>
                <div className="form-floating mt-3">
                    <Form.Control
                        name="password"
                        type={passwordShow ? "text" : "password"}
                        placeholder="Password"
                    />
                    <Form.Label>Password</Form.Label>
                    <Button
                        variant="link"
                        className="position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
                        type="button"
                        id="password-addon"
                        onClick={() => setPasswordShow(!passwordShow)}
                    >
                        <i className="ri-eye-fill align-middle"></i>
                    </Button>
                </div>
            </Modal.Body>
            <Modal.Footer className='border-top'>
                <Button variant='subtle-primary'>Update</Button>
                <Button variant='btn-ghost' onClick={clickHandler}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ExposureLimitModal;