import TableContainer from "Common/TableContainer";
import moment from "moment";
import { useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { authServices } from "Utils/auth/services";

interface CasinoBetsTableProps {
  casinoBetsData: CasinoBetsData[];
}

const CasinoBetsTable = ({ casinoBetsData }: CasinoBetsTableProps) => {
  const [showList, setList] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [agentList, setAgentList] = useState<CasinoUserAgent[]>([]);

  const toggleList = (name?: string) => {
    setAgentList([]);
    setList(!showList);
    setSelectedUser(name || '')
  };

  const getUserAgent = async (userId: string) => {
    const { response } = await authServices.getShowAgent({
      user_id: userId,
    });
    if (response?.data) {
      setAgentList(response?.data?.agents);
    }
  };

  const defaultTable = useMemo(() => {
    if (!casinoBetsData || !Array.isArray(casinoBetsData)) {
      return [];
    }
    return casinoBetsData.map((items, index: number) => ({
      no: index + 1,
      date: items?.createdAt,
      domainName: items?.domainName || "",
      username: items?.userName,
      market: items?.marketName,
      match: items?.matchName,
      selection: items?.runnerName,
      isBack: items?.isBack,
      rate: items?.odds,
      stake: items?.stake,
      pnl: items?.pnl,
      roundId: items?.roundId,
      userId: items?.userId,
    }));
  }, [casinoBetsData]);

  const columns = useMemo(
    () => [
      {
        header: "No.",
        accessorKey: "no",
        enableColumnFilter: false,
      },

      {
        header: "Date",
        accessorKey: "date",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <p className="mb-0">
                {moment(cell?.row?.original?.date).format("DD-MM-YY")}
              </p>
              <p className="mb-0">
                {moment(cell?.row?.original?.date).format(" hh:mm A")}
              </p>
            </div>
          );
        },
      },
      {
        header: "Domain",
        accessorKey: "domain",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <span>{cell?.row?.original?.domainName}</span>;
        },
      },
      {
        header: "User Name",
        accessorKey: "username",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span
              className="cursor-pointer text-info"
              onClick={() => {
                toggleList(cell?.row?.original?.username);
                getUserAgent(cell?.row?.original?.userId);
              }}
            >
              {cell?.row?.original?.username}
            </span>
          );
        },
      },
      {
        header: "Match | Market",
        accessorKey: "matchMarket",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <p className="mb-0 fs-lg">{cell?.row?.original?.match}</p>
              <p className="mb-0 text-muted">{cell?.row?.original?.market}</p>
            </div>
          );
        },
      },
      {
        header: "Selection | Type",
        accessorKey: "selectionType",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <p className="mb-0 fs-lg">{cell?.row?.original?.selection}</p>
              <p className="mb-0 btn-back d-inline px-2 rounded-pill">
                {cell?.row?.original?.isBack ? "Back" : "Lay"}
              </p>
            </div>
          );
        },
      },
      {
        header: "Rate",
        accessorKey: "rate",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <span>{cell?.row?.original?.rate}</span>;
        },
      },
      {
        header: "Stake",
        accessorKey: "stake",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <span>{cell?.row?.original?.stake}</span>;
        },
      },
      {
        header: "P | L",
        accessorKey: "profitLoss",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span className="cursor-pointer text-success">
              {cell?.row?.original?.pnl}
            </span>
          );
        },
      },
      {
        header: "RoundID",
        accessorKey: "roundID",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <span>{cell?.row?.original?.roundId}</span>;
        },
      },
    ],
    []
  );

  return (
    <>
      <TableContainer
        columns={columns || []}
        data={defaultTable || []}
        isBordered={false}
        customPageSize={10}
        isPagination
        tableClass="table-centered align-middle table-nowrap table-striped mb-3"
        theadClass="text-muted table-light"
        SearchPlaceholder="Search Products..."
      />
      <Modal show={showList} onHide={()=>toggleList()} className="zoomIn" scrollable>
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <div className="d-flex align-items-center">
            Parent List of{" "}
            <span className="text-muted fw-normal mx-2">({selectedUser})</span>
          </div>
          <Button variant="light btn-sm" onClick={()=>toggleList()}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        {agentList?.map((item) => {
          return <Modal.Body>{item.name}({item.user_name})</Modal.Body>
        })}
      </Modal>
    </>
  );
};

export default CasinoBetsTable;
