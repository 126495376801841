import React, { useEffect, useState } from "react";
import { authServices } from "Utils/auth/services";



interface Series{
is_active: boolean;
name: string;
series_id: string
sport_id: string
}

const Navdata = () => {
    //state data
    const [isCricket, setIsCricket] = useState(false);
    const [isTennis, setIsTennis] = useState(false);
    const [isSoccer, setIsSoccer] = useState(false);
    const [isDownline, setIsDownline] = useState(false);
    const [isSuper, setIsSuper] = useState(false);
    const [isReports, setIsReports] = useState(false);
    const [sportSeriesData, setSportSeriesData] = useState<Series[]>([]);

    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e: any) {
        if (e && e.target && e.target.getAttribute("sub-items")) {
            const ul: any = document.getElementById("two-column-menu");
            const iconItems: any = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id: any = item.getAttribute("sub-items");
                var menusId = document.getElementById(id);
                if (menusId) {
                    (menusId.parentElement as HTMLElement).classList.remove("show");
                }
            });
            e.target.classList.add("active");
        }
    }

    const fetchSeries = async (sport_id: any) => {
        if (!sport_id) return;
        try {
            const { response } = await authServices.getSeriesData({sport_id});
            setSportSeriesData(response?.data);
        } catch (error) {
            console.error("Error fetching series data:", error);
        }
    };

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');

        if (iscurrentState !== 'Cricket') {
            setIsCricket(false);
        }
        if (iscurrentState !== 'Tennis') {
            setIsTennis(false);
        }
        if (iscurrentState !== 'Soccer') {
            setIsSoccer(false);
        }
        if (iscurrentState !== 'Downline') {
            setIsDownline(false);
        }
        if (iscurrentState !== 'Super') {
            setIsSuper(false);
        }
        if (iscurrentState !== 'Reports') {
            setIsReports(false);
        }
    }, [
        iscurrentState,
        isCricket,
        isTennis,
        isSoccer,
        isDownline,
        isSuper,
        isReports,
    ]);

    const menuItems:any = [
        {
            id: "dashboard",
            label: "Dashboards",
            icon: "ph-gauge",
            link: "/",
        },
        {
            id: "Cricket",
            label: "Cricket",
            icon: 'bx bx-cricket-ball',
            link: "#",
            sportId: "4",
            click: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                e.preventDefault();
                setIsCricket(!isCricket);
                setIscurrentState('Cricket');
                updateIconSidebar(e);
                fetchSeries("4");
            },
            stateVariables: isCricket,
            subItems: sportSeriesData
        },
        {
            id: "Tennis",
            label: "Tennis",
            icon: 'bx bxs-tennis-ball',
            link: "#",
            sportId: "2",
            click: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                e.preventDefault();
                setIsTennis(!isTennis);
                setIscurrentState('Tennis');
                updateIconSidebar(e);
                fetchSeries("2");
            },
            stateVariables: isTennis,
            subItems: sportSeriesData
        },
        {
            id: "Soccer",
            label: "Soccer",
            icon: 'bx bx-football',
            link: "#",
            sportId: "1",
            click: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                e.preventDefault();
                setIsSoccer(!isSoccer);
                setIscurrentState('Soccer');
                updateIconSidebar(e);
                fetchSeries("1");
            },
            stateVariables: isSoccer,
            subItems: sportSeriesData
        },
        {
            id: "Downline",
            label: "Downline List",
            icon: 'ri-bubble-chart-line',
            link: "#",
            click: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                e.preventDefault();
                setIsDownline(!isDownline);
                setIscurrentState('Downline');
                updateIconSidebar(e);
            },
            stateVariables: isDownline,
            subItems: [
                { id: "Downline1", label: "Add Agent", link: "/add-agent", parentId: "Downline" },
                { id: "Downline2", label: "Add Uk Agent", link: "/add-uk-agent", parentId: "Downline" },
                { id: "Downline3", label: "Add User", link: "/add-user", parentId: "Downline" },
                { id: "Downline4", label: "User List", link: "/user-list", parentId: "Downline" },
                { id: "Downline4", label: "Agent User List", link: "/agent-user-list", parentId: "Downline" },
                { id: "Downline5", label: "B2C Manager", link: "/b2c-manager", parentId: "Downline" },
                { id: "Downline6", label: "Online User", link: "/online-user", parentId: "Downline" },
                { id: "Downline7", label: "Closed User", link: "/closed-user", parentId: "Downline" },
            ]
        },
        {
            id: "ImportMarket",
            label: "Import Market",
            icon: "ri-building-2-line",
            link: "/import-market",
        },
        {
            id: "market-analisis",
            label: "Market Analysis",
            icon: "ri-building-2-line",
            link: "/market-analysis",
        },
        {
            id: "Super",
            label: "Super",
            icon: 'ri-vip-crown-2-line',
            link: "#",
            click: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                e.preventDefault();
                setIsSuper(!isSuper);
                setIscurrentState('Super');
                updateIconSidebar(e);
            },
            stateVariables: isSuper,
            subItems: [
                { id: "Super1", label: "Domain Setting", link: "#", parentId: "Super" },
                { id: "Super2", label: "Setting", link: "#", parentId: "Super" },
                { id: "Super3", label: "Match result", link: "#", parentId: "Super" },
                { id: "Super4", label: "Fancy result", link: "#", parentId: "Super" },
                { id: "Super5", label: "Casino result", link: "#", parentId: "Super" },
                { id: "Super6", label: "Universe Casino result", link: "#", parentId: "Super" },
                { id: "Super7", label: "Roll Back Match result", link: "#", parentId: "Super" },
                { id: "Super8", label: "Roll Back Fancy result", link: "#", parentId: "Super" },
                { id: "Super9", label: "Update User Data", link: "#", parentId: "Super" },
                { id: "Super10", label: "Bet Process List", link: "#", parentId: "Super" },
                { id: "Super11", label: "Score TV", link: "#", parentId: "Super" },
                { id: "Super12", label: "Banner Setting", link: "#", parentId: "Super" },
                { id: "Super13", label: "User Mobile Data", link: "#", parentId: "Super" },
                { id: "Super14", label: "Fraud Bets", link: "#", parentId: "Super" },
                { id: "Super15", label: "Delete Bets", link: "#", parentId: "Super" },
                { id: "Super16", label: "Void Bets", link: "#", parentId: "Super" },
                { id: "Super17", label: "WCO Log", link: "#", parentId: "Super" },
                { id: "Super18", label: "PL Statement", link: "#", parentId: "Super" },
            ]
        },
        {
            id: "Reports",
            label: "Reports",
            icon: 'ri-file-list-3-line',
            link: "#",
            click: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                e.preventDefault();
                setIsReports(!isReports);
                setIscurrentState('Reports');
                updateIconSidebar(e);
            },
            stateVariables: isReports,
            subItems: [
                { id: "Reports1", label: "My statement", link: "#", parentId: "Reports" },
                { id: "Reports2", label: "Chip Summary", link: "#", parentId: "Reports" },
                { id: "Reports3", label: "Casino Bets", link: "#", parentId: "Reports" },
                { id: "Reports4", label: "Market P|L", link: "#", parentId: "Reports" },
                { id: "Reports5", label: "Sport P|L", link: "#", parentId: "Reports" },
                { id: "Reports6", label: "QTech P|L", link: "#", parentId: "Reports" },
                { id: "Reports7", label: "Fancy P|L", link: "#", parentId: "Reports" },
                { id: "Reports8", label: "Fancy Stake (M)", link: "#", parentId: "Reports" },
                { id: "Reports9", label: "Fancy Stake (U)", link: "#", parentId: "Reports" },
                { id: "Reports10", label: "Open Bets", link: "#", parentId: "Reports" },
                { id: "Reports11", label: "Settled Bets", link: "#", parentId: "Reports" },
                { id: "Reports12", label: "News Update", link: "#", parentId: "Reports" },
            ]
        },
        {
            id: "b2c",
            label: "B2C",
            icon: "ri-briefcase-2-fill",
            link: "/",
        },
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};

export default Navdata;