import React, { useEffect, useState } from 'react';
import { Card, Col, Button, Form } from 'react-bootstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { Link, useNavigate } from "react-router-dom";
import withRouter from 'Common/withRouter';
import * as Yup from "yup";
import { useFormik } from "formik";
import snackBarUtil from 'Utils/snackBarUtil';
import { authServices } from 'Utils/auth/services';

const Signin = () => {
    const [passwordShow, setPasswordShow] = useState<boolean>(false);
    const [rememberMe, setRememberMe] = useState<boolean>(false);
    const [logo, setLogo] = useState<string>("");
    const nav = useNavigate();

    const getLogoData = async()=>{
        const {response} = await authServices.getLogo({
            key:"cGxhdGludW1leGNoLm5ldA==",
        });
        console.log(response?.data?.description, "responseresponseresponse");
        setLogo(response?.data?.description);
    }

    useEffect(()=>{
        getLogoData();
    }, [])
   
    const login = async (data: URLSearchParams) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASEURL}/user/adminLogin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json, text/plain, */*',
                    'Authorization': 'Basic YXBwbGljYXRpb246c2VjcmV0',
                },
                body: data
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json(); 
            if (!result.status) {
                snackBarUtil.error(result.msg);
                return null;
            }
            return result;
        } catch (error) {
            console.error("Login error:", error);
            throw error;
        }
    };

   
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            user_name: localStorage.getItem("username") || '', 
            password: '',
        },
        validationSchema: Yup.object({
            user_name: Yup.string().required("Please Enter User ID"),
            password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: async (values) => {
            const params = new URLSearchParams();
            params.append("user_name", values.user_name);
            params.append("password", values.password);
            params.append("grant_type", "password");

            try {
                const data = await login(params);
                
                if (data) {
                    localStorage.setItem("token", data.token.accessToken);
                    localStorage.setItem("adminDetails", JSON.stringify(data.data));
                    localStorage.setItem("adminRefreshToken", data.token.refreshToken);
                    localStorage.setItem("operatorId", data.operatorId);
                    localStorage.setItem("userId", data.data.user_id);
                    snackBarUtil.success(data.msg);
                    
                    // Save username if rememberMe is checked
                    if (rememberMe) {
                        localStorage.setItem("username", values.user_name);
                    } else {
                        localStorage.removeItem("username");
                    }
                    
                    
                    nav(data.data.is_change_password ? '/' : '/change-password');
                }
                console.log("Login successful:", data);
            } catch (error) {
                console.error("Error during login:", error);
            }
        }
    });

   
    const handleRememberMe = () => {
        setRememberMe(!rememberMe);
    };

    useEffect(() => {
       
        const savedRememberMe = localStorage.getItem("remember") === "true";
        setRememberMe(savedRememberMe);
    }, []);

    useEffect(() => {
        localStorage.setItem("remember", JSON.stringify(rememberMe));
    }, [rememberMe]);

    return (
        <ParticlesAuth>
            <React.Fragment>
                <Col xxl="6" className="mx-auto">
                    <Card className="mb-0 border-0 shadow-none mb-0">
                        <Card.Body className="p-sm-5 m-lg-4">
                            <div className='text-center d-xxl-none'>
                                <img src={`${process.env.REACT_APP_API_BASEURL}/${logo}`} alt="Logo" style={{maxWidth: '260px'}} />
                            </div>
                            <div className="text-center mt-5">
                                <h5 className="fs-3xl">Welcome Back</h5>
                                <p className="text-muted">Sign in to continue to Backlay.</p>
                            </div>
                            <div className="p-2 mt-5">
                                <Form onSubmit={formik.handleSubmit}>
                                    <Form.Group className="mb-3" controlId="formUsername">
                                        <Form.Label>Username <span className="text-danger">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="user_name"
                                            className="form-control password-input"
                                            placeholder="Enter username"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.user_name}
                                            isInvalid={formik.touched.user_name && !!formik.errors.user_name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.touched.user_name && formik.errors.user_name}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formPassword">
                                        <Form.Label>Password <span className="text-danger">*</span></Form.Label>
                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                            <Form.Control
                                                type={passwordShow ? "text" : "password"}
                                                className="form-control pe-5 password-input"
                                                placeholder="Enter password"
                                                name="password"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.password}
                                                isInvalid={formik.touched.password && !!formik.errors.password}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.touched.password && formik.errors.password}
                                            </Form.Control.Feedback>
                                            <Button
                                                variant="link"
                                                className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                                type="button"
                                                onClick={() => setPasswordShow(!passwordShow)}>
                                                <i className="ri-eye-fill align-middle"></i>
                                            </Button>
                                        </div>
                                    </Form.Group>

                                    <Form.Group controlId="formRememberMe">
                                        <Form.Check 
                                            checked={rememberMe} 
                                            type="checkbox" 
                                            onChange={handleRememberMe} 
                                            label="Remember me" 
                                            id="auth-remember-check" 
                                        />
                                    </Form.Group>

                                    <div className="mt-4">
                                        <Button className="btn btn-primary w-100" type="submit">Sign In</Button>
                                    </div>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </React.Fragment>
        </ParticlesAuth>
    );
};

export default withRouter(Signin);
