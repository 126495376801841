import BreadCrumb from "Common/BreadCrumb";
import UserListTable from "Common/Tables/UserListTable";
import UserListTableAgent from "Common/Tables/UserListTableAgent";
import { FC, useEffect, useState } from "react";
import { Container, Card, Button, Row, Col, Form, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { authServices } from "Utils/auth/services";

interface Props{
    agent: boolean
}

const UserList:FC<Props> = ({agent}) => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState<useListData>();
    const [userDataAgent, setUserDataAgent] = useState<userListAgentRes[]>([]);
    const [userId, setUserId] = useState<string>("");
    const [domainList, setDomainList] = useState<DomainListPayload[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>("");  
    const [domainId, setDomainId] = useState<string>("");        
    const [levelId, setLevelId] = useState<number | string>(""); 

    const user_Id = localStorage.getItem("userId");

    useEffect(() => {
        if (user_Id) setUserId(user_Id);
    }, [user_Id]);

    const getUserList = async (filters: any = {}) => {
        const { response } = await authServices.getUserListData(userId, {
            limit: 50,
            page: 1,
            ...filters, 
        });
        setUserData(response?.data);
    };
    const getUserListAgent = async (filters: any = {}) => {
        const { response } = await authServices.getAgentUserList({
            limit: 50,
            page: 1,
            user_id:userId
        });
        setUserDataAgent(response?.data?.data);

    };

    const getAllWebsite = async () => {
        const { response } = await authServices.getAllWebsite();
        setDomainList(response?.data);
    };

    useEffect(() => {
        if (userId) {
            if(agent){
                getUserListAgent();
            }else{
                getUserList()
            }
        }
    }, [userId, agent]);

    useEffect(() => {
        getAllWebsite();
    }, []);

    
    const handleOnchange = (e: any) => {
        const { value } = e.target;
        setSearchQuery(value);
    };

    const handleDomain = (e: any) => {
        const { value } = e.target;
        setDomainId(value); 
    };

    const handleLevel = (e: any) => {
        const { value } = e.target;
        setLevelId(value); 
    };

    const handleSubmit = () => {
        getUserList({
            searchQuery,
            domainId,
            levelId,
        });
    };

    const handleReset = () => {
        setSearchQuery(""); 
        setDomainId("");   
        setLevelId(""); 
        getUserList();
    };

    console.log(userDataAgent, "userDataAgentuserDataAgent")

    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="User List" pageTitle="Dashboard" back />
                <Card>
                    <Card.Body>
                        <Row className="align-items-center">
                            <Col lg={9}>
                                <Row>
                                    <Col lg={3}>
                                        <Form.Control
                                            type="text"
                                            name="username"
                                            placeholder="Search Username"
                                            value={searchQuery}
                                            onChange={handleOnchange}
                                        />
                                    </Col>
                                    <Col lg={3} className="mt-3 mt-lg-0">
                                        <Form.Select name="level" value={levelId} onChange={handleLevel}>
                                            <option value="">Level</option>
                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((items) => (
                                                <option value={items} key={items}>{items}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                    <Col lg={3} className="mt-3 mt-lg-0">
                                        <Form.Select name="domain" value={domainId} onChange={handleDomain}>
                                            <option value="">Search Domain</option>
                                            {domainList?.map((items) => (
                                                <option value={items?._id} key={items?._id}>{items?.host_name}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                    <Col lg={3} className="mt-3 mt-lg-0">
                                        <Button variant="subtle-primary" onClick={handleSubmit}>Submit</Button>
                                        <Button variant="subtle-dark" className="ms-3" onClick={handleReset}>Reset</Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={3} className="text-end mt-3 mt-lg-0 d-flex justify-content-end">
                                <Button variant="outline-primary" className="me-2" onClick={() => navigate('/add-user')}>
                                    <i className="fs-lg ri-user-line align-middle me-1"></i> User
                                </Button>
                                <Button variant="primary" className="me-2" onClick={() => navigate('/add-agent')}>
                                    <i className="fs-lg ri-group-line align-middle me-1"></i> Agent
                                </Button>
                                <Dropdown drop="start">
                                    <Dropdown.Toggle variant="subtle-dark" className="e-caret-hide">
                                        <i className="fs-lg ri-download-2-line"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item className="border-bottom py-2">
                                            <i className="fs-lg me-2 bi bi-file-earmark-pdf-fill align-middle"></i> PDF
                                        </Dropdown.Item>
                                        <Dropdown.Item className="border-none py-2">
                                            <i className="fs-lg me-2 bi bi-file-earmark-excel-fill align-middle"></i> XLS
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        {
                            agent?<UserListTableAgent setUserId={setUserId} getUserList={getUserList} userData={userDataAgent}/>: <UserListTable setUserId={setUserId} getUserList={getUserList} userData={userData} />
                        }
                        
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
};

export default UserList;
