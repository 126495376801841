import ChipSummaryTable from "Common/Tables/ChipSummaryTable";
import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { authServices } from "Utils/auth/services";

const ChipSummary = () => {
  const [chipSummaryData, setChipSummaryData] =
    useState<ChipSummaryData | null>(null);

  const getChipSummaryList = async (filters: any = {}) => {
    const { response } = await authServices.getChipSummaryList(filters);
    if (response?.data) {
      setChipSummaryData(response?.data);
    }
  };

  useEffect(() => {
    getChipSummaryList({});
  }, []);

  return (
    <>
      <Row>
        <Col lg={6}>
          <Card className="ribbon-box border shadow-none mb-lg-0">
            <Card.Header>
              <div className="ribbon ribbon-success ribbon-shape">
                Plus Account
              </div>
              <h5 className="fs-md text-end">{chipSummaryData?.user}</h5>
            </Card.Header>
            <Card.Body>
              <ChipSummaryTable
                type="success"
                chipSummaryData={chipSummaryData}
                getChipSummaryList={getChipSummaryList}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
          <Card className="ribbon-box border shadow-none mb-lg-0">
            <Card.Header>
              <div className="ribbon ribbon-danger ribbon-shape">
                Minus Account
              </div>
              <h5 className="fs-md text-end">{chipSummaryData?.user}</h5>
            </Card.Header>
            <Card.Body>
              <ChipSummaryTable
                type="danger"
                chipSummaryData={chipSummaryData}
                getChipSummaryList={getChipSummaryList}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ChipSummary;
