import StatementTable from "Common/Tables/StatementTable";
import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import { authServices } from "Utils/auth/services";
import moment from "moment";
import { getLastTimeOfDay, getStartTimeOfDay } from "Utils/datefilter";

interface StatementProps {
  filter?: boolean;
}

const Statement = ({ filter }: StatementProps) => {
  const [statementData, setStatementData] = useState<StatementListData[]>([]);
  const [startDate, setStartDate] = useState<string>(
    getStartTimeOfDay(new Date())
  );
  const [endDate, setEndDate] = useState<string>(getLastTimeOfDay(new Date()));
  const [statementType, setStatementType] = useState<string>("");

  const getStatementList = async (filters: any = {}) => {
    const { response } = await authServices.getStatementList({
      limit: 50,
      page: 1,
      ...filters,
    });
    if (response?.data && response?.data?.length > 0) {
      setStatementData(response?.data[0]?.data);
    }
  };

  useEffect(() => {
    getStatementList();
  }, []);

  const handlefilterSubmit = () => {
    let filterData: {
      from_date: string;
      to_date: string;
      statement_type?: string;
    } = {
      from_date: startDate,
      to_date: endDate,
    };
    if (statementType != "") filterData.statement_type = statementType;
    getStatementList(filterData);
  };

  return (
    <>
      {filter ? (
        <Card>
          <Card.Body>
            <Form>
              <div className="d-flex align-items-center flex-wrap mb-3">
                <div className="form-check me-3">
                  <Form.Check
                    type="radio"
                    name="statement"
                    id="Statement"
                    defaultChecked={statementType === ""}
                    onChange={() => setStatementType("")}
                  />
                  <Form.Label className="form-check-label" htmlFor="Statement">
                    Statement
                  </Form.Label>
                </div>
                <div className="form-check me-3">
                  <Form.Check
                    type="radio"
                    name="statement"
                    id="FreeChips"
                    defaultChecked={statementType === "1"}
                    onChange={() =>
                      setStatementType(statementType === "1" ? "" : "1")
                    }
                  />
                  <Form.Label className="form-check-label" htmlFor="FreeChips">
                    Free Chips
                  </Form.Label>
                </div>
                <div className="form-check me-3">
                  <Form.Check
                    type="radio"
                    name="statement"
                    id="ProfitLoss"
                    defaultChecked={statementType === "2"}
                    onChange={() =>
                      setStatementType(statementType === "2" ? "" : "2")
                    }
                  />
                  <Form.Label className="form-check-label" htmlFor="ProfitLoss">
                    Profit Loss
                  </Form.Label>
                </div>
                <div className="form-check me-3">
                  <Form.Check
                    type="radio"
                    name="statement"
                    id="Commission"
                    defaultChecked={statementType === "3"}
                    onChange={() =>
                      setStatementType(statementType === "3" ? "" : "3")
                    }
                  />
                  <Form.Label className="form-check-label" htmlFor="Commission">
                    Commission
                  </Form.Label>
                </div>
                <div className="form-check me-3">
                  <Form.Check
                    type="radio"
                    name="statement"
                    id="Settlement"
                    defaultChecked={statementType === "4"}
                    onChange={() =>
                      setStatementType(statementType === "4" ? "" : "4")
                    }
                  />
                  <Form.Label className="form-check-label" htmlFor="Settlement">
                    Settlement
                  </Form.Label>
                </div>
              </div>
              <Row className="gy-3 align-items-end">
                <Col lg={9}>
                  <Row className="gap-3 gap-sm-0">
                    <Col lg={4}>
                      <Form.Label>Start Date</Form.Label>
                      <Flatpickr
                        className="form-control"
                        pl
                        options={{
                          enableTime: false,
                          dateFormat: "Y-m-d",
                          defaultDate: [startDate],
                        }}
                        onChange={([date]: [Date]) => {
                          setStartDate(getStartTimeOfDay(new Date(date)));
                        }}
                      />
                    </Col>
                    <Col lg={4}>
                      <Form.Label>End Date</Form.Label>
                      <Flatpickr
                        className="form-control"
                        pl
                        options={{
                          enableTime: false,
                          dateFormat: "Y-m-d",
                          defaultDate: [endDate],
                        }}
                        onChange={([date]: [Date]) => {
                          setEndDate(getLastTimeOfDay(new Date(date)));
                        }}
                      />
                    </Col>
                    <Col lg={4}>
                      <Form.Label>Search By Username</Form.Label>
                      <Form.Control type="text" placeholder="Username" />
                    </Col>
                  </Row>
                </Col>
                <Col lg={3}>
                  <Button
                    className="me-2 btn-sm"
                    style={{ height: "38px" }}
                    onClick={() => handlefilterSubmit()}
                  >
                    <i className="fs-lg align-middle ri-filter-line"></i> Submit
                  </Button>
                  <Button
                    variant="outline-primary"
                    className="btn-sm"
                    style={{ height: "38px" }}
                  >
                    <i className="fs-lg align-middle ri-refresh-line"></i> Clear
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      ) : null}
      <Card>
        <Card.Body>
          <StatementTable statementData={statementData} />
        </Card.Body>
      </Card>
    </>
  );
};

export default Statement;
