import CasinoBetsTable from "Common/Tables/CasinoBetsTable";
import Flatpickr from "react-flatpickr";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { authServices } from "Utils/auth/services";
import { getLastTimeOfDay, getStartTimeOfDay } from "Utils/datefilter";

interface StatementProps {
  filter?: boolean;
}

const CasinoBets = ({ filter }: StatementProps) => {
  const [startDate, setStartDate] = useState<string>(
    getStartTimeOfDay(new Date())
  );
  const [endDate, setEndDate] = useState<string>(getLastTimeOfDay(new Date()));
  const [betType, setBetType] = useState<string>("open");
  const [casinoBetsData, setCasinoBetsData] = useState<CasinoBetsData[]>([]);

  const getCasinoBetsList = async (filters: any = {}) => {
    const { response } = await authServices.getCasinoBets({
      limit: 50,
      page: 1,
      ...filters,
    });
    if (response?.data && response?.data?.data) {
      setCasinoBetsData(response?.data?.data);
    }
  };

  useEffect(() => {
    getCasinoBetsList({
      bets_type: betType,
    });
  }, []);

  const handleChange = (e: any) => {
    setBetType(e.target.value);
  };

  const handlefilterSubmit = () => {
    getCasinoBetsList({
      from_date: startDate,
      to_date: endDate,
      bets_type: betType,
    });
  };

  return (
    <>
      {filter ? (
        <Card>
          <Card.Body>
            <Form>
              <Row className="gy-3 align-items-end">
                <Col lg={9}>
                  <Row className="gap-3 gap-sm-0">
                    <Col lg={3}>
                      <Form.Label>Start Date</Form.Label>
                      <Flatpickr
                        className="form-control"
                        pl
                        options={{
                          enableTime: false,
                          dateFormat: "Y-m-d",
                          defaultDate: [startDate],
                        }}
                        onChange={([date]: [Date]) => {
                          setStartDate(getStartTimeOfDay(new Date(date)));
                        }}
                      />
                    </Col>
                    <Col lg={3}>
                      <Form.Label>End Date</Form.Label>
                      <Flatpickr
                        className="form-control"
                        pl
                        options={{
                          enableTime: false,
                          dateFormat: "Y-m-d",
                          defaultDate: [endDate],
                        }}
                        onChange={([date]: [Date]) => {
                          setEndDate(getLastTimeOfDay(new Date(date)));
                        }}
                      />
                    </Col>
                    <Col lg={3}>
                      <Form.Label>Bet Type</Form.Label>
                      <Form.Select
                        id="floatingSelect"
                        aria-label="Floating label select example"
                        value={betType}
                        onChange={handleChange}
                      >
                        <option selected>Choose...</option>
                        <option value="open">Open</option>
                        <option value="settled">Settle</option>
                        <option value="cancelled">Close</option>
                      </Form.Select>
                    </Col>
                    <Col lg={3}>
                      <Form.Label>Search By Username</Form.Label>
                      <Form.Control type="text" placeholder="Username" />
                    </Col>
                  </Row>
                </Col>
                <Col lg={3}>
                  <Button
                    className="me-2 btn-sm"
                    style={{ height: "38px" }}
                    onClick={() => handlefilterSubmit()}
                  >
                    <i className="fs-lg align-middle ri-filter-line"></i> Submit
                  </Button>
                  <Button
                    variant="outline-primary"
                    className="btn-sm"
                    style={{ height: "38px" }}
                  >
                    <i className="fs-lg align-middle ri-refresh-line"></i> Clear
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      ) : null}
      <Card>
        <Card.Body>
          <CasinoBetsTable casinoBetsData={casinoBetsData} />
        </Card.Body>
      </Card>
    </>
  );
};

export default CasinoBets;
