import moment from "moment";
import { Match } from "pages/Dashboard";
import { act, FC, useEffect, useState } from "react";
import { Button, Card, Tab, Nav, ListGroup } from "react-bootstrap";
import { authServices } from "Utils/auth/services";



interface DataRes{
    sport_id: string
    series_id: string
    match_id: string
    name: string
    match_name: string
    is_manual: number
    match_date: string
    centralId: string
    is_created: number
    is_active: number
    is_visible: boolean
    inplay: boolean
    market_count: string
    enable_fancy: number
    match_tv_url: any
    match_scoreboard_url: any
    is_lock: boolean
    country_code: string
    from_db: number
}

interface Props{
  active:number;
  sportData: Match[] | undefined
}

const HrGrTable:FC<Props> = ({active, sportData}) => {
  const [tabsData, setTabsData] = useState<DataRes[]>([])
  const getHorseTabs = async()=>{
    const {response} = await authServices.getHorseRacing({
      series_id:active.toString()
    })

    setTabsData(response?.data)
  }

  useEffect(()=>{
    getHorseTabs();
  }, [active])



  return (
    <Card>
      <Card.Header>
        <h5>
          Horse Racing
          {/* <span className='text-muted'>(37)</span> */}
        </h5>
      </Card.Header>
      <Card.Body>
        <Tab.Container defaultActiveKey="All">
          <div className="overflow-auto bg-light mb-3">
            <Nav
              as="ul"
              variant="pills"
              className="nav-dark flex-nowrap text-center"
            >
              <Nav.Item as="li">
                <Nav.Link eventKey="All" style={{minWidth: '75px'}} className="fs-md">All</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link eventKey="USA" style={{minWidth: '75px'}} className="fs-md">USA</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link eventKey="AUS" style={{minWidth: '75px'}} className="fs-md">AUS</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link eventKey="IRE" style={{minWidth: '75px'}} className="fs-md">IRE</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link eventKey="FRA" style={{minWidth: '75px'}} className="fs-md">FRA</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link eventKey="RSA" style={{minWidth: '75px'}} className="fs-md">RSA</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link eventKey="GB" style={{minWidth: '75px'}} className="fs-md">GB</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <Tab.Content className="text-muted">
            <Tab.Pane eventKey="All">
              <ListGroup className="list-group-fill-success">
                {
                  sportData?.map((items)=>{
                    return(
                      <ListGroup.Item className="list-group-item-action cursor-pointer"><strong className="text-primary">{items?.match_name}</strong> <span className="text-muted ms-2">{moment(items?.match_date).format('DD-MM-YY hh:mm A')}</span> 
                     {items?.inplay && <span className="inplayBlink ms-2">INPLAY</span>}
                      </ListGroup.Item>
                    )
                  })
                }
                 
                  
              </ListGroup>
            </Tab.Pane>
            <Tab.Pane eventKey="USA">
              <ListGroup className="list-group-fill-success">
                  <ListGroup.Item className="d-flex align-items-sm-center flex-column flex-sm-row gap-2">
                    <div className="me-3 w-100" style={{maxWidth: '145px'}}><strong>Belmont At The Big A</strong></div>
                    <div className="d-flex gap-2 flex-wrap">
                      <Button style={{minWidth: '65px'}} variant="success" className="bg-gradient">12:10</Button>
                      <Button style={{minWidth: '65px'}} variant="light" className="bg-gradient">12:10</Button>
                      <Button style={{minWidth: '65px'}} variant="light" className="bg-gradient">12:10</Button>
                      <Button style={{minWidth: '65px'}} variant="light" className="bg-gradient">12:10</Button>
                      <Button style={{minWidth: '65px'}} variant="light" className="bg-gradient">12:10</Button>
                      <Button style={{minWidth: '65px'}} variant="light" className="bg-gradient">12:10</Button>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex align-items-sm-center flex-column flex-sm-row gap-2">
                    <div className="me-3 w-100" style={{maxWidth: '145px'}}><strong>Grafton </strong></div>
                    <div className="d-flex gap-2 flex-wrap">
                      <Button style={{minWidth: '65px'}} variant="success" className="bg-gradient">05:10</Button>
                      <Button style={{minWidth: '65px'}} variant="light" className="bg-gradient">12:10</Button>
                      <Button style={{minWidth: '65px'}} variant="light" className="bg-gradient">12:10</Button>
                      <Button style={{minWidth: '65px'}} variant="light" className="bg-gradient">12:10</Button>
                      <Button style={{minWidth: '65px'}} variant="light" className="bg-gradient">12:10</Button>
                      <Button style={{minWidth: '65px'}} variant="light" className="bg-gradient">12:10</Button>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex align-items-sm-center flex-column flex-sm-row gap-2">
                    <div className="me-3 w-100" style={{maxWidth: '145px'}}><strong>Newcastle  </strong></div>
                    <div className="d-flex gap-2 flex-wrap">
                      <Button style={{minWidth: '65px'}} variant="light" className="bg-gradient">12:10</Button>
                      <Button style={{minWidth: '65px'}} variant="light" className="bg-gradient">12:10</Button>
                      <Button style={{minWidth: '65px'}} variant="light" className="bg-gradient">12:10</Button>
                      <Button style={{minWidth: '65px'}} variant="light" className="bg-gradient">12:10</Button>
                      <Button style={{minWidth: '65px'}} variant="light" className="bg-gradient">12:10</Button>
                      <Button style={{minWidth: '65px'}} variant="light" className="bg-gradient">12:10</Button>
                    </div>
                  </ListGroup.Item>
              </ListGroup>
            </Tab.Pane>
            <Tab.Pane eventKey="AUS">AUS</Tab.Pane>
            <Tab.Pane eventKey="IRE">IRE</Tab.Pane>
            <Tab.Pane eventKey="FRA">FRA</Tab.Pane>
            <Tab.Pane eventKey="RSA">RSA</Tab.Pane>
            <Tab.Pane eventKey="GB">GB</Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Card.Body>
    </Card>
  );
};

export default HrGrTable;
