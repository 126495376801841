import { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { authServices } from 'Utils/auth/services';
import snackbarUtil from 'Utils/snackBarUtil';

interface EditStakeModalProps {
    show: boolean;
    clickHandler: any;
    userIds: string;
}

const EditStakeModal = ({ show, clickHandler, userIds }: EditStakeModalProps) => {
    const [stake, setStake] = useState<number | ''>(''); 
    const [tempStakesArray, setTempStakesArray] = useState<number[]>([]); 
    const [showDynamicList, setShowDynamicList] = useState<boolean>(false); 
    const [errorMessage, setErrorMessage] = useState<string>(''); 

    const handleStakeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newStakeCount = Number(e.target.value);
        setStake(newStakeCount);

        if (newStakeCount <= 0) {
            setTempStakesArray([]);
            setErrorMessage('Stake count must be greater than zero.');
        } else {
            setErrorMessage('');
            const newStakesArray = Array.from({ length: newStakeCount }, (_, index) => 100 + index * 100);
            setTempStakesArray(newStakesArray); 
        }
    };

    const handleDynamicInputChange = (index: number, newValue: number) => {
        if (newValue >= 0) {
            const updatedArray = [...tempStakesArray];
            updatedArray[index] = newValue; 
            setTempStakesArray(updatedArray); 
        }
    };

    const updateStack = async () => {
        const { response } = await authServices?.getUpdateStack({
            user_id: userIds,
            match_stack: tempStakesArray
        });

        if (response?.status) {
            snackbarUtil?.success(response?.msg);
            setShowDynamicList(false);
            clickHandler();
            setTempStakesArray([]);
            setStake('');
        }
    };

    const handleStackSubmit = () => {
        if (stake === '' || stake <= 0) { 
            setErrorMessage('Stake count must be greater than zero.'); 
            return;
        }
        setShowDynamicList(true);
        console.log(tempStakesArray);  
    };

    const handleStackUpdate = () => {
        if (tempStakesArray.length === 0 || tempStakesArray.some(v => v <= 0)) { 
            setErrorMessage('All stakes must be greater than zero.'); 
            return;
        }
        updateStack();
    };

    return (
        <Modal show={show} onHide={clickHandler} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <span>Change Stake Value</span>
                <Button variant="light btn-sm" onClick={clickHandler}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='fs-md'>
                <div className="form-floating mb-3">
                    <Form.Control
                        type='number'
                        id="Stake"
                        placeholder='Stake'
                        value={stake}
                        onChange={handleStakeChange}
                    />
                    <Form.Label htmlFor="Stake">Number of Stake Inputs</Form.Label>
                    {errorMessage && <div className="text-danger">{errorMessage}</div>}
                </div>
                <Button onClick={handleStackSubmit}>Submit</Button>
                {showDynamicList && tempStakesArray.length > 0 && tempStakesArray.map((value, index) => (
                    <div className="form-floating mb-3" key={index}>
                        <Form.Control
                            type="number"
                            value={value}
                            onChange={(e) => handleDynamicInputChange(index, Number(e.target.value))}
                        />
                        <Form.Label>Stake {index + 1}</Form.Label>
                    </div>
                ))}
            </Modal.Body>
            <Modal.Footer className='border-top'>
                <Button onClick={handleStackUpdate}>Submit</Button>
                <Button variant='light' onClick={clickHandler}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditStakeModal;
