import HistoryModal from "Common/Modals/HistoryModal";
import { useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { authServices } from "Utils/auth/services";

interface ChipSummaryTableProps {
  type: string;
  chipSummaryData: ChipSummaryData | null;
  getChipSummaryList: (value?: { user_id?: string }) => void;
}

const ChipSummaryTable = ({
  type,
  chipSummaryData,
  getChipSummaryList,
}: ChipSummaryTableProps) => {
  const [showList, setList] = useState(false);
  const [showHistory, setHistory] = useState(false);
  const [showSettlement, setSettlement] = useState(false);
  const [chipHistoryData, setChipHistoryData] = useState<ChipHistoryData[]>([]);
  const [chipsAmount, setChipsAmount] = useState<string>("0");
  const [remarks, setRemarks] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [agentList, setAgentList] = useState<CasinoUserAgent[]>([]);

  const toggleList = (name?: string) => {
    setAgentList([]);
    setList(!showList);
    setSelectedUser(name || "");
  };
  const toggleHistory = () => {
    setChipHistoryData([]);
    setHistory(!showHistory);
  };
  const toggleSettlement = (chip: string, userId?: string) => {
    setChipsAmount(chip);
    setUserId(userId || "");
    setSettlement(!showSettlement);
  };

  const getChipSummaryHistory = async (userId: string) => {
    const { response } = await authServices.getChipSummaryHistory({
      user_id: userId,
    });
    if (response?.data) {
      setChipHistoryData(response?.data);
    }
  };

  const handleChange = (e: any) => {
    setChipsAmount(e.target.value);
  };

  const handleRemark = (e: any) => {
    setRemarks(e.target.value);
  };

  const getChipSettlement = async () => {
    const { response } = await authServices.getChipSettlement({
      user_id: userId,
      type: type === "success" ? "1" : "2",
      amount: chipsAmount,
      comment: remarks,
    });
    if (response?.status) {
      getChipSummaryList({});
      setSettlement(false);
    }
  };

  const getUserAgent = async (userId: string) => {
    const { response } = await authServices.getShowAgent({
      user_id: userId,
    });
    if (response?.data) {
      setAgentList(response?.data?.agents);
    }
  };

  return (
    <>
      <div className="table-responsive">
        <Table className="table-centered align-middle table-nowrap table-striped mb-0">
          <thead className="table-light">
            <tr>
              <th>Name</th>
              <th>Account</th>
              <th>Chips</th>
              <th>
                {chipSummaryData?.parent_id && (
                  <Button
                    variant={`subtle-primary`}
                    className="btn-sm"
                    onClick={() => {
                      chipSummaryData?.parent_id &&
                        getChipSummaryList({
                          user_id: chipSummaryData?.parent_id,
                        });
                    }}
                  >
                    Back
                  </Button>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {type === "success" &&
              chipSummaryData?.plusData?.map((item) => {
                return (
                  <tr>
                    <td></td>
                    <td>{item?.description}</td>
                    <td colSpan={2} className={`text-${type}`}>
                      {item?.amount}
                    </td>
                  </tr>
                );
              })}
            {type === "success" &&
              chipSummaryData?.data_paid_to?.list?.map((item) => {
                return (
                  <tr>
                    <td
                      className="cursor-pointer"
                      onClick={() => {
                        toggleList(item?.user_name);
                        getUserAgent(item?.user_id);
                      }}
                    >
                      {item?.name}
                    </td>
                    <td
                      className="text-info cursor-pointer"
                      onClick={() => {
                        item?.user_type_id !== 1 &&
                          getChipSummaryList({ user_id: item?.user_id });
                      }}
                    >
                      <span className="badge bg-info-subtle text-info">A</span>{" "}
                      {item?.user_name} A/C
                    </td>
                    <td className={`text-${type}`}>
                      {item?.settlement_amount}
                    </td>
                    <td className="text-end">
                      <Button
                        variant={`subtle-${type}`}
                        className="btn-sm"
                        onClick={() => {
                          toggleHistory();
                          getChipSummaryHistory(item?.user_id);
                        }}
                      >
                        History
                      </Button>
                      <Button
                        variant="subtle-dark"
                        className="ms-2 btn-sm"
                        onClick={() =>
                          toggleSettlement(
                            item?.settlement_amount,
                            item?.user_id
                          )
                        }
                      >
                        Settlement
                      </Button>
                    </td>
                  </tr>
                );
              })}

            {type === "danger" &&
              chipSummaryData?.data_receiving_from?.list?.map((item) => {
                return (
                  <tr>
                    <td
                      className="cursor-pointer"
                      onClick={() => {
                        toggleList(item?.user_name);
                        getUserAgent(item?.user_id);
                      }}
                    >
                      {item?.name}
                    </td>
                    <td
                      className="text-info cursor-pointer"
                      onClick={() => {
                        item?.user_type_id !== 1 &&
                          getChipSummaryList({ user_id: item?.user_id });
                      }}
                    >
                      <span className="badge bg-info-subtle text-info">A</span>{" "}
                      {item?.user_name} A/C
                    </td>
                    <td className={`text-${type}`}>
                      {item?.settlement_amount}
                    </td>
                    <td className="text-end">
                      <Button
                        variant={`subtle-${type}`}
                        className="btn-sm"
                        onClick={() => {
                          toggleHistory();
                          getChipSummaryHistory(item?.user_id);
                        }}
                      >
                        History
                      </Button>
                      <Button
                        variant="subtle-dark"
                        className="ms-2 btn-sm"
                        onClick={() =>
                          toggleSettlement(
                            item?.settlement_amount,
                            item?.user_id
                          )
                        }
                      >
                        Settlement
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
          <tfoot className="table-light">
            <tr>
              <th colSpan={2}>Total</th>
              <th colSpan={2} className={`text-${type}`}>
                {type === "danger"
                  ? chipSummaryData?.totalMinus
                  : chipSummaryData?.totalPlus}
              </th>
            </tr>
          </tfoot>
        </Table>
      </div>

      <Modal
        show={showList}
        onHide={() => toggleList()}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <div className="d-flex align-items-center">
            Parent List of{" "}
            <span className="text-muted fw-normal mx-2">({selectedUser})</span>
          </div>
          <Button variant="light btn-sm" onClick={() => toggleList()}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        {agentList?.map((item) => {
          return (
            <Modal.Body>
              {item.name}({item.user_name})
            </Modal.Body>
          );
        })}
      </Modal>

      <HistoryModal
        show={showHistory}
        clickHandler={toggleHistory}
        chipHistoryData={chipHistoryData}
      />

      <Modal
        show={showSettlement}
        onHide={() => toggleSettlement("")}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <div className="d-flex align-items-center">Settlement</div>
          <Button variant="light btn-sm" onClick={() => toggleSettlement("")}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="form-floating mb-3">
            <Form.Control
              type="number"
              value={chipsAmount}
              onChange={handleChange}
            />
            <Form.Label>Chips </Form.Label>
          </div>
          <div className="form-floating">
            <Form.Control
              type="text"
              placeholder="Remark"
              value={remarks}
              onChange={handleRemark}
            />
            <Form.Label>Remark </Form.Label>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-top">
          <Button
            variant={`subtle-${type}`}
            onClick={() => getChipSettlement()}
          >
            {type === "success" ? "Debit" : "Credit"}
          </Button>
          <Button variant="subtle-dark" onClick={() => toggleSettlement("")}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChipSummaryTable;
