import { useState, useCallback, useMemo } from 'react';
import { Table, Form, InputGroup, Button } from 'react-bootstrap';
import { apiService } from 'Utils/apiService';
import { authServices } from 'Utils/auth/services';
import snackbarUtil from 'Utils/snackBarUtil';

const ShowFancyTable = ({ data, getFancyLiveData }: any) => {
  const [active, setActive] = useState<{ [key: string]: number }>({});
  const [fancyDetails, setFancyDetails] = useState<{ [key: string]: { category: string; chronology: string } }>({});

  const user_id = useMemo(() => localStorage.getItem("userId") || "", []);

  const updateFancyStatus = async (fancy_id: string, is_active: string) => {
    try {
      const { response } = await authServices.updateFancyStatus({
        fancy_id,
        user_id,
        is_active,
      });
      if (response?.status) {
        snackbarUtil.success(response.msg);
        getFancyLiveData();
        // getFancyOnline();
      } else {
        snackbarUtil.error(response.msg);
      }
    } catch (error) {
      console.error("Failed to update fancy status", error);
      snackbarUtil.error("Failed to update status");
    }
  };

  const updateFancyDetails = async (category: string, chronology: string, fancy_id: string) => {
    try {
      const { response } = await authServices.updateFancy({
        category,
        chronology,
        fancy_id,
      });

      if (response?.status) {
        snackbarUtil.success("Updated Successfully...");
        setFancyDetails(prev => ({
          ...prev,
          [fancy_id]: { category: '', chronology: '' },
        }));
      } else {
        snackbarUtil.error(response?.msg);
      }
    } catch (error) {
      console.error("Failed to update fancy", error);
      snackbarUtil.error("Update failed");
    }
  };

  const handleToggleStatus = useCallback(
    (fancyId: string, is_active: number) => {
      const newStatus = is_active === 1 ? 0 : 1;
      setActive(prevState => ({
        ...prevState,
        [fancyId]: newStatus,
      }));
      updateFancyStatus(fancyId, newStatus.toString());
    },
    [updateFancyStatus]
  );

  const handleFancyUpdate = useCallback(
    (fancy_id: string) => {
      const { category, chronology } = fancyDetails[fancy_id] || { category: '', chronology: '' };
      updateFancyDetails(category, chronology, fancy_id);
    },
    [fancyDetails]
  );

  const handleCategoryChange = useCallback((fancy_id: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setFancyDetails(prev => ({
      ...prev,
      [fancy_id]: {
        ...prev[fancy_id],
        category: e.target.value,
      },
    }));
  }, []);

  const handleChronologyChange = useCallback((fancy_id: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setFancyDetails(prev => ({
      ...prev,
      [fancy_id]: {
        ...prev[fancy_id],
        chronology: e.target.value,
      },
    }));
  }, []);

  return (
    <div className="table-responsive">
      <Table className="align-middle table-nowrap mb-0">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Fancy Name</th>
            <th scope="col">Action</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((fancy: any, index: number) => {
            const isActive = active[fancy.fancy_id] ?? fancy.is_active;
            const { category = '', chronology = '' } = fancyDetails[fancy.fancy_id] || {};

            return (
              <tr key={fancy.fancy_id}>
                <td>{index + 1}</td>
                <td className="w-50">{fancy.RunnerName}</td>
                <td>
                  <Form.Check
                    type="checkbox"
                    id={`action-${fancy.fancy_id}`}
                    checked={isActive === 1}
                    onChange={() => handleToggleStatus(fancy.fancy_id, isActive)}
                  />
                </td>
                <td>
                  <InputGroup>
                    <Form.Control
                      onChange={handleCategoryChange(fancy.fancy_id)}
                      value={category}
                      type="text"
                      placeholder="Type"
                    />
                    <Form.Control
                      onChange={handleChronologyChange(fancy.fancy_id)}
                      value={chronology}
                      type="text"
                      placeholder="Order"
                    />
                    <Button
                      variant="subtle-success"
                      onClick={() => handleFancyUpdate(fancy.fancy_id)}
                    >
                      U-C
                    </Button>
                  </InputGroup>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default ShowFancyTable;
