import { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

interface DomainSettingModalProps {
	show: any;
	clickHandler: any;
}

const DomainSettingModal = ({show, clickHandler} : DomainSettingModalProps) => {
    const [showPassword, setPassword] = useState(false);

    return (
        <Modal size='lg' show={show} onHide={clickHandler} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <div>Update Website Setting</div>
                <Button variant="light btn-sm" onClick={clickHandler}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='fs-md'>
                <Row className="align-items-center g-3">
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>Website Name</Form.Label>
                            <Form.Control type="text" />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>Site Title Data</Form.Label>
                            <Form.Control type="text" />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>Logo</Form.Label>
                            <Form.Control type="file" />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>Bg Image</Form.Label>
                            <Form.Control type="file" />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <div className="form-icon right">
                                <Form.Control
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Password"
                                />
                                <Button
                                    variant="link"
                                    className="btn-sm position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
                                    type="button"
                                    id="password-addon"
                                    onClick={() => setPassword(!showPassword)}
                                >
                                    <i className="ri-eye-fill align-middle"></i>
                                </Button>
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className='border-top'>
                <Button>Update</Button>
                <Button variant='light' onClick={clickHandler}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DomainSettingModal;