import ToggleSwitch from 'Common/ToggleSwitch';
import { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

interface PopupImageModalProps {
	show: any;
	clickHandler: any;
}

const PopupImageModal = ({show, clickHandler} : PopupImageModalProps) => {
    const[showToggle, setToggle] = useState(false)

    const toggleSwitch = () => {
        setToggle(!showToggle)
    }
    return (
        <Modal size='lg' show={show} onHide={clickHandler} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <div>Update Popup Image</div>
                <Button variant="light btn-sm" onClick={clickHandler}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='fs-md'>
                <Row className="align-items-center g-3">
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>Website Name</Form.Label>
                            <Form.Control type="text" />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>Site Title Data</Form.Label>
                            <Form.Control type="text" />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>Image</Form.Label>
                            <Form.Control type="file" />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className='d-flex align-items-center pt-3'>
                            <Form.Label className='mb-0 me-3'>Status</Form.Label>
                            <ToggleSwitch On='On' Off='Off' onChange={toggleSwitch} checked={showToggle ? true : false} />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className='border-top'>
                <Button>Update</Button>
                <Button variant='light' onClick={clickHandler}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PopupImageModal;