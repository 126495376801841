import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

interface UploadLogoModalProps {
	show: any;
	clickHandler: any;
}

const UploadLogoModal = ({show, clickHandler} : UploadLogoModalProps) => {

    return (
        <Modal show={show} onHide={clickHandler} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <div>Upload Logo</div>
                <Button variant="light btn-sm" onClick={clickHandler}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='fs-md'>
                <Row className="align-items-center g-3">
                    <Col lg={12}>
                        <Form.Group>
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" placeholder='Enter Title' />
                        </Form.Group>
                    </Col>
                    <Col lg={12}>
                        <Form.Group>
                            <Form.Label>Slug</Form.Label>
                            <Form.Control type="text" placeholder='like as user.domain.com' />
                        </Form.Group>
                    </Col>
                    <Col lg={12}>
                        <Form.Group>
                            <Form.Label>Logo</Form.Label>
                            <Form.Control type="file" />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className='border-top'>
                <Button>Update</Button>
                <Button variant='light' onClick={clickHandler}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UploadLogoModal;