import { useEffect, useState } from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { authServices } from 'Utils/auth/services';
import snackbarUtil from 'Utils/snackBarUtil';

interface DepositModalProps {
    show: any;
    clickHandler: any;
    userIds: string;
    getUserList: () => Promise<void>;
}

interface DepositData {
    balance: number;
    parent_id: {
        _id: string;
        user_name: string;
        balance: number;
    };
    name: string;
    credit_reference: number;
}

const DepositModal = ({ show, clickHandler, userIds, getUserList }: DepositModalProps) => {
    const [passwordShow, setPasswordShow] = useState(false);
    const [depositData, setDepositData] = useState<DepositData[]>([]);
    const [formData, setFormData] = useState({
        amount: '',
        remark: '',
        password: ''
    });
    const [formErrors, setFormErrors] = useState({
        amount: '',
        remark: '',
        password: ''
    });

    // Function to get deposit user details
    const getDepositUserDetails = async (id: string) => {
        const { response } = await authServices.getUserDetailsParent(id);
        setDepositData(response?.data || []);
    };

    useEffect(() => {
        if (userIds) getDepositUserDetails(userIds);
    }, [userIds]);

   
    useEffect(() => {
        if (!show) {
            
            setFormData({
                amount: '',
                remark: '',
                password: ''
            });
            setFormErrors({
                amount: '',
                remark: '',
                password: ''
            });
            setDepositData([]);
        }
    }, [show]);

    // Handle form input changes
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const validateForm = () => {
        let isValid = true;
        const errors: any = {};

        if (!formData.amount || isNaN(Number(formData.amount)) || Number(formData.amount) <= 0) {
            errors.amount = 'Please enter a valid amount';
            isValid = false;
        }

        if (!formData.remark.trim()) {
            errors.remark = 'Remark is required';
            isValid = false;
        }

        if (!formData.password) {
            errors.password = 'Password is required';
            isValid = false;
        }

        setFormErrors(errors);
        return isValid;
    };

    const getChipsOut = async () => {
        const { response } = await authServices.getChipsOut({
            ...formData,
            amount: Number(formData?.amount),
            crdr: 1,
            parent_id: depositData[0]?.parent_id?._id || "",
            user_id: userIds
        });

        if (response?.status) {
            snackbarUtil.success(response?.msg);
            getUserList();
            clickHandler(); // Close the modal
            setFormData({
                amount: '',
                remark: '',
                password: ''
            });
        } else {
            snackbarUtil.error(response?.msg);
        }
    };

    // Handle form submission
    const handleSubmit = () => {
        if (validateForm()) {
            getChipsOut();
        }
    };

    return (
        <Modal show={show} onHide={clickHandler} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <span>Deposit to (Agent)</span>
                <Button variant="light btn-sm" onClick={clickHandler}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className="fs-md">
                <div className="form-floating">
                    <Form.Control
                        name="amount"
                        type="number"
                        placeholder="Deposit Chips"
                        value={formData.amount}
                        onChange={handleInputChange}
                        isInvalid={!!formErrors.amount}
                    />
                    <Form.Label>Deposit Chips</Form.Label>
                    {formErrors.amount && <Form.Control.Feedback type="invalid">{formErrors.amount}</Form.Control.Feedback>}
                </div>

                <Row>
                    <Col sm={6}>
                        <div className="mt-3 bg-light p-1 rounded text-center">
                            {depositData[0]?.parent_id?.user_name}: <strong>{depositData[0]?.parent_id?.balance}</strong>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className="mt-3 bg-light p-1 rounded text-center">
                            {depositData[0]?.name}: <strong>{depositData[0]?.balance + +formData?.amount}</strong>
                        </div>
                    </Col>
                </Row>

                <div className="form-floating mt-3">
                    <Form.Control
                        name="remark"
                        as="textarea"
                        rows={5}
                        id="Remarks"
                        placeholder="Remarks"
                        value={formData.remark}
                        onChange={handleInputChange}
                        isInvalid={!!formErrors.remark}
                    />
                    <Form.Label htmlFor="Content">Remarks</Form.Label>
                    {formErrors.remark && <Form.Control.Feedback type="invalid">{formErrors.remark}</Form.Control.Feedback>}
                </div>

                <div className="form-floating mt-3 position-relative">
                    <Form.Control
                        name="password"
                        type={passwordShow ? 'text' : 'password'}
                        placeholder="Password"
                        value={formData.password}
                        onChange={handleInputChange}
                        isInvalid={!!formErrors.password}
                    />
                    <Form.Label>Password</Form.Label>
                    <Button
                        variant="link"
                        className="position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
                        type="button"
                        id="password-addon"
                        onClick={() => setPasswordShow(!passwordShow)}
                    >
                        <i className="ri-eye-fill align-middle"></i>
                    </Button>
                    {formErrors.password && <Form.Control.Feedback type="invalid">{formErrors.password}</Form.Control.Feedback>}
                </div>
            </Modal.Body>
            <Modal.Footer className="text-end border-top">
                <Button variant="subtle-success" onClick={handleSubmit}>Deposit</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DepositModal;
