import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { authServices } from 'Utils/auth/services';
import snackbarUtil from 'Utils/snackBarUtil';

interface ResetPasswordModalProps {
    show: any;
    clickHandler: any;
    userIds: string;
    userName: string
}


interface AgentProps {
    user_id: string,
    user_name: string,
    name: string,
    user_type_id: number

}

const ParentList = ({ show, clickHandler, userIds, userName }: ResetPasswordModalProps) => {
    const [agentList, setAgentList] = useState<AgentProps[]>([])

    const getParentList = async () => {
        const { response } = await authServices.getShowAgent({
            user_id: userIds
        })

        setAgentList(response?.data?.agents)
    }

    useEffect(() => {
        if (userIds) {
            getParentList();
        }
    }, [userIds])

    return (
        <Modal show={show} onHide={clickHandler} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <span>Parent List of ({userName})</span>
                <Button variant="light btn-sm" onClick={() => clickHandler()}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className="fs-md">
                {
                    agentList?.map((items)=>{
                        return(
                            <p style={{borderBottom:"1px solid #f2f2f2"}} className='pb-2'>{items?.name} ({items?.user_name})</p>
                        )
                    })
                }
            </Modal.Body>
        </Modal>
    );
};

export default ParentList;
