import React, { Dispatch, FC, SetStateAction } from 'react'
import { Market } from './DetailsLeft'
import { Button, Card, OverlayTrigger, Table, Tooltip } from 'react-bootstrap'
import ToggleSwitch from 'Common/ToggleSwitch'

import { authServices } from 'Utils/auth/services'
import snackbarUtil from 'Utils/snackBarUtil'
import Marquee from 'react-fast-marquee'
import { BetLimits } from '.'

interface Props {
    combinedDataOdds: Market[] | undefined,
    toggleBook: (id: string) => void
    toggleBets: () => void,
    setFancy: React.Dispatch<SetStateAction<boolean>>,
    fancy: boolean,
    setTableBtn: any,
    tableBtn: any
    toggleMatchSetting: any,
    setLock: Dispatch<SetStateAction<boolean>>,
    lock: boolean,
    toggleAbond: (id: string, name: string, marketName: string) => void,
    toggleNews: (market_id: any, name: any, marketName: string) => void
    getMatchDetails: () => Promise<void>
    getFancyData: () => Promise<void>
    setActive: Dispatch<SetStateAction<boolean>>
    setMarketIds: Dispatch<SetStateAction<string>>
    limits: BetLimits | undefined
    previousCombinedDataOdds: any,
    limitData: any,
    teamPosition: any
}

const MatchedOdds: FC<Props> = ({ teamPosition, limitData, previousCombinedDataOdds, limits, setMarketIds, setActive, combinedDataOdds, getMatchDetails, getFancyData, toggleBook, toggleBets, setFancy, fancy, setTableBtn, tableBtn, toggleMatchSetting, setLock, lock, toggleAbond, toggleNews }) => {

    const updateLoclstatus = async (is_lock: boolean, market_id: string) => {
        const { response } = await authServices.updateLimits({
            market_id,
            values: {
                is_lock
            }

        });
        if (response?.status) {
            snackbarUtil.success(response.msg);
            getFancyData();
            getMatchDetails();
        } else {
            snackbarUtil.error(response.msg)
        }

    }

    const handleLock = (is_lock: boolean, market_id: string) => {
        updateLoclstatus(is_lock, market_id)
    }

    const handleActiveDeactive = (id: number, market_id: string) => {
        setTableBtn(id)
        setActive(true)
        setMarketIds(market_id)
    }

    function convertToKandL(number: number) {
        if (isNaN(number) || number == null) {
            return '--';
        }
        const sizeData = Number(number);
        const absNumber = Math.abs(sizeData);

        if (absNumber >= 10000000) {
            return (sizeData / 10000000).toFixed(1) + 'Cr';
        } else if (absNumber >= 100000) {
            return (sizeData / 100000).toFixed(1) + 'L';
        } else if (absNumber >= 1000) {
            return (sizeData / 1000).toFixed(1) + 'K';
        } else {
            return sizeData.toString();
        }
    }
    function convertToKandLForMax(number: number) {
        if (isNaN(number) || number == null) {
            return '0';
        }
        const sizeData = Number(number);
        const absNumber = Math.abs(sizeData);

        if (absNumber >= 10000000) {
            return (sizeData / 10000000).toFixed(1) + 'Cr';
        } else if (absNumber >= 100000) {
            return (sizeData / 100000).toFixed(1) + 'L';
        } else if (absNumber >= 1000) {
            return (sizeData / 1000).toFixed(1) + 'K';
        } else {
            return sizeData.toString();
        }
    }

    return (
        <> {
            combinedDataOdds?.map((items, id: number) => {
                const prevData = previousCombinedDataOdds && previousCombinedDataOdds[id];
                const limit = limitData && limitData[items?.market_id];
                const marketData = teamPosition?.[items.market_id];
                return (
                    <Card key={id + items?.market_id} className='p-2 mb-2 ribbon-box border shadow-none'>
                        <div className="ribbon ribbon-primary ribbon-shape">{items?.market_name}</div>
                        <div className='d-flex justify-content-end align-items-center'>
                            <span className='fs-3xs'>Min-Max: {convertToKandLForMax(limit?.market_min_stack ?? 0)} - {convertToKandLForMax(limit?.market_max_stack ?? 0)}</span>
                            <Button className='badge-gradient-success btn-sm border-0 ms-2' onClick={() => toggleBook(items?.market_id)}>Book</Button>
                            <Button className='badge-gradient-primary btn-sm border-0 ms-2' onClick={toggleBets}>Bets</Button>
                        </div>

                        <div className="table-responsive">
                            <Table className="table-borderless align-middle table-nowrap mb-0 mt-1">
                                <thead>
                                    <tr>
                                        <th scope="col" className='py-2 px-1'>
                                            <div className='d-flex align-items-center'>
                                                <span>P/S</span>
                                                <ToggleSwitch On='S' Off='F' id='fancy' className='ms-2' onChange={() => setFancy(!fancy)} checked={fancy ? true : false} />
                                                <ToggleSwitch On='On' Off='Off' id='tableBtn' className='ms-2' onChange={() => handleActiveDeactive(items?.is_active, items?.market_id)} checked={items?.is_active ? true : false} />
                                                <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> Market-Setting </Tooltip>}>
                                                    <Button variant="btn-ghost btn-sm p-0 ms-2" onClick={() => toggleMatchSetting(items?.market_id, "market_id")}><i className='ri-settings-4-line fs-xl'></i></Button>
                                                </OverlayTrigger>
                                                <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> {lock ? 'UnLock' : 'Lock'} </Tooltip>}>
                                                    <Button variant="btn-ghost btn-sm p-0 ms-2" onClick={() => handleLock(!items?.is_lock, items?.market_id)}><i className={`fs-xl ${!items?.is_lock ? 'ri-lock-unlock-line' : 'ri-lock-line'}`}></i></Button>
                                                </OverlayTrigger>
                                                <Button className='badge-gradient-danger btn-sm border-0 ms-2' onClick={() => toggleAbond(items?.market_id, "market_id", items?.market_name)}>Abond</Button>
                                                <Button className='badge-gradient-info btn-sm border-0 ms-2' onClick={() => toggleNews(items?.market_id, "market_id", "market")}>News</Button>
                                            </div>
                                        </th>
                                        <th className='py-2 px-1'>
                                            <div className='d-flex align-items-center justify-content-center'>
                                                <div className='px-2 textBack'>Back</div>
                                                <div className='px-2 textLay'>Lay</div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        items?.runners?.map((match, index: number) => {
                                            const prevRunner = prevData?.runners ? prevData.runners[index] : null;
                                            const oddsBack = match.ex?.availableToBack || [];
                                            const oddsLay = match.ex?.availableToLay || [];

                                            const oddsBackPrev = prevRunner?.ex?.availableToBack || [];
                                            const oddsLayPrev = prevRunner?.ex?.availableToLay || [];

                                            const stackBack2 = oddsBack[2]?.size !== oddsBackPrev[2]?.size ? "back_blink" : "";
                                            const stackBack1 = oddsBack[1]?.size !== oddsBackPrev[1]?.size ? "back_blink" : "";
                                            const stackBack0 = oddsBack[0]?.size !== oddsBackPrev[0]?.size ? "back_blink" : "";

                                            const stackLay0 = oddsLay[0]?.size !== oddsLayPrev[0]?.size ? "lay_blink" : "";
                                            const stackLay1 = oddsLay[1]?.size !== oddsLayPrev[1]?.size ? "lay_blink" : "";
                                            const stackLay2 = oddsLay[2]?.size !== oddsLayPrev[2]?.size ? "lay_blink" : "";
                                            const correspondingData = marketData?.find(
                                                (d: any) => d.selection_id === match.selectionId
                                            );
                                            return (
                                                <tr key={index + match?.name}>
                                                    <td className='w-100 p-1'>
                                                        <div className='d-flex align-items-center'>
                                                            <strong>{match?.name}</strong>
                                                            {fancy?<span className={`ms-auto ${correspondingData?.win_loss > 0 ? "text-success" : "text-danger"}`}>{correspondingData?.win_loss?.toFixed(2)}</span>:
                                                            <span className={`ms-auto ${correspondingData?.win_loss_total_exposure > 0 ? "text-success" : "text-danger"}`}>{correspondingData?.win_loss_total_exposure}</span>}
                                                        </div>
                                                    </td>
                                                    <td className='p-1'>
                                                        <div className='oddsSection d-flex position-relative'>
                                                            <div style={{ width: "54px" }} className={`oddBtn p-1 text-secondary backLight ${stackBack2} lh-sm rounded text-center`}>
                                                                <strong className='fs-xs'>{oddsBack && oddsBack[2]?.price || "--"}</strong>
                                                                <p className='mb-0 fs-2xs'>{convertToKandL(oddsBack && oddsBack[2]?.size) || "--"}</p>
                                                            </div>
                                                            <div style={{ width: "54px" }} className={`oddBtn p-1 text-secondary backLight ${stackBack1} lh-sm rounded text-center`}>
                                                                <strong className='fs-xs'>{oddsBack && oddsBack[1]?.price || "--"}</strong>
                                                                <p className='mb-0 fs-2xs'>{convertToKandL(oddsBack && oddsBack[1]?.size) || "--"}</p>
                                                            </div>
                                                            <div style={{ width: "54px" }} className={`oddBtn p-1 text-secondary back ${stackBack0} lh-sm rounded text-center`}>
                                                                <strong className='fs-xs'>{oddsBack && oddsBack[0]?.price || "--"}</strong>
                                                                <p className='mb-0 fs-2xs'>{convertToKandL(oddsBack && oddsBack[0]?.size) || "--"}</p>
                                                            </div>
                                                            <div style={{ width: "54px" }} className={`oddBtn p-1 text-secondary lay ${stackLay0} lh-sm rounded text-center`}>
                                                                <strong className='fs-xs'>{oddsLay && oddsLay[0]?.price || "--"}</strong>
                                                                <p className='mb-0 fs-2xs'>{convertToKandL(oddsLay && oddsLay[0]?.size) || "--"}</p>
                                                            </div>
                                                            <div style={{ width: "54px" }} className={`oddBtn p-1 text-secondary layLight ${stackLay1} lh-sm rounded text-center`}>
                                                                <strong className='fs-xs'>{oddsLay && oddsLay[1]?.price || "--"}</strong>
                                                                <p className='mb-0 fs-2xs'>{convertToKandL(oddsLay && oddsLay[1]?.size) || "--"}</p>
                                                            </div>
                                                            <div style={{ width: "54px" }} className={`oddBtn p-1 text-secondary layLight ${stackLay2} lh-sm rounded text-center`}>
                                                                <strong className='fs-xs'>{oddsLay && oddsLay[2]?.price || "--"}</strong>
                                                                <p className='mb-0 fs-2xs'>{convertToKandL(oddsLay && oddsLay[2]?.size) || "--"}</p>
                                                            </div>
                                                            {(match?.status === "SUSPENDED" || items?.status === "SUSPENDED") && <div className='position-absolute w-100 h-100 border rounded-2 bg-white bg-opacity-75 d-flex align-items-center justify-content-center fs-xs text-danger border-danger text-uppercase fw-bold'>{match?.status || items?.status}</div>}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }


                                </tbody>
                            </Table>
                        </div>
                        <Marquee speed={45}>{items?.news}</Marquee>
                    </Card>
                )
            })
        }</>
    )
}

export default MatchedOdds