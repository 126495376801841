import { useEffect, useState } from 'react';
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import ToggleSwitch from 'Common/ToggleSwitch';
import { authServices } from 'Utils/auth/services';
import snackbarUtil from 'Utils/snackBarUtil';
import { useLocation } from 'react-router-dom';
import { BetLimits } from 'pages/MatchDetail';

interface MatchSettingProps {
    show: boolean;
    clickHandler: any;
    limits: BetLimits | undefined;
    id: string | undefined;
    getLimit: any,
    fancyShow: boolean
    name: string,
    shoeOdss: boolean,
    marketId: string,
    
}

const
    MatchSetting = ({ show, clickHandler, limits, id, getLimit, fancyShow, name, shoeOdss, marketId }: MatchSettingProps) => {
        const [formData, setFormData] = useState({
            market_min_stack: 0,
            market_max_stack: 0,
            market_min_odds_rate: 0,
            market_max_odds_rate: 0,
            market_max_profit: 0,
            market_advance_bet_stake: 0,
            min_volume_limit: 0,
            inplay_max_volume_stake_0_10: 0,
            inplay_max_volume_stake_10_40: 0,
            inplay_max_volume_stake_40: 0,
            max_volume_stake_0_10: 0,
            max_volume_stake_10_40: 0,
            max_volume_stake_40: 0,
            session_min_stack: 0,
            session_max_stack: 0,
            session_max_profit: 0,
        });




        useEffect(() => {
            if (limits) {
                setFormData({
                    market_min_stack: limits.market_min_stack || 0,
                    market_max_stack: limits.market_max_stack || 0,
                    market_min_odds_rate: limits.market_min_odds_rate || 0,
                    market_max_odds_rate: limits.market_max_odds_rate || 0,
                    market_max_profit: limits.market_max_profit || 0,
                    market_advance_bet_stake: limits.market_advance_bet_stake || 0,
                    min_volume_limit: limits.min_volume_limit || 0,
                    inplay_max_volume_stake_0_10: limits.inplay_max_volume_stake_0_10 || 0,
                    inplay_max_volume_stake_10_40: limits.inplay_max_volume_stake_10_40 || 0,
                    inplay_max_volume_stake_40: limits.inplay_max_volume_stake_40 || 0,
                    max_volume_stake_0_10: limits.max_volume_stake_0_10 || 0,
                    max_volume_stake_10_40: limits.max_volume_stake_10_40 || 0,
                    max_volume_stake_40: limits.max_volume_stake_40 || 0,
                    session_min_stack: limits.session_min_stack || 0,
                    session_max_stack: limits.session_max_stack || 0,
                    session_max_profit: limits.session_max_profit || 0,
                });
            }
        }, [limits]);

        const [advanceMarket, setAdvanceMarket] = useState(true);
        const handleInputChange = (e: any) => {
            const { name, value } = e.target;
            setFormData({
                ...formData,
                [name]: Number(value),
            });
        };

        const getUpdateLimits = async (data: any) => {
            let payload;
            if (marketId === "match_id") {
                payload = data;
            } else if (marketId === "fancy_id") {
                payload = {
                    session_min_stack: data.session_min_stack,
                    session_max_stack: data.session_max_stack,
                    session_max_profit: data.session_max_profit,
                }
            } else {
                payload = {
                    market_min_stack: data.market_min_stack,
                    market_max_stack: data.market_max_stack,
                    market_min_odds_rate: data.market_min_odds_rate,
                    market_max_odds_rate: data.market_max_odds_rate,
                    market_max_profit: data.market_max_profit,
                    market_advance_bet_stake: data.market_advance_bet_stake,
                    min_volume_limit: data.min_volume_limit,
                    inplay_max_volume_stake_0_10: data.inplay_max_volume_stake_0_10,
                    inplay_max_volume_stake_10_40: data.inplay_max_volume_stake_10_40,
                    inplay_max_volume_stake_40: data.inplay_max_volume_stake_40,
                    max_volume_stake_0_10: data.max_volume_stake_0_10,
                    max_volume_stake_10_40: data.max_volume_stake_10_40,
                    max_volume_stake_40: data.max_volume_stake_40,
                }
            }
            const { response } = await authServices.updateLimits({
                [marketId]: id || "",
                values: payload
            })

            if (response.status) {
                snackbarUtil.success(response.msg);
                clickHandler();
                getLimit();
            } else {
                snackbarUtil.error(response.msg)
            }
        }
        const handleSave = () => {
            getUpdateLimits(formData);

        };

        return (
            <Modal size="xl" show={show} onHide={clickHandler} className="zoomIn" scrollable>
                <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-3 pb-md-0">
                    <div>{name}
                        {/* <span className='text-muted fw-normal'>({state})</span> */}
                    </div>
                    <Button variant="light btn-sm" onClick={clickHandler}>
                        <i className="ri-close-line fs-xl align-middle"></i>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="g-3">
                            {
                                shoeOdss && <><h5 className='text-primary mb-0'>Market Setting</h5>
                                    <Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="market_min_stack"
                                                type="number"
                                                id="MinStakeAmount"
                                                placeholder="Min. Stake Amount"
                                                value={formData.market_min_stack}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label htmlFor="MinStakeAmount">Min. Stake Amount</Form.Label>
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="market_max_stack"
                                                type="number"
                                                id="MaxStakeAmount"
                                                placeholder="Max. Stake Amount"
                                                value={formData.market_max_stack}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label htmlFor="MaxStakeAmount">Max. Stake Amount</Form.Label>
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="market_min_odds_rate"
                                                type="number"
                                                id="MinOddsRate"
                                                placeholder="Min. Odds Rate"
                                                value={formData.market_min_odds_rate}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label htmlFor="MinOddsRate">Min. Odds Rate</Form.Label>
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="market_max_odds_rate"
                                                type="number"
                                                id="MaxOddsRate"
                                                placeholder="Max. Odds Rate"
                                                value={formData.market_max_odds_rate}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label htmlFor="MaxOddsRate">Max. Odds Rate</Form.Label>
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="market_max_profit"
                                                type="number"
                                                id="MarketMaxProfit"
                                                placeholder="Market Max Profit"
                                                value={formData.market_max_profit}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label htmlFor="MarketMaxProfit">Market Max Profit</Form.Label>
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="market_advance_bet_stake"
                                                type="number"
                                                id="InplayMatchStake"
                                                placeholder="Before Inplay Match Stake"
                                                value={formData.market_advance_bet_stake}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label htmlFor="InplayMatchStake">Before Inplay Match Stake</Form.Label>
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="min_volume_limit"
                                                type="number"
                                                id="MinVolumeStake"
                                                placeholder="Min Volume Stake"
                                                value={formData.min_volume_limit}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label htmlFor="MinVolumeStake">Min Volume Stake</Form.Label>
                                        </div>
                                    </Col>

                                    <div className='d-flex'>
                                        <h5 className='text-primary mb-0'>Advance Market Stake Setting</h5>
                                        <ToggleSwitch On='On' Off='Off' className='ms-2' onChange={() => setAdvanceMarket(!advanceMarket)} />
                                    </div>

                                    <Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="inplay_max_volume_stake_0_10"
                                                type="number"
                                                placeholder="Max Stake (0-10k gbp)"
                                                value={formData.inplay_max_volume_stake_0_10}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label>Max Stake (0-10k gbp)</Form.Label>
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="inplay_max_volume_stake_10_40"
                                                type="number"
                                                placeholder="Max Stake (10k-40k gbp)"
                                                value={formData.inplay_max_volume_stake_10_40}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label>Max Stake (10k-40k gbp)</Form.Label>
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="inplay_max_volume_stake_40"
                                                type="number"
                                                placeholder="Max Stake (>40k gbp)"
                                                value={formData.inplay_max_volume_stake_40}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label>Max Stake ({'>40k gbp'})</Form.Label>
                                        </div>
                                    </Col>

                                    <h5 className='text-primary mb-0'>Market Stake Setting</h5>
                                    <Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="max_volume_stake_0_10"
                                                type="number"
                                                placeholder="Max Stake (0-10k gbp)"
                                                value={formData.max_volume_stake_0_10}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label>Max Stake (0-10k gbp)</Form.Label>
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="max_volume_stake_10_40"
                                                type="number"
                                                placeholder="Max Stake (10k-40k gbp)"
                                                value={formData.max_volume_stake_10_40}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label>Max Stake (10k-40k gbp)</Form.Label>
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="max_volume_stake_40"
                                                type="number"
                                                placeholder="Max Stake (>40k gbp)"
                                                value={formData.max_volume_stake_40}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label>Max Stake ({'>40k gbp'})</Form.Label>
                                        </div>
                                    </Col>
                                </>
                            }


                            {
                                fancyShow && <><h5 className='text-primary mb-0'>Session Setting</h5>
                                    <Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="session_min_stack"
                                                type="number"
                                                placeholder="Session Min Stake"
                                                value={formData.session_min_stack}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label>Session Min Stake</Form.Label>
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="session_max_stack"
                                                type="number"
                                                placeholder="Session Max Stake"
                                                value={formData.session_max_stack}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label>Session Max Stake</Form.Label>
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <div className="form-floating">
                                            <Form.Control
                                                name="session_max_profit"
                                                type="number"
                                                placeholder="Session Max Profit"
                                                value={formData.session_max_profit}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label>Session Max Profit</Form.Label>
                                        </div>
                                    </Col></>
                            }

                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='justify-content-center border-top'>
                    <Button className='mx-2' onClick={handleSave}>Save</Button>
                    <Button variant='light' className='mx-2' onClick={clickHandler}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        );
    };

export default MatchSetting;
