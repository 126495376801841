import { Dispatch, SetStateAction } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

interface NewsModalProps {
	show: any;
	clickHandler: any;
    setNewsValues: Dispatch<SetStateAction<string>>
    newsValues:string
    handleUpdateNews: () => void
}

const NewsModal = ({show, clickHandler, setNewsValues, newsValues, handleUpdateNews} : NewsModalProps) => {
    return (
        <Modal show={show} onHide={clickHandler} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <span>Update News</span>
                <Button variant="light btn-sm" onClick={clickHandler}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='fs-md'>
                <div className="form-floating">
                    <Form.Control onChange={(e)=>setNewsValues(e.target.value)} value={newsValues} as="textarea" rows={5} id="Content" style={{height: 'auto'}} placeholder='Content' />
                    <Form.Label htmlFor="Content">Content</Form.Label>
                </div>
            </Modal.Body>
            <Modal.Footer className='border-top'>
                <Button variant='light' onClick={clickHandler}>Cancel</Button>
                <Button className='ms-2' onClick={handleUpdateNews}>Update</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default NewsModal;