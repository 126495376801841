import ToggleSwitch from 'Common/ToggleSwitch';
import { useState } from 'react';
import { Button, Modal, Form, Table } from 'react-bootstrap';

interface FancyBookProps {
	show: any;
	clickHandler: any;
}

const FancyBook = ({show, clickHandler} : FancyBookProps) => {
    const[showOn, setShowOn] = useState(false)
    return (
        <Modal size='lg' show={show} onHide={clickHandler} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <div className='d-flex align-items-center'>Fancy Position <span className='text-muted fw-normal mx-2'>50 over runs AFG(AFG vs SA)</span> <ToggleSwitch On='On' Off='Off' id='mainMatch' onChange={()=>setShowOn(!showOn)} checked={showOn ? true : false} /></div>
                <Button variant="light btn-sm" onClick={clickHandler}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='fs-md'>
            <div className="table-responsive">
                <Table className="table-striped table-nowrap table-bordered text-center align-middle mb-0">
                    <thead>
                        <tr>
                            <th scope="col">Runs</th>
                            <th scope="col">P&L</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>0-215</td>
                            <td className='text-danger'>-496.9</td>
                        </tr>
                        <tr>
                            <td>216-219</td>
                            <td className='text-success'>603.1</td>
                        </tr>
                        <tr>
                            <td>220-224</td>
                            <td className='text-danger'>96.9</td>
                        </tr>
                        <tr>
                            <td>225-231</td>
                            <td className='text-success'>63.1</td>
                        </tr>
                    </tbody>
                </Table>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default FancyBook;