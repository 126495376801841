import { Dispatch, SetStateAction, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { authServices } from 'Utils/auth/services';
import snackbarUtil from 'Utils/snackBarUtil';

interface ResetPasswordModalProps {
    show: any;
    clickHandler: any;
    userIds: string;
    setshowResetPasswordModal: Dispatch<SetStateAction<boolean>>
}

interface ResData {
    password: string;
    status: boolean;
}

const ResetPasswordModal = ({ show, clickHandler, userIds, setshowResetPasswordModal }: ResetPasswordModalProps) => {
    const [showPassword, setPassword] = useState(false);
    const [passVal, setPasswordVal] = useState<string>("");
    const [passData, setPassData] = useState<ResData | null>(null);
    const [validated, setValidated] = useState(false);  

    const handlePassValue = (e: any) => {
        setPasswordVal(e.target.value);
        setValidated(false); 
    }

    const getShowPass = async (passVal: string) => {
        const { response } = await authServices.getUpdateChildPass({
            childUserId: userIds || "" ,
            newPassword: passVal,
        });

        if(response?.status){
            snackbarUtil.success(response?.msg);
            setshowResetPasswordModal(false);
        }
    }

    const handleHide = () => {
        clickHandler(); 
       
        setPasswordVal("");
        setPassword(false);
        setPassData(null);
        setValidated(false);
    }

    const handlePasswordShow = () => {
        if (passVal.trim() === "") {
            setValidated(true); 
            return;
        }
        getShowPass(passVal);
    }

    return (
        <Modal show={show} onHide={handleHide} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <span>Change Password</span>
                <Button variant="light btn-sm" onClick={handleHide}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className="fs-md">
                <Form noValidate>
                    <div className="form-floating">
                        <Form.Control
                            isInvalid={validated && passVal.trim() === ""}  // Show as invalid if password is empty
                            name="password"
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            value={passVal}
                            onChange={handlePassValue}
                            required
                        />
                        <Form.Label>Enter New Password</Form.Label>
                        <Button
                            variant="link"
                            className="position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
                            type="button"
                            id="password-addon"
                            onClick={() => setPassword(!showPassword)}
                        >
                            <i className="ri-eye-fill align-middle"></i>
                        </Button>
                        <Form.Control.Feedback type="invalid">
                            Please enter your password.
                        </Form.Control.Feedback>
                    </div>
                </Form>
                <div className="text-end mt-3">
                    <Button 
                        variant="subtle-primary" 
                        onClick={handlePasswordShow}
                        disabled={!passVal.trim()}  
                    >
                        Reset Password
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ResetPasswordModal;
