import { Dispatch, SetStateAction, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { authServices } from 'Utils/auth/services';
import snackbarUtil from 'Utils/snackBarUtil';

interface EditCreditReferenceProps {
    show: any;
    clickHandler: any;
    creditRef: number;
    setCreditRef: Dispatch<SetStateAction<number>>
    userName: string;
    userDetails: any;
    userIds: string;
    getUserList:any
}

const EditCreditReference = ({getUserList,userIds, show, clickHandler, creditRef, setCreditRef, userName, userDetails }: EditCreditReferenceProps) => {
    const [passwordShow, setPasswordShow] = useState(false);
    const [formData, setFormData] = useState({
        new_credit_reference: '',
        password: ''
    });
    const [formErrors, setFormErrors] = useState({
        new_credit_reference: '',
        password: ''
    });

    const validateForm = () => {
        let isValid = true;
        const errors: any = {};

        if (!formData.new_credit_reference || isNaN(Number(formData.new_credit_reference)) || Number(formData.new_credit_reference) <= 0) {
            errors.match_commission = 'Please enter a valid amount';
            isValid = false;
        }
        if (!formData.password || isNaN(Number(formData.password)) || Number(formData.password) <= 0) {
            errors.session_commission = 'Please enter a valid amount';
            isValid = false;
        }

        setFormErrors(errors);
        return isValid;
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };


    const creaditData = async()=>{
        const {response} = await authServices.getUpdateCr({
            new_credit_reference:Number(formData?.new_credit_reference),
            user_id:userIds
        })
        if(response?.status){
            snackbarUtil.success(response?.msg);
            getUserList();
            clickHandler();
        }
    }


    const handleSubmit = () => {

        if (validateForm()) {
            if (formData?.password === userDetails?.password) {
                creaditData();
            } else {
                snackbarUtil.error("Please Enter Valid Password");
            }
        }
    };

    return (
        <Modal show={show} onHide={clickHandler} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <span>Edit Credit Reference ({userName})</span>
                <Button variant="light btn-sm" onClick={clickHandler}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='fs-md'>
                <div className='bg-light p-1 rounded text-center'>Current: <strong>{creditRef + +formData?.new_credit_reference}</strong></div>
                <div className="form-floating mt-3">
                    <Form.Control
                        name="new_credit_reference"
                        type="number"
                        placeholder="New credit reference"
                        onChange={handleInputChange}
                        value={formData?.new_credit_reference}
                        isInvalid={!formData?.new_credit_reference && formData?.password.trim() === ""}

                    />
                    <Form.Label>New credit reference</Form.Label>
                    {formErrors.new_credit_reference && (
                        <Form.Control.Feedback type="invalid" className="d-block">
                            {formErrors.new_credit_reference}
                        </Form.Control.Feedback>
                    )}
                </div>
                <div className="form-floating mt-3">
                    <Form.Control
                        name="password"
                        type={passwordShow ? "text" : "password"}
                        placeholder="Password"
                        onChange={handleInputChange}
                        value={formData?.password}
                        isInvalid={!formData?.password && formData?.password.trim() === ""}
                    />
                    <Form.Label>Password</Form.Label>
                    {formErrors.password && (
                        <Form.Control.Feedback type="invalid" className="d-block">
                            {formErrors.password}
                        </Form.Control.Feedback>
                    )}
                    <Button
                        variant="link"
                        className="position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
                        type="button"
                        id="password-addon"
                        onClick={() => setPasswordShow(!passwordShow)}

                    >
                        <i className="ri-eye-fill align-middle"></i>
                    </Button>
                </div>
            </Modal.Body>
            <Modal.Footer className='text-end border-top'>
                <Button variant='subtle-primary' onClick={handleSubmit}>Update</Button>
                <Button className='btn-ghost-primary' onClick={clickHandler}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditCreditReference;