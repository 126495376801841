export function getLastTimeOfDay(dateInput: Date) {
    const date = new Date(dateInput)
    date.setUTCHours(23, 59, 59, 999)
    date.setUTCDate(date.getUTCDate() + 1);

    return date.toISOString()
  }

export function getStartTimeOfDay(dateInput: Date) {
    const date = new Date(dateInput)
    date.setUTCHours(0, 0, 0, 0)
    date.setUTCDate(date.getUTCDate() + 1);

    return date.toISOString()
  }