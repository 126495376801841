import MatchDetailTable from 'Common/Tables/MatchDetailTable';
import { Button, Modal } from 'react-bootstrap';

interface BetsModalProps {
	show: any;
	clickHandler: any;
}

const BetsModal = ({show, clickHandler} : BetsModalProps) => {
    return (
        <Modal size="lg" show={show} onHide={clickHandler} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <div>Bet List <span className='text-muted fw-normal'>(Match Odds)</span></div>
                <Button variant="light btn-sm" onClick={clickHandler}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='pt-1 fs-md'>
                {/* <MatchDetailTable betsDetails={undefined} totalPages={betsDetails && betsDetails?.metadata && betsDetails?.metadata[0]?.total} betsDetails={betsDetails} handlePreviousPage={handlePreviousPage} handleNextPage={handleNextPage}/> */}
            </Modal.Body>
        </Modal>
    );
};

export default BetsModal;