import { Navigate } from "react-router-dom";

import Fourzerofour from "pages/AuthenticationInner/Errors/404Error";
import Fivezerozero from "pages/AuthenticationInner/Errors/500Error";
import Fivezerothree from "pages/AuthenticationInner/Errors/503Error";
import OfflinePage from "pages/AuthenticationInner/Errors/Offline";

import Dashboard from "pages/Dashboard";

// Authentication
import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/Logout";
import Register from "pages/Authentication/Register";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import UserProfile from "pages/Authentication/user-profile";
import ChangePassword from "pages/Authentication/ChangePassword";
import TransactionCode from "pages/Authentication/TransactionCode";
import SecureAuth from "pages/SecureAuth";
import Verification from "pages/Authentication/Verification";
import MatchDetail from "pages/MatchDetail";
import SeriesByData from "Common/SeriesByData";
import SeriesData from "pages/SeriesData";
import Styles from "pages/Styles";
import AddAgent from "pages/AddAgent";
import ClosedUser from "pages/ClosedUser";
import UserList from "pages/UserList";
import OnlineUser from "pages/OnlineUser";
import ImportMarket from "pages/ImportMarket";
import Reports from "pages/Reports";
import MarketAnalysis from "pages/MarketAnalysis";

import { DomainSetting } from "pages/Super";



const authProtectedRoutes = [
    // Dashboard
    { path: "/dashboard", component: <Dashboard /> },
    { path: "/dashboard/:seriesId/:sportId", component: <SeriesData /> },
    { path: "/", exact: true ,component: <Navigate to="/dashboard" /> },
    { path: "*", component: <Navigate to="/dashboard" /> },
    { path: "/match-detail/:id/:sportid", exact: true, name: "Match Detail", component: <MatchDetail /> },

    //user prpfile
    { path: "/user-profile", name : "UserProfile" ,component: <UserProfile /> },
    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    { path: "/", exact: true, name : "Navigate" ,component: <Navigate to="/dashboard" /> },
    { path: "/secure-auth", exact: true, name : "SecureAuth" ,component: <SecureAuth /> },
    { path: "/styles", exact: true, name : "Styles" ,component: <Styles /> },
    { path: "/add-agent", exact: true, name : "Add Agent" ,component: <AddAgent type="" name="Agent"/> },
    { path: "/add-uk-agent", exact: true, name : "Add Uk Agent" ,component: <AddAgent type="uk" name="UK Agent"/> },
    { path: "/add-user", exact: true, name : "Add User" ,component: <AddAgent type="user" name="User"/> },
    { path: "/addChild-user/:id/:type", exact: true, name : "Add User" ,component: <AddAgent type="user" name="User"/> },
    { path: "/addDemo-user/:id/:type", exact: true, name : "Add User" ,component: <AddAgent type="user" name="User"  demo={true}/> },
    { path: "/b2c-manager", exact: true, name : "B2C Manager" ,component: <AddAgent type="b2c" name="B2C Manager"/> },
    { path: "/closed-user", exact: true, name : "Closed User" ,component: <ClosedUser /> },
    { path: "/user-list", exact: true, name : "User List" ,component: <UserList agent={false}/> },
    { path: "/agent-user-list", exact: true, name : "Agent User List" ,component: <UserList agent={true} /> },
    { path: "/online-user", exact: true, name : "Online List" ,component: <OnlineUser /> },
    { path: "/import-market", exact: true, name : "Online List" ,component: <ImportMarket /> },
    { path: "/reports", exact: true, name : "Reports" ,component: <Reports /> },
    { path: "/market-analysis", exact: true, name : "Reports" ,component: <MarketAnalysis /> },
    { path: "/domain-setting", exact: true, name : "Domain Setting", component: <DomainSetting /> },

];

const publicRoutes = [
    // Authentication
    { path: "/login", name : "Login" ,component: <Login /> },
    { path: "/logout", name : "Logout" ,component: <Logout /> },
    { path: "/register", name : "Register" ,component: <Register /> },
    { path: "/forgot-password", name : "ForgotPassword" ,component: <ForgotPassword /> },
    { path: "/change-password", name : "ChangePassword" ,component: <ChangePassword /> },
    { path: "/transaction-code", name : "TransactionCode" ,component: <TransactionCode /> },
    { path: "/verification", name : "Verification" ,component: <Verification /> },

    // Authentication Inner
    // Error
    // Error 404
    { path: "/auth-404", name : "Fourzerofour" ,component: <Fourzerofour /> },

    // Error 500
    { path: "/auth-500", name : "Fivezerozero" ,component: <Fivezerozero /> },

    // Error 503
    { path: "/auth-503", name : "Fivezerothree" ,component: <Fivezerothree /> },

    // Offline Page
    { path: "/auth-offline", name : "OfflinePage" ,component: <OfflinePage /> },
];

export { authProtectedRoutes, publicRoutes };