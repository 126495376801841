import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Table, Form, Button } from 'react-bootstrap';

interface Props {
  userData: SportsShare[] | undefined;
  sportShare: SportsSharePayload[];
  setSportShare: Dispatch<SetStateAction<SportsSharePayload[]>>;
  type: string;
}

const SharingTable: FC<Props> = ({ userData, sportShare, setSportShare, type }) => {
  const [ukShareValue, setUkShareValue] = useState<number>(sportShare[1]?.percentage[1]?.share || 0);

  // Utility to clamp values between 0 and 100
  const clampValue = (value: number) => {
    return Math.max(0, Math.min(100, value));
  };

  const updateShareValue = (value: number) => {
    const clampedValue = clampValue(value);
    const updatedShares = [...sportShare];

    updatedShares.forEach((share) => {
      if (type === "uk") {
        share.percentage[1].parent_share = clampedValue;
        share.percentage[1].share = clampedValue;
      } else {
        share.percentage[0].share = clampedValue;
        share.percentage[0].user_share = clampedValue;
        share.percentage[1].share = clampedValue;
        share.percentage[1].user_share = clampedValue;
        share.percentage[1].parent_share = clampedValue;
        share.percentage[1].parent_partnership_share =
          share.percentage[0]?.parent_share - clampedValue;
      }
    });

    setSportShare(updatedShares);
  };

  const countUp = () => {
    const currentValue = sportShare[0]?.percentage[1].share;
    if (currentValue < 100) {
      updateShareValue(currentValue + 1);
    }
  };

  const countDown = () => {
    const currentValue = sportShare[0]?.percentage[1].share;
    if (currentValue > 0) {
      updateShareValue(currentValue - 1);
    }
  };

  const handleInputChange = (value: number) => {
    updateShareValue(value);
  };

  const handleUkShareChange = (value: number) => {
    setUkShareValue(clampValue(value));
    const updatedShares = [...sportShare];
    updatedShares.forEach((share) => {
      share.percentage[0].share = clampValue(value);
      share.percentage[1].share = 0;
      share.percentage[0].user_share = clampValue(value);
      share.percentage[0].parent_share = 0;
      share.percentage[1].parent_share = clampValue(value);
    });
    setSportShare(updatedShares);
  };

  const handleFocus = (e?: any, index?:number) => {
    if(index === 0){
      if (e.target.value === "0") {
        e.target.value = "";
      }
    }
   
  };

  return (
    <div className='table-responsive'>
      {type !== "uk" ? (
        <Table className='table-striped'>
          <thead className='table-light'>
            <tr>
              <th>Game</th>
              <th>Share</th>
              <th className='text-end text-nowrap'>Parent Share</th>
            </tr>
          </thead>
          <tbody>
            {userData?.map((item, index) => {
              return (
                <tr key={index}>
                  <td className='w-50'>{item?.name}</td>
                  <td className='py-1'>
                    <div className="input-step step-primary">
                      <Button className="minus" onClick={countDown}>-</Button>
                      <Form.Control
                        type="number"
                        name="share"
                        className="product-quantity"
                        value={sportShare[0]?.percentage[1].share}
                        min="0"
                        max="100"
                        readOnly={index !== 0}
                        onFocus={(e)=>handleFocus(e,index)}
                        onChange={(e) => handleInputChange(clampValue(Number(e.target.value)))}
                      />
                      <span className='py-1 px-2'>%</span>
                      <Button className="plus" onClick={countUp}>+</Button>
                    </div>
                  </td>
                  <td className='text-end'>
                    {sportShare[0].percentage[0]?.parent_share - sportShare[0].percentage[1].share}%
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <div className="input-step step-primary">
          <Form.Control
            type="number"
            name="share"
            className="product-quantity"
            value={ukShareValue}
            min="0"
            max="100"
            onFocus={(e) => handleFocus(e, 0)}
            onChange={(e) => handleUkShareChange(clampValue(Number(e.target.value)))}
          />
          <span className='py-1 px-2'>%</span>
        </div>
      )}
    </div>
  );
};

export default SharingTable;
