import TableContainer from "Common/TableContainer";
import moment from "moment";
import { useMemo, useState } from "react";
import { Button, Modal, Pagination, Table } from "react-bootstrap";

interface ProfitLossTableProps {
  profitLossData: ProfitLossData[];
  listTotal: ProfitLossTotal;
  profitLossSum: ProfitLossSum;
  handlePageFilter: (value: number) => void;
}

const ProfitLossTable = ({
  profitLossData,
  listTotal,
  profitLossSum,
  handlePageFilter,
}: ProfitLossTableProps) => {
  const [showList, setList] = useState(false);
  const toggleList = () => {
    setList(!showList);
  };

  const numbersArray = useMemo(
    () => Array.from({ length: listTotal.total }, (_, index) => index + 1),
    [listTotal.total]
  );

  const renderPaginationItems = () => {
    const totalPages = numbersArray.length;
    const currentPage = listTotal.page;
    const maxVisiblePages = 10;
    const paginationItems = [];

    if (totalPages <= maxVisiblePages) {
      paginationItems.push(
        ...numbersArray.map((num) => (
          <Pagination.Item
            key={num}
            active={num === currentPage}
            onClick={() => handlePageFilter(num)}
          >
            {num}
          </Pagination.Item>
        ))
      );
    } else {
      paginationItems.push(
        <Pagination.Item
          key={1}
          active={1 === currentPage}
          onClick={() => handlePageFilter(1)}
        >
          1
        </Pagination.Item>
      );

      if (currentPage > 4) {
        paginationItems.push(<Pagination.Ellipsis key="start-ellipsis" />);
      }

      const startPage = Math.max(2, currentPage - 2);
      const endPage = Math.min(totalPages - 1, currentPage + 2);

      for (let i = startPage; i <= endPage; i++) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => handlePageFilter(i)}
          >
            {i}
          </Pagination.Item>
        );
      }

      if (currentPage < totalPages - 3) {
        paginationItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
      }

      paginationItems.push(
        <Pagination.Item
          key={totalPages}
          active={totalPages === currentPage}
          onClick={() => handlePageFilter(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }

    return paginationItems;
  };

  return (
    <>
      <div className="table-responsive">
        <Table className="table-centered align-middle table-nowrap table-striped">
          <thead className="table-light">
            <tr>
              <th>No.</th>
              <th>Date</th>
              <th>Sports | Series</th>
              <th>Match | Market</th>
              <th>P | L</th>
              <th>Commission</th>
              <th>Net PNL</th>
              <th>Result</th>
              <th>View Bets</th>
            </tr>
          </thead>
          <tbody>
            {profitLossData?.map((item, index) => {
              return (
                <tr>
                  <td>{index < 9 ? `0${index + 1}` : index + 1}</td>
                  <td>
                    <p className="mb-0">
                      {moment(item?.result_date).format("DD-MM-YY")}
                    </p>
                    <p className="mb-0">
                      {moment(item?.result_date).format(" hh:mm A")}
                    </p>
                  </td>
                  <td>
                    <strong>{item?.sport_name}</strong>
                    <p className="mb-0 text-muted">{item?.series_name}</p>
                  </td>
                  <td>
                    <strong>{item?.match_name}</strong>
                    <p className="mb-0 text-muted">{item?.event_name}</p>
                  </td>
                  <td
                    className={`text-${item?.p_l < 0 ? "danger" : "success"}`}
                  >
                    {item?.p_l}
                  </td>
                  <td
                    className={`text-${
                      item?.commission < 0 ? "danger" : "success"
                    }`}
                  >
                    {item?.commission}
                  </td>
                  <td
                    className={`text-${
                      item?.net_pl < 0 ? "danger" : "success"
                    }`}
                  >
                    {item?.net_pl}
                  </td>
                  <td>{item?.winner_name}</td>
                  <td>
                    <Button variant="subtle-dark" className="btn-sm">
                      View Bets
                    </Button>
                    <Button variant="subtle-primary" className="btn-sm ms-2">
                      P | L
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={3}></th>
              <th>Total</th>
              <th
                className={`text-${
                  profitLossSum?.p_l < 0 ? "danger" : "success"
                }`}
              >
                {profitLossSum?.p_l || 0}
              </th>
              <th
                className={`text-${
                  profitLossSum?.commission < 0 ? "danger" : "success"
                }`}
              >
                {profitLossSum?.commission || 0}
              </th>
              <th
                colSpan={3}
                className={`text-${
                  profitLossSum?.net_pl < 0 ? "danger" : "success"
                }`}
              >
                {profitLossSum?.net_pl || 0}
              </th>
            </tr>
          </tfoot>
        </Table>

        <nav aria-label="Page navigation" className="px-3 d-flex">
          <Pagination className="ms-auto mb-0">
            <Pagination.Item
              className={`${listTotal.page < 2 && "disabled"}`}
              onClick={() =>
                listTotal.page > 1 && handlePageFilter(listTotal.page - 1)
              }
            >
              <i className="mdi mdi-chevron-left"></i>
            </Pagination.Item>
            {renderPaginationItems()}
            <Pagination.Item
              className={`${
                listTotal.page != numbersArray.length ? "" : "disabled"
              }`}
              onClick={() =>
                listTotal.page != numbersArray.length &&
                handlePageFilter(listTotal.page + 1)
              }
            >
              <i className="mdi mdi-chevron-right"></i>
            </Pagination.Item>
          </Pagination>
        </nav>
      </div>

      <Modal show={showList} onHide={toggleList} className="zoomIn" scrollable>
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <div className="d-flex align-items-center">
            Parent List of{" "}
            <span className="text-muted fw-normal mx-2">(lcbook247-prime)</span>
          </div>
          <Button variant="light btn-sm" onClick={toggleList}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>List</Modal.Body>
      </Modal>
    </>
  );
};

export default ProfitLossTable;
