import BreadCrumb from "Common/BreadCrumb";
import { useEffect, useState } from "react";
import { Container, Card, Form, Button, Table, Pagination, Modal, Dropdown } from "react-bootstrap";
import { authServices } from "Utils/auth/services";
import snackbarUtil from "Utils/snackBarUtil";

const ClosedUser = () => {
    const [showBtn, setBtn] = useState(false)
    const [openUser, setUser] = useState(false)
    const [userId, setUserId] = useState<string | undefined>("")
    const [closeUserData, setCloseUserData] = useState<CloseData[]>([])
    const [modalType, setModalType] = useState<'user' | 'allUsers'>('user');

    const toggleUser = (type?: 'user' | 'allUsers', ids?:string | undefined) => {
        if (type) {
            setModalType(type);
        }
        setUser(!openUser);
        setUserId(ids)
    };

    const getCloseUserDetails = async () => {
        const { response } = await authServices.getCloseUser({
            limit: 50,
            page: 1
        })
        setCloseUserData(response?.data)
    }
    const getCloseUser = async (user_id:string | undefined) => {
        const { response } = await authServices.getClose({
            action:0,
            user_id:user_id||"",
        })
       if(response?.status){
        snackbarUtil.success(response.msg);
        getCloseUserDetails();
        setUser(false);
       }

    }


    useEffect(() => {
        getCloseUserDetails();
    }, [])


    const handleSubmit = ()=>{
        getCloseUser(userId);
    }

    return (
        <div className="page-content">
            <Container fluid>
                <div className="position-relative mb-2">
                    <BreadCrumb title="Closed User" pageTitle="Dashboard" back />
                    {showBtn ? <Button variant="dark" className="position-absolute top-0 end-0" onClick={() => toggleUser('allUsers')}>Open All User</Button> : null}
                </div>
                <Card>
                    <div className="table-responsive">
                        <Table className="table-striped">
                            <thead>
                                <tr>
                                    <th>
                                        <div className="form-check form-check-dark">
                                            <Form.Check type="checkbox" id="openUser" onClick={() => setBtn(!showBtn)} />
                                        </div>
                                    </th>
                                    <th>Closed User Name</th>
                                    <th>Parent Name</th>
                                    <th>Action</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    closeUserData?.map((items) => {
                                        return (
                                            <tr>
                                                <td>
                                                    <div className="form-check form-check-dark">
                                                        <Form.Check type="checkbox" id="openUser" />
                                                    </div>
                                                </td>
                                                <td>{items?.name}</td>
                                                <td>{items?.parent_user_name}</td>
                                                <td>
                                                    <Button variant="subtle-dark" className="btn-sm" onClick={() => toggleUser('user', items?._id)}><i className="ri-arrow-right-up-line fs-xl align-middle"></i> Open User</Button>
                                                </td>
                                                <td className="text-end pe-4">
                                                    <Dropdown drop="start">
                                                        <Dropdown.Toggle variant="subtle-dark" className="e-caret-hide btn-sm">
                                                            <i className="fs-lg ri-more-2-fill"></i>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item className="border-bottom py-2">
                                                                <i className="fs-lg me-2 ri-file-list-3-fill align-middle"></i> Report
                                                            </Dropdown.Item>
                                                            <Dropdown.Item className="border-none py-2">
                                                                <i className="fs-lg me-2 ri-parent-fill align-middle"></i> Parent List
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }


                            </tbody>
                        </Table>
                    </div>
                    <nav aria-label="Page navigation" className="px-3 d-flex">
                        <Pagination className="ms-auto">
                            <Pagination.Item className="disabled" >← Prev</Pagination.Item>
                            <Pagination.Item>{1}</Pagination.Item>
                            <Pagination.Item active>{2}</Pagination.Item>
                            <Pagination.Item>{3}</Pagination.Item>
                            <Pagination.Item>Next →</Pagination.Item>
                        </Pagination>
                    </nav>
                </Card>
            </Container>

            <Modal show={openUser} onHide={toggleUser} className="zoomIn" scrollable>
                <Modal.Body className='text-center '>
                    <Button variant="light btn-sm" className='position-absolute' style={{ right: '1rem' }} onClick={() => toggleUser()}>
                        <i className="ri-close-line fs-xl align-middle"></i>
                    </Button>
                    <i className="bi bi-exclamation-triangle text-warning display-5"></i>
                    <h4>{modalType === 'allUsers' ? 'Oepn All Selected' : 'Open'} User?</h4>
                    <p className='fs-lg mt-4'>Are you sure you want to reopen {modalType === 'allUsers' ? 'All Selected' : 'This'} user account?</p>
                    <div className='mt-4'>
                        <Button variant='light' className='mx-2' onClick={() => toggleUser()}>No</Button>
                        <Button className='mx-2' onClick={handleSubmit}>Yes</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ClosedUser;