import { METHODS } from "http";
import { apiHandler, apiWithSnackbar } from "../apiService";
import { authResourcs as authResources } from "./resources";

export const authServices = {
  changePassword: async (data: ChangePasswordPayload) => {
    const params = {
      resource: authResources.CHANGE_PASSWORD,
      data,
    };
    return await apiHandler(params);
  },
  setTransPass: async () => {
    const params = {
      resource: authResources.SET_TRANS,
    };
    return await apiHandler(params);
  },
  telegramCode: async (data: TeleCode) => {
    const params = {
      resource: authResources.TELEGRAM_CODE,
      data,
    };
    return await apiHandler(params);
  },

  getExposureSport: async (data: expSport) => {
    const params = {
      resource: authResources.GET_EXPOSURE_SPORT,
      data,
    };
    return await apiWithSnackbar(params);
  },
  getExposureSportV1: async (data: expSport) => {
    const params = {
      resource: authResources.GET_EXPV1_SPORT,
      data,
    };
    return await apiWithSnackbar(params);
  },


  getExposureCasino: async (data: expCasino) => {
    const params = {
      resource: authResources.GET_CASINO_EXPOSURE,
      data,
    };
    return await apiWithSnackbar(params);
  },
  showBalance: async (data: Balance) => {
    const params = {
      resource: authResources.SHOW_BALANCE,
      data,
    };
    return await apiWithSnackbar(params);
  },
  getSportList: async () => {
    const params = {
      resource: authResources.GET_SPORT,

    };
    return await apiWithSnackbar(params);
  },
  getSportData: async (data: SportDataPayload) => {
    const params = {
      resource: authResources.GET_SPORT_DATA,
      data,

    };
    return await apiWithSnackbar(params);
  },
  getNews: async () => {
    const params = {
      resource: authResources.GET_NEWS,
    };
    return await apiWithSnackbar(params);
  },
  getSeriesData: async (data: SeriesDataPayload) => {
    const params = {
      resource: authResources.GET_SERIES,
      data
    };
    return await apiWithSnackbar(params);
  },

  getEnabaledFancy: async (data: enableFancy) => {
    const params = {
      resource: authResources.ENABLED_FANCY,
      data
    };
    return await apiWithSnackbar(params);
  },

  updateMatchStatus: async (data: updateMatchStatus) => {
    const params = {
      resource: authResources.UPDATE_MATCH_STATUS,
      data
    };
    return await apiHandler(params);
  },
  updateEvent: async (data: UpdateEventList) => {
    const params = {
      resource: authResources.UPDATE_EVENT,
      data
    };
    return await apiHandler(params);
  },

  validToken: async () => {
    const params = {
      resource: authResources.VALID_TOKEN
    };
    return await apiWithSnackbar(params);
  },
  getMatchedDetails: async (data: matchDetailsPayload) => {
    const params = {
      resource: authResources.GET_MATCHDETAILS,
      data
    };
    return await apiWithSnackbar(params);
  },
  getMatchedDetailsRunner: async (data: matchDetailsRunnerPayload) => {
    const params = {
      resource: authResources.GET_MATCHDETAILS_RUNNER,
      data
    };
    return await apiWithSnackbar(params);
  },
  getFancyData: async (data: matchDetailsPayload) => {
    const params = {
      resource: authResources.FANCY_DATA,
      data
    };
    return await apiWithSnackbar(params);
  },
  getFancyLiveData: async (data: matchDetailsPayload) => {
    const params = {
      resource: authResources.FANCY_LIVE_DATA,
      data
    };
    return await apiWithSnackbar(params);
  },
  getBets: async (data: BetsPayload) => {
    const params = {
      resource: authResources.BETS,
      data
    };
    return await apiWithSnackbar(params);
  },
  getOnlineFancy: async (data: matchedIdd) => {
    const params = {
      resource: authResources.GETONLINE_FANCY,
      data
    };
    return await apiWithSnackbar(params);
  },
  updateFancyStatus: async (data: UpdateStatus) => {
    const params = {
      resource: authResources.UPDATE_FANCY_STATUS,
      data
    };
    return await apiWithSnackbar(params);
  },
  updateFancy: async (data: Update_fancy) => {
    const params = {
      resource: authResources.UPDATE_FANCY,
      data
    };
    return await apiWithSnackbar(params);
  },
  getTeamPosition: async (data: matchedIdds) => {
    const params = {
      resource: authResources.GET_TEAM_POSITION,
      data
    };
    return await apiWithSnackbar(params);
  },
  getLimits: async (data: matchedIdd) => {
    const params = {
      resource: authResources.GET_LIMIT,
      data
    };
    return await apiWithSnackbar(params);
  },
  updateLimits: async (data: updateLimits) => {
    const params = {
      resource: authResources.UPDATE_LIMITS,
      data
    };
    return await apiWithSnackbar(params);
  },

  oddsAbondoned: async (data: oddsReq) => {
    const params = {
      resource: authResources.ODDS_ABONDONED,
      data
    };
    return await apiWithSnackbar(params);
  },
  updateMarketStatus: async (data: marketStatusRes) => {
    const params = {
      resource: authResources.UPDATE_MARKET_STATUS,
      data
    };
    return await apiWithSnackbar(params);
  },
  getMarketPosition: async (data: getMarketPayload) => {
    const params = {
      resource: authResources.GET_MARKET_POSITION,
      data
    };
    return await apiWithSnackbar(params);
  },
  getAddUserDetails: async (data: AddUsePayload) => {
    const params = {
      resource: authResources.ADD_USER,
      data
    };
    return await apiWithSnackbar(params);
  },
  getUserRegister: async (data: any) => {
    const params = {
      resource: authResources.USER_REGISTER,
      data
    };
    return await apiWithSnackbar(params);
  },
  getCloseUser: async (data: closeUser) => {
    const params = {
      resource: authResources.GET_CLOSE_USER,
      data
    };
    return await apiWithSnackbar(params);
  },
  getClose: async (data: closeUserPayload) => {
    const params = {
      resource: authResources.GET_CLOSE,
      data
    };
    return await apiHandler(params);
  },
  getUserListData: async (userId: string, data: closeUser) => {
    const resource = authResources.USER_LIST_WITH_CHILD;
    const params = {
      resource: {
        ...resource,
        URL: `${resource.URL}/${userId}`,  
      },
      data,
    };
    return await apiHandler(params);
  },
  getShowPass: async (data: ShowPassPay) => {
    const params = {
      resource: authResources.SHOW_PASSWORD,
      data
    };
    return await apiHandler(params);
  },
  getUpdateChildPass: async (data: chnagePaeePay) => {
    const params = {
      resource: authResources.UPDATE_CHILD_PASS,
      data
    };
    return await apiHandler(params);
  },
  getUserDetailsParent: async (data: string) => {
    const params = {
      resource: {
        URL:`/user/getUserDetailsWithParentDetails/${data}`,
        METHODS:"GET"
      },
      data
    };
    return await apiHandler(params);
  },
  getChipsOut: async (data: ChipsPayload) => {
    const params = {
      resource: authResources.CHIPS_OUT,
      data
    };
    return await apiHandler(params);
  },
  getCommittion: async (data: ShowPassPay) => {
    const params = {
      resource: authResources.GET_COMMITTION,
      data
    };
    return await apiHandler(params);
  },
  getUpdateCommittion: async (data: updateProps) => {
    const params = {
      resource: authResources.UPDATE_COMMITTION,
      data
    };
    return await apiHandler(params);
  },
  getLogo: async (data:logoPayload) => {
    const params = {
      resource: authResources.GET_LOGO,
      data
    };
    return await apiHandler(params);
  },
  getUpdateCr: async (data:updateCRPayload) => {
    const params = {
      resource: authResources.UPADATE_CR,
      data
    };
    return await apiHandler(params);
  },
  getMultiLogin: async (data:userLockPaylod) => {
    const params = {
      resource: authResources.ALLOW_MULTI_LOGIN,
      data
    };
    return await apiHandler(params);
  },
  getBetlock: async (data:userLockPaylod) => {
    const params = {
      resource: authResources.ALLOW_BETLOCK_LOCK,
      data
    };
    return await apiHandler(params);
  },
  getFancyBetlock: async (data:userLockPaylod) => {
    const params = {
      resource: authResources.ALLOW_FANCYLOCK_LOCK,
      data
    };
    return await apiHandler(params);
  },
  getUserLock: async (data:userLockPaylod) => {
    const params = {
      resource: authResources.USER_ACC_LOCK,
      data
    };
    return await apiHandler(params);
  },
  getCloseAcc: async (data:userLockPaylod) => {
    const params = {
      resource: authResources.CLOSE_ACCOUNT,
      data
    };
    return await apiHandler(params);
  },
  getEventLock: async (data:userLockPaylod) => {
    const params = {
      resource: authResources.EVENT_LOCK,
      data
    };
    return await apiHandler(params);
  },
  getPartnerShip: async (data:userLockPaylod) => {
    const params = {
      resource: authResources.GET_PAETNERSHIP_LIST,
      data
    };
    return await apiHandler(params);
  },
  getUpdatePartbership: async (data:SportPaylod) => {
    const params = {
      resource: authResources.UPDATE_PARTNERSHIP,
      data
    };
    return await apiHandler(params);
  },
  getHorseRacing: async (data:horsePayload) => {
    const params = {
      resource: authResources.HORSE_RACING,
      data
    };
    return await apiHandler(params);
  },
  getEventLimits: async (data:eventPayload) => {
    const params = {
      resource: authResources.GET_EVENT_LIMIT,
      data
    };
    return await apiHandler(params);
  },
  getDeleteBet: async (data:DeletePayload) => {
    const params = {
      resource: authResources.DELETE_BET,
      data
    };
    return await apiHandler(params);
  },
  getUserLimits: async (data:userSettingPayload) => {
    const params = {
      resource: authResources.USER_SETTING,
      data
    };
    return await apiHandler(params);
  },
  getUserSettingUpdate: async (data:updateUserSetting) => {
    const params = {
      resource: authResources.USER_SETTING_UPDATE,
      data
    };
    return await apiHandler(params);
  },
  getAllWebsite: async () => {
    const params = {
      resource: authResources.GET_ALL_WEBSITE,
    };
    return await apiHandler(params);
  },
  getUpdateStack: async (data:any) => {
    const params = {
      resource: authResources.UPDATE_STACK,
      data
    };
    return await apiHandler(params);
  },
  getShowAgent: async (data:showAgent) => {
    const params = {
      resource: authResources.SHOW_AGENT,
      data
    };
    return await apiHandler(params);
  },
  getActiveLog: async (data:activeLogsPayload) => {
    const params = {
      resource: authResources.GET_ACTIVE_LOG,
      data
    };
    return await apiHandler(params);
  },
  getAllUserLogout: async (data:any) => {
    const params = {
      resource: authResources.GET_ALL_LOGOUT,
      data
    };
    return await apiHandler(params);
  },
  getSportListData: async (data:any) => {
    const params = {
      resource: authResources.GET_SPORT_LIST,
      data
    };
    return await apiHandler(params);
  },
  getMatchesData: async (data:matchPayload) => {
    const params = {
      resource: authResources.GET_MATCHES,
      data
    };
    return await apiHandler(params);
  },
  getUpdateSportServices: async (data:UpdateStatusPaylod) => {
    const params = {
      resource: authResources.UPDATE_SPORT_STATUS,
      data
    };
    return await apiHandler(params);
  },
  getMarketData: async (data:MatchesPayload) => {
    const params = {
      resource: authResources.GET_MARKET,
      data
    };
    return await apiHandler(params);
  },
  getSeriesUpdate: async (data:SeriesUpdatePayload) => {
    const params = {
      resource: authResources.GET_UPDATE_SERIES_STATUS,
      data
    };
    return await apiHandler(params);
  },
  createMatched: async (data:CreateMatchPayload) => {
    const params = {
      resource: authResources.CREATE_MATCH,
      data
    };
    return await apiHandler(params);
  },
  getTvScore: async (data:tvPaylod) => {
    const params = {
      resource: authResources.GET_TV_SCORE,
      data
    };
    return await apiHandler(params);
  },
  getUpdateUrl: async (data:tvUpdatePaylod) => {
    const params = {
      resource: authResources.UPDATE_TV_URL,
      data
    };
    return await apiHandler(params);
  },
  getAgentUserList: async (data:UserListPayload) => {
    const params = {
      resource: authResources.AGENT_USER_LIST,
      data
    };
    return await apiHandler(params);
  },
  getStatementList: async (data:StatementPayload) => {
    const params = {
      resource: authResources.STATEMENT_LIST,
      data
    };
    return await apiHandler(params);
  },
  getChipSummaryList: async (data: any) => {
    const params = {
      resource: authResources.CHIP_SUMMARY_LIST,
      data
    };
    return await apiHandler(params);
  },
  getChipSummaryHistory: async (data: ChipSummaryHistoryPayload) => {
    const params = {
      resource: authResources.CHIP_SUMMARY_HISTORY,
      data
    };
    return await apiHandler(params);
  },
  getChipSettlement: async (data: ChipSettlementPayload) => {
    const params = {
      resource: authResources.CHIP_SETTLEMENT,
      data
    };
    return await apiHandler(params);
  },
  getCasinoBets: async (data: CasinoBetsPayload) => {
    const params = {
      resource: authResources.CASINO_BETS,
      data
    };
    return await apiHandler(params);
  },
  getProfitLossList: async (data: ProfitLossPayload) => {
    const params = {
      resource: authResources.PROFIT_LOSS,
      data
    };
    return await apiHandler(params);
  },
}