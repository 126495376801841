import Flatpickr from "react-flatpickr";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import ProfitLossTable from "Common/Tables/ProfitLossTable";
import { getLastTimeOfDay, getStartTimeOfDay } from "Utils/datefilter";
import { useEffect, useState } from "react";
import { authServices } from "Utils/auth/services";

const options = [
  { value: "Sport", label: "Sport Name" },
  { value: "Series", label: "Series Name" },
  { value: "Match", label: "Match Name" },
  { value: "Market", label: "Market Name" },
];

interface StatementProps {
  filter?: boolean;
}

const ProfitLoss = ({ filter }: StatementProps) => {
  const [startDate, setStartDate] = useState<string>(
    getStartTimeOfDay(new Date())
  );
  const [endDate, setEndDate] = useState<string>(getLastTimeOfDay(new Date()));
  const [profitLossData, setProfitLossData] = useState<ProfitLossData[]>([]);
  const [listTotal, setListTotal] = useState<ProfitLossTotal>({
    total: 0,
    page: 0,
  });
  const [profitLossSum, setProfitLossSum] = useState<ProfitLossSum>({
    p_l: 0,
    commission: 0,
    net_pl: 0,
  });

  const getProfitLossList = async (filters: any = {}) => {
    const { response } = await authServices.getProfitLossList({
      limit: 50,
      page: 1,
      ...filters,
    });
    if (response?.data && response?.data?.data) {
      setProfitLossData(response?.data?.data);
      setListTotal({
        total: response?.data?.metadata[0]?.total / 50,
        page: response?.data?.metadata[0]?.page,
      });
      setProfitLossSum(response?.data?.sum[0]);
    }
  };

  useEffect(() => {
    getProfitLossList();
  }, []);

  const handlefilterSubmit = () => {
    getProfitLossList({
      from_date: startDate,
      to_date: endDate,
    });
  };

  const handlePageFilter = (page: number) => {
    getProfitLossList({
      from_date: startDate,
      to_date: endDate,
      page: page,
    });
  };

  return (
    <>
      {filter ? (
        <Card>
          <Card.Body>
            <Form>
              <Row className="gy-3 align-items-end">
                <Col lg={3}>
                  <Form.Label>Start Date</Form.Label>
                  <Flatpickr
                    className="form-control"
                    pl
                    options={{
                      enableTime: false,
                      dateFormat: "Y-m-d",
                      defaultDate: [startDate],
                    }}
                    onChange={([date]: [Date]) => {
                      setStartDate(getStartTimeOfDay(new Date(date)));
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>End Date</Form.Label>
                  <Flatpickr
                    className="form-control"
                    pl
                    options={{
                      enableTime: false,
                      dateFormat: "Y-m-d",
                      defaultDate: [endDate],
                    }}
                    onChange={([date]: [Date]) => {
                      setEndDate(getLastTimeOfDay(new Date(date)));
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>Search Sport Name</Form.Label>
                  <Select options={options} className="customSelect" />
                </Col>
                <Col lg={3}>
                  <Form.Label>Search Series Name</Form.Label>
                  <Select options={options} className="customSelect" />
                </Col>
                <Col lg={3}>
                  <Form.Label>Search Match Name</Form.Label>
                  <Select options={options} className="customSelect" />
                </Col>
                <Col lg={3}>
                  <Form.Label>Search Market Name</Form.Label>
                  <Select options={options} className="customSelect" />
                </Col>
                <Col lg={3}>
                  <Button
                    className="me-2 btn-sm"
                    style={{ height: "38px" }}
                    onClick={() => handlefilterSubmit()}
                  >
                    <i className="fs-lg align-middle ri-filter-line"></i> Submit
                  </Button>
                  <Button
                    variant="outline-primary"
                    className="btn-sm"
                    style={{ height: "38px" }}
                  >
                    <i className="fs-lg align-middle ri-refresh-line"></i> Clear
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      ) : null}
      <Card>
        <Card.Body>
          <ProfitLossTable
            profitLossData={profitLossData}
            listTotal={listTotal}
            profitLossSum={profitLossSum}
            handlePageFilter={handlePageFilter}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default ProfitLoss;
