import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, Container, Modal } from 'react-bootstrap';
import BreadCrumb from 'Common/BreadCrumb';
import { authServices } from 'Utils/auth/services';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import TableContainer from 'Common/TableContainer';


export interface sportListProps{
    name: string,
    sport_id: string,
    is_active: boolean,
    providerCode: null,
    is_live_sport: number
}
interface Runner {
    selectionId: number;
    status: string;
    ex: {
      availableToBack: Array<{ size: string; price: string }>;
      availableToLay: Array<{ size: string; price: string }>;
    };
  }
  
 export  interface Match {
    bet_count: any;
    sport_id: string;
    sport_name: string;
    series_id: string;
    series_name: string;
    match_id: string;
    match_name: string;
    market_id: string;
    status: string;
    inplay: boolean;
    match_date: string;
    marketIds: string[];
    is_active: number;
    enable_fancy: number;
    fancy_count: number;
    market_count: number;
    bookmaker_count: number;
    runners: Runner[];
  }
  
 export interface GroupedData {
    [sport_id: string]: Match[];
  }
 export interface GroupedDataName {
    [sport_name: string]: Match[];
  }

const MarketAnalysis = () => {
    const [sportData, setSportData] = useState<GroupedData>();
    const [sportDataName, setSportDataName] = useState<GroupedDataName>();

    const nav = useNavigate();

    const fetchSportData = async()=>{
        const {response} = await authServices.getSportData({
            market_analysis_fields:true,   
            market_analysis:true,   
        });

        const groupedData = groupBySportId(response?.data);
        const groupedDataName = groupBySportName(response?.data);

        setSportData(groupedData)
        setSportDataName(groupedDataName)
    }


    const groupBySportId = (matches: Match[]): GroupedData => {
        return matches.reduce((acc, match) => {
          const { sport_name } = match;
          if (!acc[sport_name]) {
            acc[sport_name] = [];
          }
          acc[sport_name].push(match);
          return acc;
        }, {} as GroupedData);
      };


      const groupBySportName = (matches: Match[]): GroupedData => {
        const groupedData = matches.reduce((acc, match) => {
          const { sport_name } = match;
          if (!acc[sport_name]) {
            acc[sport_name] = [];
          }
          acc[sport_name].push(match);
          return acc;
        }, {} as GroupedData);
      
        const sortedGroupedData = Object.keys(groupedData)
          .sort() 
          .reduce((sortedAcc, sportName) => {
            sortedAcc[sportName] = groupedData[sportName];
            return sortedAcc;
          }, {} as GroupedData);
      
        return sortedGroupedData;
      };
    
    useEffect(() => {
        fetchSportData();
        const intervalId = setInterval(() => {
            fetchSportData();
        }, 10000);
        return () => clearInterval(intervalId);
    }, []);

    const columns = useMemo(() => [
        {
            header: "Match Details",
            accessorKey: "name",
            enableColumnFilter: false,
            enableSorting: false,
            cell: (cell: any) => (
                <>
                    <div className='text-dark fs-lg cursor-pointer d-inline' onClick={()=>nav(`/match-detail/${cell?.row?.original?.matchid}/${cell?.row?.original?.sport_id}`, {state:cell.row.original.matchName})}>{cell.row.original.matchName} ({cell.row.original.bet_count})</div>
                    <div className='d-flex align-items-center'>
                        <span className='inplayBlink'>{cell.row.original.inplay}</span>
                        {
                            cell.row.original.inplay === "INPLAY" && <span className='mx-2 text-muted'>|</span>
                        }

                        {cell.row.original.date && (
                            <>
                                <span className='text-muted'>{cell.row.original.date}</span>
                                <span className='mx-2 text-muted'>|</span>
                            </>
                        )}
                        <span className='text-primary'>{cell.row.original.seriesName}</span>
                    </div>
                </>
            ),
        },
       
    ], []);

    const generateTableData = (key: string) => {
        if (!sportData || !sportData[key] || !Array.isArray(sportData[key])) {
            return [];
        }
    
        return sportData[key].map((items) => ({
                matchName: items?.match_name,
                inplay: items?.inplay && 'INPLAY',
                sport: items?.sport_name,
                odds: items?.runners[0],
                status: items?.status,
                seriesName: items?.series_name,
                matchid: items?.match_id,
                isActive: items?.is_active,
                sport_id:items?.sport_id,
                key: key,
                enableFancy: items.enable_fancy,
                bet_count: items.bet_count,
                date: items?.match_date
                    ? moment(items?.match_date).format('DD-MM-YY hh:mm A')
                    : null,
            }));
    };


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Market Analysis" pageTitle="" />
                    {sportData && Object.keys(sportData).map((key) =>{
                 const filteredData = generateTableData(key);
                 if (filteredData.length === 0) return null;
 
                 const displayCount = filteredData.length;
                 return(
                <Card className='mt-3' key={key}>
                    <Card.Header>
                        <h5>{key} <span className='text-muted'>({displayCount || 0})</span></h5>
                    </Card.Header>
                    <Card.Body className='pt-1 px-4'>
                        <TableContainer
                            isPagination={false}
                            columns={columns}
                            data={generateTableData(key)}
                            isBordered={false}
                            divClassName="table-responsive table-card mt-0"
                            tableClass="table table-striped align-middle table-nowrap mb-0"
                            theadClass="d-none"
                            tdClass="w-100"
                        />
                    </Card.Body>
                </Card>
            )})}
                </Container>
            </div>
            
        </React.Fragment>
    );
};

export default MarketAnalysis;
