import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import DetailsRight from './DetailsRight';
import DetailsLeft from './DetailsLeft';
import DetailsTopBar from './DetailsTopBar';
import { useLocation, useParams } from 'react-router-dom';
import { authServices } from 'Utils/auth/services';
import snackbarUtil from 'Utils/snackBarUtil';


export interface BetLimits {
    _id: string
    sport_id: string
    series_id: string
    match_id: string
    market_id: string
    market_min_stack: number
    market_max_stack: number
    market_min_odds_rate: number
    market_max_odds_rate: number
    market_back_rate_range: number
    market_lay_rate_range: number
    market_max_profit: number
    market_advance_bet_stake: number
    market_live_odds_validation: boolean
    volume_stake_enable: boolean
    min_volume_limit: number
    betting_will_start_time: number
    is_back_bet_allowed: boolean
    is_lay_bet_allowed: boolean
    inplay_max_volume_stake_0_10: number
    inplay_max_volume_stake_10_40: number
    inplay_max_volume_stake_40: number
    max_volume_stake_0_10: number
    max_volume_stake_10_40: number
    max_volume_stake_40: number
    inplay_betting_allowed: boolean
    session_min_stack: number
    session_max_stack: number
    session_max_profit: number
    session_live_odds_validation: boolean
  }

const MatchDetail = () => {
    const [enableFancy, setEnalbleFancy] = useState<number>(0);
    const [updateFancy, setUpdateFancy] = useState<boolean>(false);
    const [show, setShow] = useState(false);
    const [limits, setLimits] = useState<BetLimits>()

    const handleClose = () => setShow(false);
    const {state} = useLocation();

    const {id} = useParams();
    const getFancyEnable = async()=>{
        const {response} = await authServices.getEnabaledFancy({
            enable_fancy:enableFancy === 0?1:0,
            match_id:id||""
        })

        if(response.status){
            snackbarUtil.success(response.msg);
            setShow(false);
            setUpdateFancy(true)
        }else{
            snackbarUtil.error(response?.error);
        }
    }

    const handleActiveDeactive = ()=>{
        getFancyEnable();
    }


    const getLimit = async () => {    
        try {
            const { response } = await authServices.getLimits({match_id: id || ""});

            setLimits(response?.data?.limites);
        } catch (error) {
            console.error("Error fetching limits:", error);
        }
    };
    const getLimitMarket = async (id:string, name:string) => {    
        try {
            let payload;
            if(name === "market_id"){
                payload  = {
                    market_id: id || ""
                }
            }else{
                payload  = {
                    fancy_id: id || ""
                }
            }
            const { response } = await authServices.getLimits(payload);

            setLimits(response?.data?.limites);
        } catch (error) {
            console.error("Error fetching limits:", error);
        }
    };
  

    useEffect(()=>{
        getLimit();
    }, [])
 
    

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={6}>
                            <DetailsLeft state={state} setShow={setShow} setLimits={setLimits} getLimit={getLimit} setEnalbleFancy={setEnalbleFancy} updateFancy={updateFancy} limits={limits} getLimitMarket={getLimitMarket}/>
                        </Col>
                        <Col lg={6}>
                            <DetailsRight />
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal show={show}>
            <Modal.Body>
          <h5 className='lg text-center'>Are you sure want to Enable Fancy ?</h5>
        </Modal.Body>

        <Modal.Footer>
          <Button size='sm' variant="secondary" onClick={handleClose}>Cancel</Button>
          <Button size='sm' variant="primary" onClick={handleActiveDeactive}>Submit</Button>
        </Modal.Footer>
      </Modal>
        </React.Fragment>
    );
};

export default MatchDetail;