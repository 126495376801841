import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import ToggleSwitch from "../ToggleSwitch";
import { useEffect, useState } from 'react';
import { authServices } from 'Utils/auth/services';
import snackbarUtil from 'Utils/snackBarUtil';

interface PermissionModalProps {
    show: any;
    clickHandler: any;
    userIds: string;
    userName: string;
    userData: any;
    getUserList: () => Promise<void>
}

const PermissionModal = ({ show, clickHandler, userIds, userName, userData, getUserList }: PermissionModalProps) => {
    const [showChecked, setChecked] = useState(false);
    const [permissionData, setPermissionData] = useState({
        allowMultilogin: false,
        betAllow: true,
        fancybetAloow: true,
        userLock: false,
        closeUserAccount: false,
        sportSetting: false
    })
    const toggleChecked = (id: string) => {
        setChecked(!showChecked)
    };

    const getMultiLogin = async()=>{
        const {response} = await authServices.getMultiLogin({
            user_id:userIds
        })
        if(response?.status){
            snackbarUtil.success(response?.msg);
            getUserList();
        }
    }
    const getbetLock = async()=>{
        const {response} = await authServices.getBetlock({
            user_id:userIds
        })
        if(response?.status){
            snackbarUtil.success(response?.msg);
            getUserList();
        }
    }
    const getFancybetLock = async()=>{
        const {response} = await authServices.getFancyBetlock({
            user_id:userIds,
            is_child_lock:1,
        })
        if(response?.status){
            snackbarUtil.success(response?.msg);
            getUserList();
        }
    }
    const getUserLock = async()=>{
        const {response} = await authServices.getUserLock({
            user_id:userIds,
            action:userData?.self_lock_user === 0?1:0
        })
        if(response?.status){
            snackbarUtil.success(response?.msg);
            getUserList();
        }
    }
    const getCloseAcc = async()=>{
        const {response} = await authServices.getCloseAcc({
            user_id:userIds,
            action:userData?.self_close_account === 0?1:0
        })
        if(response?.status){
            snackbarUtil.success(response?.msg);
            getUserList();
        }
    }
    const getEventLock = async()=>{
        const {response} = await authServices.getEventLock({
            user_id:userIds,
            check_event_limit:userData?.check_event_limit ?false:true
        })
        if(response?.status){
            snackbarUtil.success(response?.msg);
            getUserList();
        }
    }

    useEffect(() => {
        if (userData) {
            console.log(userData, "userData debug");
            setPermissionData({
                allowMultilogin: userData?.is_multi_login_allow === 0 ? false : true,
                betAllow: (userData?.parent_lock_betting === 0 && userData?.self_lock_betting === 0) ? true : false,
                fancybetAloow: (userData?.parent_lock_fancy_bet === 0 && userData?.self_lock_fancy_bet === 0) ? true : false,
                userLock: (userData?.parent_lock_user === 0 && userData?.self_lock_user === 0) ? false : true,
                closeUserAccount: userData?.self_close_account === 0 ? false : true,
                sportSetting: userData?.check_event_limit || false
            });
        }
    }, [userData]);

    return (
        <Modal show={show} onHide={clickHandler} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <span>Permission Of ({userName})</span>
                <Button variant="light btn-sm" onClick={clickHandler}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='fs-md'>
                <Row className='gap-3'>
                    <Col sm={5}>
                        <div className='d-flex align-items-center'>
                            <ToggleSwitch checked={permissionData?.allowMultilogin} id='MultiLogin' onChange={()=>{getMultiLogin(); clickHandler()}} />
                            <Form.Label className="form-check-label ms-2" htmlFor="MultiLogin">Allow MultiLogin</Form.Label>
                        </div>
                    </Col>
                    <Col sm={5}>
                        <div className='d-flex align-items-center'>
                            <ToggleSwitch checked={permissionData?.betAllow} id='BetAllow' onChange={()=>{getbetLock(); clickHandler()}} />
                            <Form.Label className="form-check-label ms-2" htmlFor="BetAllow">Bet Allow</Form.Label>
                        </div>
                    </Col>
                    <Col sm={5}>
                        <div className='d-flex align-items-center'>
                            <ToggleSwitch checked={permissionData?.fancybetAloow} id='FancyBetAllow' onChange={()=>{getFancybetLock(); clickHandler()}} />
                            <Form.Label className="form-check-label ms-2" htmlFor="FancyBetAllow">Fancy Bet Allow</Form.Label>
                        </div>
                    </Col>
                    <Col sm={5}>
                        <div className='d-flex align-items-center'>
                            <ToggleSwitch checked={permissionData?.userLock} id='LockUser' onChange={()=>{getUserLock(); clickHandler()}} />
                            <Form.Label className="form-check-label ms-2" htmlFor="LockUser">Lock User</Form.Label>
                        </div>
                    </Col>
                    <Col sm={5}>
                        <div className='d-flex align-items-center'>
                            <ToggleSwitch checked={permissionData?.closeUserAccount} id='CloseUser' onChange={()=>{getCloseAcc(); clickHandler()}} />
                            <Form.Label className="form-check-label ms-2" htmlFor="CloseUser">Close User Account</Form.Label>
                        </div>
                    </Col>
                    <Col sm={5}>
                        <div className='d-flex align-items-center'>
                            <ToggleSwitch checked={permissionData?.sportSetting} id='SportsSettings' onChange={()=>{getEventLock(); clickHandler()}} />
                            <Form.Label className="form-check-label ms-2" htmlFor="SportsSettings">Sports Settings</Form.Label>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className='border-top'>
                {/* <Button variant='subtle-primary'>Update</Button> */}
                <Button variant='btn-ghost' onClick={clickHandler}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PermissionModal;