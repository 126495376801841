import CasinoConversionModal from "Common/Modals/CasinoConversionModal";
import DomainSettingModal from "Common/Modals/DomainSettingModal";
import PopupImageModal from "Common/Modals/PopupImageModal";
import SocialMediaModal from "Common/Modals/SocialMediaModal";
import UpdateDomainModal from "Common/Modals/UpdateDomainModal";
import UploadLogoModal from "Common/Modals/UploadLogoModal";
import ToggleSwitch from "Common/ToggleSwitch";
import { useState } from "react";
import { Button, Col, Dropdown, Form, Modal, Pagination, Row, Table } from "react-bootstrap";

const DomainSettingTable = () => {
  const[showDelete, setDelete] = useState(false)
  const[showToggle, setToggle] = useState(false)
  const[showDomainSetting, setDomainSetting] = useState(false)
  const [showPassword, setPassword] = useState(false);
  const[showUploadLogo, setUploadLogo] = useState(false)
  const[showSocialMedia, setSocialMedia] = useState(false)
  const[showTvUrl, setTvUrl] = useState(false)
  const[showUpdateDomain, setUpdateDomain] = useState(false)
  const[showPopupImage, setPopupImage] = useState(false)
  const[showCasinoConversion, setCasinoConversion] = useState(false)

  const toggleDelete = () => {
      setDelete(!showDelete)
  }
  const toggleSwitch = () => {
    setToggle(!showToggle)
  }
  const toggleDomainSetting = () => {
    setDomainSetting(!showDomainSetting)
  }
  const toggleUploadLogo = () => {
    setUploadLogo(!showUploadLogo)
  }
  const toggleSocialMedia = () => {
    setSocialMedia(!showSocialMedia)
  }
  const toggleTvUrl = () => {
    setTvUrl(!showTvUrl)
  }
  const toggleUpdateDomain = () => {
    setUpdateDomain(!showUpdateDomain)
  }
  const togglePopupImage = () => {
    setPopupImage(!showPopupImage)
  }
  const toggleCasinoConversion = () => {
    setCasinoConversion(!showCasinoConversion)
  }

  return (
      <>
      <div className="table-responsive">
        <Table className="table-centered align-middle table-nowrap table-striped">
          <thead className="table-light">
            <tr>
              <th>Domain</th>
              <th>Site Title</th>
              <th>Unmatch Bet</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>https://radheexch99.com</td>
              <td>radheexch99.com</td>
              <td>
                <div className="d-flex align-items-center"><span className="me-2">Unmatch Bet Allowed</span> <ToggleSwitch On="Yes" Off="No" onChange={toggleSwitch} checked={showToggle ? true : false} /></div>
              </td>
              <td>
                <Dropdown drop="start">
                    <Dropdown.Toggle variant="subtle-dark" className="e-caret-hide btn-sm">
                        <i className="ri-more-2-fill"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleDomainSetting}>Update Domain Setting</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleDelete}>Delete Domain</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleUploadLogo}>Upload logo</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleSocialMedia}>Add Social Media</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleTvUrl}>Upload TV Url</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleUpdateDomain}>Update Domain</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={togglePopupImage}>Update Popup Image</Dropdown.Item>
                      <Dropdown.Item className="py-2" onClick={toggleCasinoConversion}>Update Casino Conversion Rate</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
            <tr>
              <td>https://radheexch99.com</td>
              <td>radheexch99.com</td>
              <td>
                <div className="d-flex align-items-center"><span className="me-2">Unmatch Bet Allowed</span> <ToggleSwitch On="Yes" Off="No" onChange={toggleSwitch} checked={showToggle ? true : false} /></div>
              </td>
              <td>
                <Dropdown drop="start">
                    <Dropdown.Toggle variant="subtle-dark" className="e-caret-hide btn-sm">
                        <i className="ri-more-2-fill"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleDomainSetting}>Update Domain Setting</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleDelete}>Delete Domain</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleUploadLogo}>Upload logo</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleSocialMedia}>Add Social Media</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleTvUrl}>Upload TV Url</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleUpdateDomain}>Update Domain</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={togglePopupImage}>Update Popup Image</Dropdown.Item>
                      <Dropdown.Item className="py-2" onClick={toggleCasinoConversion}>Update Casino Conversion Rate</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
            <tr>
              <td>https://radheexch99.com</td>
              <td>radheexch99.com</td>
              <td>
                <div className="d-flex align-items-center"><span className="me-2">Unmatch Bet Allowed</span> <ToggleSwitch On="Yes" Off="No" onChange={toggleSwitch} checked={showToggle ? true : false} /></div>
              </td>
              <td>
                <Dropdown drop="start">
                    <Dropdown.Toggle variant="subtle-dark" className="e-caret-hide btn-sm">
                        <i className="ri-more-2-fill"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleDomainSetting}>Update Domain Setting</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleDelete}>Delete Domain</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleUploadLogo}>Upload logo</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleSocialMedia}>Add Social Media</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleTvUrl}>Upload TV Url</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleUpdateDomain}>Update Domain</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={togglePopupImage}>Update Popup Image</Dropdown.Item>
                      <Dropdown.Item className="py-2" onClick={toggleCasinoConversion}>Update Casino Conversion Rate</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
            <tr>
              <td>https://radheexch99.com</td>
              <td>radheexch99.com</td>
              <td>
                <div className="d-flex align-items-center"><span className="me-2">Unmatch Bet Allowed</span> <ToggleSwitch On="Yes" Off="No" onChange={toggleSwitch} checked={showToggle ? true : false} /></div>
              </td>
              <td>
                <Dropdown drop="start">
                    <Dropdown.Toggle variant="subtle-dark" className="e-caret-hide btn-sm">
                        <i className="ri-more-2-fill"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleDomainSetting}>Update Domain Setting</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleDelete}>Delete Domain</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleUploadLogo}>Upload logo</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleSocialMedia}>Add Social Media</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleTvUrl}>Upload TV Url</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleUpdateDomain}>Update Domain</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={togglePopupImage}>Update Popup Image</Dropdown.Item>
                      <Dropdown.Item className="py-2" onClick={toggleCasinoConversion}>Update Casino Conversion Rate</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
            <tr>
              <td>https://radheexch99.com</td>
              <td>radheexch99.com</td>
              <td>
                <div className="d-flex align-items-center"><span className="me-2">Unmatch Bet Allowed</span> <ToggleSwitch On="Yes" Off="No" onChange={toggleSwitch} checked={showToggle ? true : false} /></div>
              </td>
              <td>
                <Dropdown drop="start">
                    <Dropdown.Toggle variant="subtle-dark" className="e-caret-hide btn-sm">
                        <i className="ri-more-2-fill"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleDomainSetting}>Update Domain Setting</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleDelete}>Delete Domain</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleUploadLogo}>Upload logo</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleSocialMedia}>Add Social Media</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleTvUrl}>Upload TV Url</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleUpdateDomain}>Update Domain</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={togglePopupImage}>Update Popup Image</Dropdown.Item>
                      <Dropdown.Item className="py-2" onClick={toggleCasinoConversion}>Update Casino Conversion Rate</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
            <tr>
              <td>https://radheexch99.com</td>
              <td>radheexch99.com</td>
              <td>
                <div className="d-flex align-items-center"><span className="me-2">Unmatch Bet Allowed</span> <ToggleSwitch On="Yes" Off="No" onChange={toggleSwitch} checked={showToggle ? true : false} /></div>
              </td>
              <td>
                <Dropdown drop="start">
                    <Dropdown.Toggle variant="subtle-dark" className="e-caret-hide btn-sm">
                        <i className="ri-more-2-fill"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleDomainSetting}>Update Domain Setting</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleDelete}>Delete Domain</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleUploadLogo}>Upload logo</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleSocialMedia}>Add Social Media</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleTvUrl}>Upload TV Url</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleUpdateDomain}>Update Domain</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={togglePopupImage}>Update Popup Image</Dropdown.Item>
                      <Dropdown.Item className="py-2" onClick={toggleCasinoConversion}>Update Casino Conversion Rate</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
            <tr>
              <td>https://radheexch99.com</td>
              <td>radheexch99.com</td>
              <td>
                <div className="d-flex align-items-center"><span className="me-2">Unmatch Bet Allowed</span> <ToggleSwitch On="Yes" Off="No" onChange={toggleSwitch} checked={showToggle ? true : false} /></div>
              </td>
              <td>
                <Dropdown drop="start">
                    <Dropdown.Toggle variant="subtle-dark" className="e-caret-hide btn-sm">
                        <i className="ri-more-2-fill"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleDomainSetting}>Update Domain Setting</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleDelete}>Delete Domain</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleUploadLogo}>Upload logo</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleSocialMedia}>Add Social Media</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleTvUrl}>Upload TV Url</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={toggleUpdateDomain}>Update Domain</Dropdown.Item>
                      <Dropdown.Item className="border-bottom py-2" onClick={togglePopupImage}>Update Popup Image</Dropdown.Item>
                      <Dropdown.Item className="py-2" onClick={toggleCasinoConversion}>Update Casino Conversion Rate</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          </tbody>
        </Table>

        <nav aria-label="Page navigation" className="px-3 d-flex">
          <Pagination className="ms-auto mb-0">
              <Pagination.Item className="disabled" ><i className="mdi mdi-chevron-left"></i></Pagination.Item>
              <Pagination.Item>{1}</Pagination.Item>
              <Pagination.Item active>{2}</Pagination.Item>
              <Pagination.Item>{3}</Pagination.Item>
              <Pagination.Item><i className="mdi mdi-chevron-right"></i></Pagination.Item>
          </Pagination>
        </nav>

      </div>

      <DomainSettingModal show={showDomainSetting} clickHandler={toggleDomainSetting} />
      <Modal show={showDelete} onHide={toggleDelete} className="zoomIn" scrollable>
          <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
              <div className='d-flex align-items-center'>User Confirmation</div>
              <Button variant="light btn-sm" onClick={toggleDelete}>
                  <i className="ri-close-line fs-xl align-middle"></i>
              </Button>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
                <Form.Label>Password</Form.Label>
                <div className="form-icon right">
                    <Form.Control
                        name="password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter Password"
                    />
                    <Button
                        variant="link"
                        className="btn-sm position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
                        type="button"
                        id="password-addon"
                        onClick={() => setPassword(!showPassword)}
                    >
                        <i className="ri-eye-fill align-middle"></i>
                    </Button>
                </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className='border-top'>
                <Button variant="danger">Delete</Button>
                <Button variant='light' onClick={toggleDelete}>Cancel</Button>
            </Modal.Footer>
      </Modal>
      <UploadLogoModal show={showUploadLogo} clickHandler={toggleUploadLogo} />
      <SocialMediaModal show={showSocialMedia} clickHandler={toggleSocialMedia} />
      <Modal show={showTvUrl} onHide={toggleTvUrl} className="zoomIn" scrollable>
          <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
              <div className='d-flex align-items-center'>Update Tv Url</div>
              <Button variant="light btn-sm" onClick={toggleTvUrl}>
                  <i className="ri-close-line fs-xl align-middle"></i>
              </Button>
          </Modal.Header>
          <Modal.Body>
            <Form.Label>TV Type</Form.Label>
            <Row className="form-check d-flex mb-2">
              <Col lg={6}>
                <Form.Check type="radio" name="tvType" id="Premimum" />
                <Form.Label htmlFor="Premimum">Premimum</Form.Label>
              </Col>
              <Col lg={6}>
                <Form.Check type="radio" name="tvType" id="Normal" />
                <Form.Label htmlFor="Normal" defaultChecked>Normal</Form.Label>
              </Col>
            </Row>
            <Form.Group>
                <Form.Label>Password</Form.Label>
                <div className="form-icon right">
                    <Form.Control
                        name="password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter Password"
                    />
                    <Button
                        variant="link"
                        className="btn-sm position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
                        type="button"
                        id="password-addon"
                        onClick={() => setPassword(!showPassword)}
                    >
                        <i className="ri-eye-fill align-middle"></i>
                    </Button>
                </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className='border-top'>
                <Button>Update</Button>
                <Button variant='light' onClick={toggleTvUrl}>Cancel</Button>
            </Modal.Footer>
      </Modal>
      <UpdateDomainModal show={showUpdateDomain} clickHandler={toggleUpdateDomain} />
      <PopupImageModal show={showPopupImage} clickHandler={togglePopupImage} />
      <CasinoConversionModal show={showCasinoConversion} clickHandler={toggleCasinoConversion} />
    </>
  );
};

export default DomainSettingTable;