import { Dispatch, useEffect, useState } from 'react';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { BetLimits } from 'pages/MatchDetail';
import snackbarUtil from 'Utils/snackBarUtil';

interface MatchSettingProps {
    limits?: BetLimits | undefined;
    id?: string | undefined;
    getLimit?: any;
    fancyShow?: boolean;
    shoeOdss?: boolean;
    marketId?: string;
    sportName: string;
    combineData: any;
    setFormData:any;
    formData:any;
    setErrors: Dispatch<any>;
    errors:any
}

const MarketSettingForm = ({ sportName, combineData, setFormData, formData, errors,setErrors }: MatchSettingProps) => {
    

   

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        const numericValue = value === "" ? "" : Number(value);
        
        setFormData({
            ...formData,
            [name]: numericValue,
        });
        if (value !== "") {
            setErrors({ ...errors, [name]: false });
        }
    };

  

  

    useEffect(() => {
        setFormData({
            market_advance_bet_stake: combineData?.market_advance_bet_stake,
            market_bet_delay: combineData?.market_bet_delay,
            market_bookmaker_max_odds_rate: combineData?.market_bookmaker_max_odds_rate,
            market_bookmaker_min_odds_rate: combineData?.market_bookmaker_min_odds_rate,
            market_max_profit: combineData?.market_max_profit,
            market_max_stack: combineData?.market_max_stack,
            market_min_odds_rate: combineData?.market_min_odds_rate,
            market_min_stack: combineData?.market_min_stack,
            session_bet_delay: combineData?.session_bet_delay,
            session_max_profit: combineData?.session_max_profit,
            session_max_stack: combineData?.session_max_stack,
            session_min_stack: combineData?.session_min_stack,
            market_max_odds_rate: combineData?.market_max_odds_rate,
        });
    }, [combineData]);

    return (
        <Form>
            <Row className="g-3">
                <h5 className='text-primary mb-0'>Market Setting</h5>
                
                <Col lg={4}>
                    <div className="form-floating">
                        <Form.Control
                            name="market_min_stack"
                            type="number"
                            id="MinStakeAmount"
                            placeholder="Min. Stake Amount"
                            value={formData.market_min_stack}
                            onChange={handleInputChange}
                            isInvalid={errors.market_min_stack}
                        />
                        <Form.Label htmlFor="MinStakeAmount">Min. Stake Amount</Form.Label>
                        {errors.market_min_stack && <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback>}
                    </div>
                </Col>

                <Col lg={4}>
                    <div className="form-floating">
                        <Form.Control
                            name="market_max_stack"
                            type="number"
                            id="MaxStakeAmount"
                            placeholder="Max. Stake Amount"
                            value={formData.market_max_stack}
                            onChange={handleInputChange}
                            isInvalid={errors.market_max_stack}
                        />
                        <Form.Label htmlFor="MaxStakeAmount">Max. Stake Amount</Form.Label>
                        {errors.market_max_stack && <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback>}
                    </div>
                </Col>

                <Col lg={4}>
                    <div className="form-floating">
                        <Form.Control
                            name="market_min_odds_rate"
                            type="number"
                            id="MinOddsRate"
                            placeholder="Min. Odds Rate"
                            value={formData.market_min_odds_rate}
                            onChange={handleInputChange}
                            isInvalid={errors.market_min_odds_rate}
                        />
                        <Form.Label htmlFor="MinOddsRate">Min. Odds Rate</Form.Label>
                        {errors.market_min_odds_rate && <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback>}
                    </div>
                </Col>

                <Col lg={4}>
                    <div className="form-floating">
                        <Form.Control
                            name="market_max_odds_rate"
                            type="number"
                            id="MaxOddsRate"
                            placeholder="Max. Odds Rate"
                            value={formData?.market_max_odds_rate}
                            onChange={handleInputChange}
                            isInvalid={errors.market_max_odds_rate}
                        />
                        <Form.Label htmlFor="MaxOddsRate">Max. Odds Rate</Form.Label>
                        {errors.market_max_odds_rate && <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback>}
                    </div>
                </Col>

                {sportName === "Cricket" && (
                    <>
                        <Col lg={4}>
                            <div className="form-floating">
                                <Form.Control
                                    name="market_bookmaker_min_odds_rate"
                                    type="number"
                                    id="BookmakerMinOddsRate"
                                    placeholder="Bookmaker Min. Odds Rate"
                                    value={formData?.market_bookmaker_min_odds_rate}
                                    onChange={handleInputChange}
                                    isInvalid={errors.market_bookmaker_min_odds_rate}
                                />
                                <Form.Label htmlFor="BookmakerMinOddsRate">Bookmaker Min. Odds Rate</Form.Label>
                                {errors.market_bookmaker_min_odds_rate && <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback>}
                            </div>
                        </Col>

                        <Col lg={4}>
                            <div className="form-floating">
                                <Form.Control
                                    name="market_bookmaker_max_odds_rate"
                                    type="number"
                                    id="BookmakerMaxOddsRate"
                                    placeholder="Bookmaker Max. Odds Rate"
                                    value={formData?.market_bookmaker_max_odds_rate}
                                    onChange={handleInputChange}
                                    isInvalid={errors.market_bookmaker_max_odds_rate}
                                />
                                <Form.Label htmlFor="BookmakerMaxOddsRate">Bookmaker Max. Odds Rate</Form.Label>
                                {errors.market_bookmaker_max_odds_rate && <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback>}
                            </div>
                        </Col>
                    </>
                )}

                <Col lg={4}>
                    <div className="form-floating">
                        <Form.Control
                            name="market_bet_delay"
                            type="number"
                            id="BetDelay"
                            placeholder="Match Bets Delay"
                            value={formData?.market_bet_delay}
                            onChange={handleInputChange}
                            isInvalid={errors.market_bet_delay}
                        />
                        <Form.Label htmlFor="BetDelay">Match Bets Delay</Form.Label>
                        {errors.market_bet_delay && <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback>}
                    </div>
                </Col>

                <Col lg={4}>
                    <div className="form-floating">
                        <Form.Control
                            name="market_max_profit"
                            type="number"
                            id="MarketMaxProfit"
                            placeholder="Market Max Profit"
                            value={formData.market_max_profit}
                            onChange={handleInputChange}
                            isInvalid={errors.market_max_profit}
                        />
                        <Form.Label htmlFor="MarketMaxProfit">Market Max Profit</Form.Label>
                        {errors.market_max_profit && <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback>}
                    </div>
                </Col>

                <Col lg={4}>
                    <div className="form-floating">
                        <Form.Control
                            name="market_advance_bet_stake"
                            type="number"
                            id="InplayMatchStake"
                            placeholder="Before Inplay Match Stake"
                            value={formData.market_advance_bet_stake}
                            onChange={handleInputChange}
                            isInvalid={errors.market_advance_bet_stake}
                        />
                        <Form.Label htmlFor="InplayMatchStake">Before Inplay Match Stake</Form.Label>
                        {errors.market_advance_bet_stake && <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback>}
                    </div>
                </Col>

                {sportName === "Cricket" && (
                    <>
                        <h5 className='text-primary mb-0'>Session Setting</h5>
                        <Col lg={4}>
                            <div className="form-floating">
                                <Form.Control
                                    name="session_min_stack"
                                    type="number"
                                    placeholder="Session Min Stake"
                                    value={formData.session_min_stack}
                                    onChange={handleInputChange}
                                    isInvalid={errors.session_min_stack}
                                />
                                <Form.Label>Session Min Stake</Form.Label>
                                {errors.session_min_stack && <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback>}
                            </div>
                        </Col>

                        <Col lg={4}>
                            <div className="form-floating">
                                <Form.Control
                                    name="session_max_stack"
                                    type="number"
                                    placeholder="Session Max Stake"
                                    value={formData.session_max_stack}
                                    onChange={handleInputChange}
                                    isInvalid={errors.session_max_stack}
                                />
                                <Form.Label>Session Max Stake</Form.Label>
                                {errors.session_max_stack && <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback>}
                            </div>
                        </Col>

                        <Col lg={4}>
                            <div className="form-floating">
                                <Form.Control
                                    name="session_bet_delay"
                                    type="number"
                                    placeholder="Session Bet Delay"
                                    value={formData.session_bet_delay}
                                    onChange={handleInputChange}
                                    isInvalid={errors.session_bet_delay}
                                />
                                <Form.Label>Session Bet Delay</Form.Label>
                                {errors.session_bet_delay && <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback>}
                            </div>
                        </Col>

                        <Col lg={4}>
                            <div className="form-floating">
                                <Form.Control
                                    name="session_max_profit"
                                    type="number"
                                    placeholder="Session Max Profit"
                                    value={formData.session_max_profit}
                                    onChange={handleInputChange}
                                    isInvalid={errors.session_max_profit}
                                />
                                <Form.Label>Session Max Profit</Form.Label>
                                {errors.session_max_profit && <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback>}
                            </div>
                        </Col>
                    </>
                )}

                
            </Row>
        </Form>
    );
};

export default MarketSettingForm;

