import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

interface CasinoConversionModalProps {
	show: any;
	clickHandler: any;
}

const CasinoConversionModal = ({show, clickHandler} : CasinoConversionModalProps) => {

    return (
        <Modal show={show} onHide={clickHandler} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <div>Update Casino Conversion Rate</div>
                <Button variant="light btn-sm" onClick={clickHandler}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='fs-md'>
                <Row className="align-items-center g-3">
                    <Col lg={12}>
                        <Form.Group>
                            <Form.Label>Old</Form.Label>
                            <Form.Control type="number" value={1} />
                        </Form.Group>
                    </Col>
                    <Col lg={12}>
                        <Form.Group>
                            <Form.Label>New</Form.Label>
                            <Form.Control type="number" placeholder='New Conversion Rate' />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className='border-top'>
                <Button>Update</Button>
                <Button variant='light' onClick={clickHandler}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CasinoConversionModal;