import { Button, Modal, Tab, Nav } from 'react-bootstrap';
import MarketSettingForm from './MarketSettingForm';
import { authServices } from 'Utils/auth/services';
import { useEffect, useState } from 'react';
import snackbarUtil from 'Utils/snackBarUtil';

interface UserMarketLimitProps {
    show: any;
    clickHandler: any;
    userIds: string;
    settingData: string;
    userName: string
}

interface SportSetting {
    sport_id: string;
    name: string;
    settings: any;  
}

interface SportSettingsByName {
    [key: string]: SportSetting;
}

const UserMarketLimit = ({ show, clickHandler, userIds, settingData, userName }: UserMarketLimitProps) => {
    const [combineData, setCombineData] = useState<SportSettingsByName>({}); 
    const [formValues, setFormValues] = useState<string>("Cricket");
    const [errors, setErrors] = useState<any>({});
    const [formData, setFormData] = useState<any>({
        market_advance_bet_stake: "",
        market_bet_delay: "",
        market_bookmaker_max_odds_rate: "",
        market_bookmaker_min_odds_rate: "",
        market_max_profit: "",
        market_max_stack: "",
        market_min_odds_rate: "",
        market_min_stack: "",
        session_bet_delay: "",
        session_max_profit: "",
        session_max_stack: "",
        session_min_stack: "",
        market_max_odds_rate: ""
    });

    const desiredOrder = [
        "Cricket", "Soccer", "Tennis", "Horse Racing",
        "Greyhound Racing", "Share Market", "Casino", "D Casino"
    ];


    const getUserLimit = async () => {
        try {
            const { response } = await authServices.getUserLimits({
                _id: settingData,
                user_id: userIds,
            });


            const groupedSports = getSportsByNames(response?.data?.sports_settings || []);
            setCombineData(groupedSports);
        } catch (error) {
            console.error('Error fetching user limits:', error);
        }
    };

    useEffect(() => {
        getUserLimit();
    }, [userIds, settingData]);
    function getSportsByNames(sportsSettings: SportSetting[]): SportSettingsByName {
        const sortedSportsSettings = sportsSettings?.sort((a, b) => {
            return desiredOrder.indexOf(a.name) - desiredOrder.indexOf(b.name);
        });

        return sortedSportsSettings?.reduce((acc: SportSettingsByName, setting: SportSetting) => {
            acc[setting.name] = setting;
            return acc;
        }, {});
    }

    const handleClickSportName = (name: string) => {
        setFormValues(name);
    }

    const validateForm = () => {
        const newErrors: any = {};
        
        const keysToValidate = formValues === "Cricket" 
            ? Object.keys(formData) 
            : Object.keys(formData).filter(key => !key.startsWith("session")); 
    
        keysToValidate.forEach((key) => {
            if (formData[key] === "" || formData[key] === 0) {
                newErrors[key] = true;
            }
        });
    
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const getUserSettingUpdate = async()=>{
        const {response} = await authServices?.getUserSettingUpdate({
            user_id:userIds,
            sport_id:combineData[formValues]?.sport_id,
            sports_settings:[
                {
                    ...(formValues !== "Cricket" ? {
                        market_advance_bet_stake: formData?.market_advance_bet_stake,
                        market_bet_delay: formData?.market_bet_delay,
                        market_max_odds_rate: formData?.market_max_odds_rate,
                        market_max_profit: formData?.market_max_profit,
                        market_max_stack: formData?.market_max_stack,
                        market_min_odds_rate: formData?.market_min_odds_rate,
                        market_min_stack: formData?.market_min_stack,
                    }:formData)
                }
            ]
        })
        if(response?.status){
            snackbarUtil.success(response?.msg);
            clickHandler();
        }
        
    }

    const handleSave = () => {
        if (validateForm()) {
            getUserSettingUpdate();
            
        } 
    };


    return (
        <Modal size='lg' show={show} onHide={clickHandler} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <span>User Sports Settings ({userName})</span>
                <Button variant="light btn-sm" onClick={clickHandler}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='pt-2 fs-md'>
                <Tab.Container defaultActiveKey="Cricket">
                    <Nav as="ul" variant="pills" className="arrow-navtabs nav-success bg-light mb-3">
                        {
                            combineData && Object.keys(combineData)?.map((key) => (
                                <Nav.Item as="li" key={key} onClick={() => handleClickSportName(key)}>
                                    <Nav.Link eventKey={key}>{key}</Nav.Link>
                                </Nav.Item>
                            ))
                        }
                    </Nav>
                    <Tab.Content className="text-muted">

                        <Tab.Pane eventKey={formValues} key={formValues}>
                            <MarketSettingForm
                                sportName={formValues}
                                combineData={combineData[formValues]}
                                setFormData={setFormData}
                                formData={formData}
                                setErrors={setErrors}
                                errors={errors}
                            />
                        </Tab.Pane>

                    </Tab.Content>
                </Tab.Container>
            </Modal.Body>
            <Modal.Footer className='border-top'>
                <Button onClick={handleSave}>Update</Button>
                <Button variant='light' onClick={clickHandler}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UserMarketLimit;
